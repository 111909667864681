<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 379 241.17"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,125.17v-9A92.12,92.12,0,0,1,17.37,84c22.85-28.25,51.48-49,84.31-64C140.48,2.18,180.94-4.45,223.29,3c42.14,7.45,79.16,25.84,111.55,53.49,19.22,16.41,36.68,34.41,44.16,59.64v9a93,93,0,0,1-17.7,32.07C325.69,199.49,280.61,226,226.58,237c-10.69,2.2-21.71,2.81-32.58,4.15h-8a37.25,37.25,0,0,0-4.4-.7c-37.79-1.55-72.68-13-104.56-32.76C44.08,187.24,14.05,163.53,0,125.17Zm180.61,98.47c49.68-.3,86.82-12.57,121.07-34.15,19.32-12.16,36.73-26.57,50.13-45.28,13.17-18.39,13.66-29.71-.17-47.5a186.52,186.52,0,0,0-29-30.12C284.6,35.59,241,18,191.54,17.24,148.24,16.61,109.07,30.29,73.2,54c-18.48,12.2-34.9,26.64-47.71,45-9.79,14-11,27.8-.5,41.91,1.69,2.27,3.1,4.76,4.93,6.9C71.8,196.82,125.59,220.85,180.61,223.64Z"
        />
        <path
          d="M103.42,120.3c.07-47.24,38.68-85.92,85.82-85.94,47.76,0,86.63,38.8,86.4,86.31a86,86,0,0,1-86.09,85.57C141.78,206.12,103.36,167.78,103.42,120.3Zm155,.67c.5-37.92-30.08-69-68.39-69.46-37.72-.47-68.78,30-69.35,68-.56,37.83,29.91,69.22,67.63,69.67A68.74,68.74,0,0,0,258.41,121Z"
        />
        <path
          d="M163.86,120.16a25.69,25.69,0,1,1,25.45,25.94A25.61,25.61,0,0,1,163.86,120.16Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "eye",
  props: ["role"],
};
</script>
