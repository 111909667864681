<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 337 372.29"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M30,372.29c-5.4-2.09-7.13-6.05-7.09-11.75.2-26.32.08-52.64.1-79,0-8.74,2.51-11.19,11.34-11.19,23.48,0,47-.07,70.46.09a12.94,12.94,0,0,1,7.32,2.21c3,2.29,3.63,5.89,2.1,9.5s-4.28,5.17-8.27,5.15c-18-.09-36,0-54,0H45.54c5.58,6.84,10.07,13,15.16,18.51C85.61,332.94,116.08,350,152.86,354c39.27,4.34,74.7-6.34,105.31-31.19,39.13-31.76,58.93-73.76,63-123.58.32-3.82.58-7.65.58-11.48,0-4.51.95-8.43,5.79-9.91s7.23,2,9.48,5.41v18a20.33,20.33,0,0,0-.85,3.26c-2.52,29-10.73,56.23-25.53,81.23-26.74,45.16-65,74.6-117.39,84.05-6.67,1.21-13.48,1.68-20.23,2.49h-9c-1.77-.28-3.55-.57-5.33-.83-8.54-1.27-17.23-1.91-25.59-3.9-37.72-9-67.73-30-91.75-60a16,16,0,0,1-1.6-3.36l-1.5,1.28c0,18.47-.14,36.93.08,55.39.07,5.71-1.63,9.67-7.31,11.4Z"
        />
        <path
          d="M0,172.25c2-11.63,3.22-23.43,6-34.86C16.68,94,39.06,57.92,75.27,31.28c60.74-44.7,140.22-41.18,197,8.59,8.1,7.1,15,15.53,22.49,23.36,1.08,1.13,2.08,2.34,4.05,4.58V61.66q0-25.75,0-51.5c0-6.22,2.74-9.86,7.35-10C311,.07,314,3.85,314,10.23q0,40.5,0,81c0,7.5-2.72,10.16-10.25,10.17q-35.73,0-71.48,0c-6.58,0-10.15-3-10.18-8.26,0-5.52,3.67-8.64,10.47-8.66,17.17,0,34.33,0,51.49,0h6.11a22.41,22.41,0,0,0-1.57-3.29C262.72,46,229,23.34,185.1,18.13S103,26.33,70.85,56c-37,34.14-54,77.46-55.56,127.32a20.28,20.28,0,0,1-.66,5.91c-2.37,6.71-9,6.68-14.63,0Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Refresh",
  props: ["role"],
};
</script>
