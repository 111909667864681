<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 540 442"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M540,41c-3.1,6.67-8.31,9-15.63,9q-193.22-.2-386.43-.09c-9.05,0-14.45-4-15-11.15a11.86,11.86,0,0,1,9.79-13,34.86,34.86,0,0,1,6.46-.45q192.21,0,384.42-.1c7.36,0,13,2,16.41,8.78Z"
        />
        <path
          d="M540,286c-3.38,6.74-8.9,8.87-16.3,8.87q-192.23-.19-384.45-.11a36.06,36.06,0,0,1-6-.35c-6.39-1.08-10.46-5.86-10.42-12a12.16,12.16,0,0,1,11-12.16,35.35,35.35,0,0,1,4.49-.1q192.72,0,385.45-.1c7.43,0,13,2.07,16.2,9Z"
        />
        <path
          d="M540,409c-4.07,6.72-10.13,8.18-17.71,8.16q-191.17-.27-382.36-.13c-2,0-4,.11-6-.07a12.14,12.14,0,0,1-.2-24.17,50.27,50.27,0,0,1,5.49-.17q192.18,0,384.36-.11c7.31,0,12.9,1.87,16.42,8.49Z"
        />
        <path
          d="M540,164c-3.68,6.45-9.17,8.37-16.56,8.36q-193-.27-385.94-.14c-9.41,0-14.77-4.6-14.62-12.48A12,12,0,0,1,133.65,148a37.9,37.9,0,0,1,4-.09q193.47,0,386.93-.11c7.4,0,12.53,2.39,15.43,9.22Z"
        />
        <path
          d="M0,154c2.61-5.48,4.45-11.53,8-16.35,10.18-13.89,28.83-18.16,44.25-10.89C68,134.21,76.36,150.7,72.91,167.55c-3.42,16.68-18.48,29-35.75,29.27-16.81.26-32-11.63-36.12-28.17A22,22,0,0,0,0,166Z"
        />
        <path
          d="M0,277c2.68-10.88,7.87-20.09,17.78-26a36.87,36.87,0,0,1,52,15.07c9.35,18.46.92,41.77-18.06,49.93-20.17,8.66-42.53-1.23-49.84-22C1.33,292.3.64,290.66,0,289Z"
        />
        <path
          d="M0,32C2.84,21.2,8.06,12.11,17.94,6.13c18-10.91,42.14-4,51.75,14.87s1,42.5-18.53,50.55C31.29,79.76,8.28,69.22,1.86,49,1.32,47.31.62,45.67,0,44Z"
        />
        <path
          d="M0,399c2.35-7.48,5.15-14.64,11-20.31a36.75,36.75,0,1,1,31.32,62.4A27.87,27.87,0,0,0,39,442H34c-1.24-.35-2.46-.76-3.71-1-13.9-3-23.43-11.09-28.32-24.5C1.3,414.65.66,412.82,0,411Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "save",
  props: ["role"],
};
</script>
