<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 293 238.69"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M293,208.35c-2.45,3.15-5.68,4.06-9.61,4-7.49-.22-15-.17-22.49,0-2.83.06-4.59-.53-5.54-3.53s-2.66-6-4.33-9.63h28.08c-1-7-3.68-12.4-9.13-15.92a65.33,65.33,0,0,0-10.73-5.14c-13.06-5.35-20.66-15-22.16-28.94-1.54-14.26.52-27.85,9.33-39.91,1.42-2,1.72-5.57,1.07-8-1.77-6.72-.61-12.65,2.33-18.78,4-8.34,4.4-16.73-.15-25.39-6.45-12.28-22.62-19.92-36.2-16.39-.77.2-1.53.46-2.73.82-1.35-4.07-2.62-7.92-4-12.11,10.62-4.67,20.86-3.18,30.79.62,19.34,7.4,30.65,24.83,28.29,43.76-.67,5.35-3.16,10.45-4.54,15.74a19.68,19.68,0,0,0-.84,7.65c1.2,8,.39,15.16-4.55,22.22-6.86,9.81-7.45,21.22-4.75,32.64,1.45,6.12,5.35,11.09,11.39,13,16.7,5.33,27.38,15.79,30.47,33.37Z"
        />
        <path
          d="M0,198.35c.86-3,1.56-6.08,2.6-9,3.82-10.74,11.57-17.57,21.87-22,16.53-7,21.32-15.42,18.14-33.23a41.47,41.47,0,0,0-6-15.59c-4.41-6.51-4.94-13.1-4.18-20.43a19.11,19.11,0,0,0-1.15-9.49c-7.19-15.76-5.14-30.4,4.26-44.51,9.29-14,35.87-21.91,50.21-14.71L81.19,41c-11.1-3.34-21.35-.38-29.9,5.92C43.09,53,39.21,62.25,40.38,72.86a23,23,0,0,0,2.13,7.66c3.59,7.09,4.92,14.18,3,22.14-.63,2.54,1,6.13,2.58,8.63,8.46,13.47,10.35,28,6.86,43.18-2.73,11.85-10.46,19.89-22.06,23.84-9,3.07-16,8-18.44,17.86a20.48,20.48,0,0,0-.24,2.82H42c-1.87,4.29-3.32,8.09-5.22,11.64-.5.93-2.42,1.52-3.69,1.54-8.33.13-16.69.38-25-.11-2.75-.17-5.37-2.42-8-3.71Z"
        />
        <path
          d="M146.42,238.68q-44.76,0-89.5,0c-9.16,0-10.52-1.4-10-10.55,1-16.93,9.62-29.19,23.9-37.48a123.1,123.1,0,0,1,20.39-9.24c25.67-9.12,34.16-33,19.8-56.54a24.56,24.56,0,0,0-3.5-4.85c-7.23-7.12-9-16.06-7.18-25.38,1.29-6.8,0-12.58-2.58-18.67-7.5-17.65-5-34.18,5.19-50.34,9.47-15,41.5-29.66,61.42-24.6,8.53,2.17,15.4,6.44,20,13.79,7.78,12.36,13.47,25.58,14.93,40.32a48.12,48.12,0,0,1-5.74,28.92,9,9,0,0,0-.91,5.49c1.95,11.29,1.27,21.49-7.21,30.7-9,9.79-11,22.36-9.25,35.15s10.26,20.31,22,24.8c7.9,3,15.94,6,23.3,10.06,14.95,8.31,23.79,21,24.64,38.54.39,8.07-1.24,9.86-9.26,9.87Q191.66,238.72,146.42,238.68Zm85.86-13.47c-1-8.46-4.92-15.24-11.56-19.33A166.11,166.11,0,0,0,194.47,193c-10.58-4.18-19.47-10-25.61-19.74-10.39-16.43-8.3-43.47,5.75-60.45,5.58-6.74,6.68-13.33,4.8-21.19-1.17-4.92-.31-9.28,2.21-13.85A36.46,36.46,0,0,0,186,64.15c1.53-15.69-4.92-29.24-12.78-42.2-3.58-5.92-9.33-8.23-16-8.66-10.18-.64-19,3.34-28.15,7.18-15.4,6.47-27.32,28.53-20.45,46.38,1.55,4,3.38,8,4.42,12.17.89,3.55,1.58,7.45,1,11-1.3,8.65-1.06,15.88,5,23.68,23.45,30.06,12,67.39-23.53,80.21A113,113,0,0,0,73.35,205a27.06,27.06,0,0,0-12.58,20.2Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "client",
  props: ["role"],
};
</script>
