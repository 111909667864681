<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 251 205.93"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M251,41.51v146c-4.06,11.81-11.75,18.41-24.8,18.39q-100.5-.17-201-.06c-1.5,0-3,0-4.49-.16-8.31-.78-14.3-5-18.1-12.37-1-1.88-1.75-3.86-2.62-5.8v-169C4.68,4.69,14.45-.55,28.77.11,43.39.77,58.09.84,72.7.08c12.82-.68,23,3.07,31.39,12.84,6.45,7.54,15,10.24,24.95,10.15,32-.3,63.92-.08,95.89-.14,7,0,13.92.92,18.76,6.39C246.77,32.82,248.6,37.41,251,41.51ZM239.75,69H11.33v5.3q0,52.76,0,105.51c0,10.81,4,14.79,14.74,14.79H224.55c11.41,0,15.2-3.81,15.2-15.33q0-52.26,0-104.51Zm0-11.87c0-2.87,0-5.18,0-7.49,0-11.44-3.79-15.18-15.3-15.18-32.63,0-65.27-.07-97.91,0-11.29,0-20.82-3.68-28.56-12-2-2.19-4-4.69-6.46-6.21-3.31-2-7.09-4.49-10.72-4.57-19.47-.45-39-.3-58.44-.15-5.36,0-10.25,3.85-10.45,9-.47,12.08-.15,24.19-.15,36.57Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "folder",
  props: ["role"],
};
</script>
