import Vue from 'vue'

export default {
  getTaxes: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {}
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...pagination,
        ...filters
      };

      return await Vue.http.get(`taxs`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getTax: async function ({
    commit,
    state
  }, id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`taxs/${id}`).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addTax: async function ({
    commit,
    state
  }, formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.post(`taxs`, formData).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  editTax: async function ({
    commit,
    state
  }, {
    id,
    formData
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.post(`taxs/${id}`, formData).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
}