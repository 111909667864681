<template>
  <svg
    class="svg-icon"
    :role="role"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 529.23 537.7"
  >
    <polygon
      class="cls-1"
      points="358.94 183.58 322 183.58 322 144.06 358.94 183.58"
    />
    <path
      class="cls-1"
      d="M313.72,191.86V142.57h-145V395.13H360.54V191.86ZM193.87,255.05h81.79v13.8H193.87Zm131.39,67.71H193.87V309H325.26Zm0-27H193.87V282H325.26Zm0-53.91H193.87v-13.8H325.26Z"
    />
  </svg>
</template>
<script>
export default {
  name: "Appointments",
  props: ["role"],
};
</script>
