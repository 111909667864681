<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 382 381.64"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,45.25c1.43-4.57,2.36-9.37,4.38-13.67C13.71,11.73,29.3.39,51.44.27,114.77,0,178.1.13,241.44.14c6.4,0,10.52,3.6,10.35,8.85-.16,5-4.18,8.28-10.47,8.28q-93.24,0-186.49,0c-23,0-37.56,14.47-37.57,37.42q0,135.77,0,271.51c0,20.31,11.25,34.74,29.32,37.7a53.07,53.07,0,0,0,8.47.41q136.25,0,272.49,0c22.66,0,37.22-14.38,37.25-37.15q.13-90,0-180v-5.5c0-6.7,1.78-10,6.23-11.15,5.33-1.42,8.44,1.64,10.95,5.73v201c-.22.24-.56.45-.63.73-8.07,30.17-25.6,43.6-56.94,43.6H177.09c-42.12,0-84.24.17-126.36-.17-22.31-.17-40.93-14.42-48-35.64-1-3.13-1.82-6.34-2.72-9.52Z"
        />
        <path
          d="M382,50.25c-2.91,13.3-11.5,22.71-20.83,32q-93.55,93-186.72,186.47a19.76,19.76,0,0,1-11.52,6c-22.14,3.55-44.23,7.5-67.16,11.44.85-5.52,1.55-10.54,2.41-15.54,3.17-18.37,6.33-36.73,9.69-55.07a11.87,11.87,0,0,1,3.08-5.89Q209.32,111.2,307.86,12.9C332-11.14,372.12.05,380.76,33c.38,1.42.83,2.83,1.24,4.25ZM173.67,243.67l158-158.33-34.89-36q-79.2,79.41-158.49,158.88h35.36Zm172.78-171C350.84,68,356,63.43,360,58c7.55-10.22,5.92-24.4-3.13-33.27S334,14.56,323.92,22.05c-5.38,4-10,9.12-14.63,13.48ZM156,225.44c-5.81,0-11.11-.07-16.41,0-5.12.1-11.66-1.46-14.93,1.1-3,2.34-2.54,9.19-3.4,14.09-1.38,7.77-2.59,15.56-4,24L156,258Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "editprofile",
  props: ["role"],
};
</script>
