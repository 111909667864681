<template>
  <v-btn
    icon
    :small="!large"
    :href="href"
    target="_blank"
    v-if="phone_number"
    :class="classes"
  >
    <v-icon :large="large">mdi-whatsapp</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "WhatsAppLink",
  props: {
    phone_number: {
      type: String,
      default: () => "",
    },
    classes: {
      type: String,
      default: () => "",
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    href() {
      let phone = this.phone_number;
      if (phone[0] !== "+") phone = "+34" + phone;
      return `https://wa.me/${phone}`;
    },
  },
};
</script>
