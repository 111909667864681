<template>
  <div v-if="max == 0" class="py-9">
    {{
      timetable_type == "artist"
        ? $t("no_artist_timetable")
        : timetable_type == "box"
        ? $t("no_box_timetable")
        : $t("no_studio_timetable")
    }}
  </div>
  <v-slide-group class="pa-2" v-else-if="$vuetify.breakpoint.smAndDown">
    <v-slide-item v-for="(day, n) in timetable" :key="n" v-slot="{ active }">
      <v-card
        align="center"
        v-if="max && max > 0"
        :color="active"
        style="border: 1px solid var(--v-primary-base)"
        class="ma-4"
        :height="size"
        width="170"
        elevation="5"
      >
        <v-card-title class="white--text text-uppercase">
          {{ $t("week_days")[n + 1] }}
        </v-card-title>
        <v-card-text align="center">
          <v-row>
            <v-col cols="12" v-for="(t, i) in day">
              <hr :key="'a' + i" v-if="i > 0" />
              <div class="white--text" :key="i">{{ t[0] }} / {{ t[1] }}</div>
            </v-col>
            <v-col cols="12" v-if="day.length == 0" class="text-uppercase">
              {{ $t("closed") }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-slide-item>
  </v-slide-group>

  <v-simple-table class="horario" v-else>
    <thead>
      <tr>
        <th v-for="n in (1, 7)" :width="n === 3 ? 300 : 250" :key="n">
          {{ $t("week_days")[n] }}
        </th>
      </tr>
    </thead>

    <tbody v-if="max && max > 0">
      <tr v-for="n in (1, max)" :key="n">
        <td v-for="m in (1, 7)" :key="m">
          <span v-if="timetable[m - 1][n - 1]">
            <td>
              <p class="mb-1">
                {{ timetable[m - 1][n - 1][0] }}
              </p>
              <p class="mb-0">
                {{ timetable[m - 1][n - 1][1] }}
              </p>
            </td>
          </span>
          <span v-else>-</span>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="7">NO HAY HORARIO</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "BaseTimetable",
  data() {
    return {};
  },
  props: {
    timetable: {
      type: Array,
      default: [[], [], [], [], [], [], []],
    },
    timetable_type: {
      type: String,
      default: "artist",
    },
  },
  computed: {
    max() {
      let lengths = this.timetable.map((t) => t.length);
      console.log(Math.max(...lengths));
      return Math.max(...lengths);
    },
    size() {
      let timetableSize = 100;
      if (this.max == 0) {
        timetableSize;
      }

      console.log(timetableSize);
      return timetableSize;
    },
  },
};
</script>
