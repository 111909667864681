<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95 93.63"
    class="svg-icon"
    :role="role"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M95,78.42C89.92,87.81,82.71,94.11,71.42,93.6a25.07,25.07,0,0,1-10-2.61C36.08,78.11,16.77,59,3.19,34,2,31.85,1.06,29.6,0,27.39v-9C2.09,12.33,4.94,6.81,10.45,3.07,17.39-1.64,21.82-1,27,5.53c3.8,4.83,7.63,9.65,11.43,14.48,3.65,4.66,3.53,7.16-.48,11.21-6.2,6.27-6.47,6.51-.38,13.23,4.77,5.27,10.27,9.87,15.5,14.72,2.89,2.67,5.47,2.1,8.23-.55C68.77,51.41,69,51.4,77.33,58c6,4.7,11.79,9.63,17.67,14.46ZM3.12,21.66l.8.78c.78,2.69,1.16,5.6,2.42,8C19.56,56,39,75.25,64.77,87.87c9.2,4.51,21-.27,24.93-9.28,1.29-2.94,1.21-5.16-1.82-7.24A131.28,131.28,0,0,1,74.06,60.69c-3.77-3.39-6.32-3.32-9.71.58C60.16,66.09,56,67,52.15,63.61,44.6,56.93,37.46,49.75,30.54,42.4c-3-3.18-2.13-7.16.55-10.49A30.26,30.26,0,0,1,35,28c1.87-1.63,2-3.07.33-5.06-3.61-4.34-7-8.87-10.5-13.33C19.49,2.86,16,2.42,10.31,9.06,7.21,12.66,5.47,17.42,3.12,21.66Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "edit",
  props: ["role"],
};
</script>
