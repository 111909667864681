<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 298 297.44"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,126.25c2.36-1.72,4.51-4,7.13-5.07Q146.24,61.42,285.45,1.87C292.27-1,293.6-.76,298,4.25v5c-6.36,14.43-12.84,28.8-19.07,43.29Q227.68,171.79,176.56,291.1c-1.46,3.4-3,6.38-7.32,6.34s-5.83-3.32-7.18-6.6q-21.57-52.33-43-104.71a12,12,0,0,0-7.32-7.29q-52-21.14-103.86-42.7C5.05,135,2.61,132.9,0,131.25Zm24.15,2.13c31.4,12.92,61.41,25.17,91.29,37.7,4,1.67,6.16.75,9-2.05q66-66.16,132.09-132.14c1.13-1.13,2.2-2.33,3.29-3.49l-.46-.72ZM270.1,39l-.76-.52c-.51.42-1,.8-1.5,1.26q-67.77,67.64-135.6,135.21c-2.35,2.33-1.83,4.13-.84,6.52q8.64,20.72,17.18,41.46c6.84,16.58,13.67,33.16,20.89,50.69Z"
        />
        <path
          d="M35.27,270.37c-6.38,0-9.59-4.88-7.28-9.37a17.9,17.9,0,0,1,3.43-4.22q31.8-31.8,63.66-63.53a20.71,20.71,0,0,1,3-2.67c2.92-2,6.25-1.67,8.06.93,1.36,2,1.22,5.11,1.3,7.74,0,.88-1.28,1.87-2.09,2.68q-32.67,32.7-65.4,65.33A23.66,23.66,0,0,1,35.27,270.37Z"
        />
        <path
          d="M116,229.57c-1.5,2.5-2.22,4.42-3.53,5.77Q103.1,245,93.43,254.42c-3.54,3.43-7.38,3.54-10.1.47-3-3.32-2.53-6.74.45-9.78,6.41-6.54,12.74-13.18,19.52-19.32a9.42,9.42,0,0,1,7.55-1.69C112.84,224.62,114.21,227.53,116,229.57Z"
        />
        <path
          d="M74.35,188.1c0,5.38-9.14,15.06-13.16,14.58-2.14-.26-4.93-1.79-5.87-3.58a7.81,7.81,0,0,1,.45-6.76,28.88,28.88,0,0,1,8-8c1.85-1.2,5.16-1.27,7.3-.48C72.75,184.46,73.68,187.17,74.35,188.1Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "send",
  props: ["role"],
};
</script>
