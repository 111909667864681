import Vue from 'vue'

export default {
  getChannels: async function ({
    commit,
    state,
    dispatch
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`notifications/external/channels`).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getActiveChannels: async function ({
    commit,
    state,
    dispatch
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`notifications/external/channels/active`).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  postChannels: async function ({
    commit,
    state,
    dispatch
  }, formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`notifications/external/channels`, formData).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getPacks: async function ({
    commit,
    state,
    dispatch
  }, {
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...filters
      }
      return await Vue.http.get(`notifications/external/packs`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getExternalNotifications: async function ({
    commit,
    state,
    dispatch
  }, {
    filters,
    pagination
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...filters,
        ...pagination
      }
      return await Vue.http.get(`notifications/external`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  countExternalNotifications: async function ({
    commit,
    state,
    dispatch
  }, {
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...filters
      }
      return await Vue.http.get(`notifications/external/count`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  purchaseNotificationPack: async function ({
    commit,
    state,
    dispatch
  }, id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`notifications/external/pay/${id}`).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  saveNotificationConfiguration: async function ({
    commit,
    state,
    dispatch
  }, formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`notifications/external/via`, formData).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
}