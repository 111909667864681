<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 420 419.45"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M420,76.26v312c-.24.22-.6.39-.69.66-7.61,22.49-18.8,30.52-42.55,30.52H42.94c-2.17,0-4.33,0-6.5-.06a37.25,37.25,0,0,1-23.57-9.5c-7-6-10.37-14.06-12.87-22.62v-355c.21-.26.52-.48.6-.77C6.7,9.73,19.37.08,41.91.08Q190.14.08,338.37,0a11.73,11.73,0,0,1,9.14,3.8Q383.65,40.14,420,76.26ZM95.57,19.05H89.42q-23.74,0-47.5,0c-15.84,0-22.89,7-22.89,22.8v336c0,1.5-.05,3,0,4.49.57,8.52,6.42,16.39,14.58,17.43,7.63,1,15.48.2,23.62.2V232.86c0-15.89,7-23,22.71-23H340.48c15.1,0,22.3,7.26,22.3,22.42q0,81,0,162v6.24c5.79,0,10.93,0,16.06,0,14.89,0,22.15-7.21,22.15-22q0-144,.08-288a10.56,10.56,0,0,0-3.52-8.22c-19.89-19.71-39.79-39.41-59.37-59.42-4.2-4.29-8.41-4.23-13.64-3.18V129.87c0,15.52-7,22.56-22.58,22.57H117.48c-14.87,0-21.91-7.14-21.91-22.17q0-52.25,0-104.49Zm248,210c-2.11-.09-3.59-.21-5.07-.21q-128.48,0-256.94-.12c-4.69,0-5.38,1.66-5.37,5.78q.18,80.24.09,160.47c0,1.74.17,3.48.26,5.17h267Zm-38.34-95.92V19.34H114.84V133.12Z"
        />
        <path d="M285.86,114H248.44V38.63h37.42Z" />
        <path d="M134.08,56.78V38.47h75.61V56.78Z" />
        <path d="M134.05,94.88V76.58h56.6v18.3Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "save",
  props: ["role"],
};
</script>
