<template>
  <v-list-group
    v-if="item.sublinks"
    class="base_item"
    :key="`item-${i}`"
    active-class="primary--text open"
  >
    <template v-slot:activator v-if="$vuetify.breakpoint.mdAndUp">
      <v-list-item-icon>
        <v-icon small>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          <span>{{ $t(item.title) }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <v-row>
      <v-col class="pl-10">
        <base-item
          v-for="(sublink, j) in item.sublinks"
          :key="`subitem-${j}`"
          :item="sublink"
        />
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-list-group>
  <v-list-item
    class="base_item"
    v-else-if="!item.sublinks"
    :disabled="
      !$store.state.auth.active || $route.name === 'appointmentCreatingView'
    "
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    active-class="primary--text open"
  >
    <v-list-item-icon
      ><v-icon small v-text="item.icon"></v-icon
      ><v-badge
        v-if="item.count && item.count > 0"
        :left="item.count > 100"
        :content="item.count > 100 ? '+99' : item.count"
    /></v-list-item-icon>
    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title style="font-size: 13px">{{
        item.title
      }}</v-list-item-title>
      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  name: "Item",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
};
</script>

<style scoped lang="sass">
.base_item
  text-transform: uppercase
  &.open .v-list-item__title
    text-decoration: underline
</style>
