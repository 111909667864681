<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    width="25.5"
    height="22.723"
    viewBox="0 0 25.5 22.723"
  >
    <g id="calendar" transform="translate(0 -20.9)">
      <g id="Grupo_81" data-name="Grupo 81" transform="translate(0 20.9)">
        <g id="Grupo_80" data-name="Grupo 80" transform="translate(0 0)">
          <path
            id="Trazado_76"
            data-name="Trazado 76"
            d="M22.856,22.833h-1.6V21.564a.664.664,0,1,0-1.328,0v1.269h-6.51V21.564a.664.664,0,1,0-1.328,0v1.269H5.513V21.564a.664.664,0,1,0-1.328,0v1.269H2.65A2.688,2.688,0,0,0,0,25.523V40.967a2.651,2.651,0,0,0,2.65,2.657h20.2a2.649,2.649,0,0,0,2.65-2.65V25.523A2.678,2.678,0,0,0,22.856,22.833Zm1.322,18.134A1.325,1.325,0,0,1,22.863,42.3H2.65a1.321,1.321,0,0,1-1.322-1.322V25.523A1.351,1.351,0,0,1,2.65,24.161H4.185v.963a.664.664,0,1,0,1.328,0v-.963h6.576v.963a.664.664,0,1,0,1.328,0v-.963h6.51v.963a.664.664,0,1,0,1.328,0v-.963h1.6a1.351,1.351,0,0,1,1.322,1.362Z"
            transform="translate(0 -20.9)"
          />
        </g>
      </g>
      <g id="Grupo_83" data-name="Grupo 83" transform="translate(9.18 28.479)">
        <g id="Grupo_82" data-name="Grupo 82">
          <path
            id="Trazado_77"
            data-name="Trazado 77"
            d="M140.027,135h-1.162a.664.664,0,0,0,0,1.328h1.162a.664.664,0,0,0,0-1.328Z"
            transform="translate(-138.2 -135)"
          />
        </g>
      </g>
      <g
        id="Grupo_85"
        data-name="Grupo 85"
        transform="translate(13.803 28.479)"
      >
        <g id="Grupo_84" data-name="Grupo 84">
          <path
            id="Trazado_78"
            data-name="Trazado 78"
            d="M209.627,135h-1.162a.664.664,0,0,0,0,1.328h1.162a.664.664,0,1,0,0-1.328Z"
            transform="translate(-207.8 -135)"
          />
        </g>
      </g>
      <g
        id="Grupo_87"
        data-name="Grupo 87"
        transform="translate(18.466 28.479)"
      >
        <g id="Grupo_86" data-name="Grupo 86">
          <path
            id="Trazado_79"
            data-name="Trazado 79"
            d="M279.827,135h-1.162a.664.664,0,1,0,0,1.328h1.162a.664.664,0,1,0,0-1.328Z"
            transform="translate(-278 -135)"
          />
        </g>
      </g>
      <g id="Grupo_89" data-name="Grupo 89" transform="translate(9.18 33.062)">
        <g id="Grupo_88" data-name="Grupo 88">
          <path
            id="Trazado_80"
            data-name="Trazado 80"
            d="M140.027,204h-1.162a.664.664,0,1,0,0,1.328h1.162a.664.664,0,0,0,0-1.328Z"
            transform="translate(-138.2 -204)"
          />
        </g>
      </g>
      <g id="Grupo_91" data-name="Grupo 91" transform="translate(4.517 33.062)">
        <g id="Grupo_90" data-name="Grupo 90">
          <path
            id="Trazado_81"
            data-name="Trazado 81"
            d="M69.833,204H68.664a.664.664,0,1,0,0,1.328h1.162A.664.664,0,0,0,69.833,204Z"
            transform="translate(-68 -204)"
          />
        </g>
      </g>
      <g
        id="Grupo_93"
        data-name="Grupo 93"
        transform="translate(13.803 33.062)"
      >
        <g id="Grupo_92" data-name="Grupo 92">
          <path
            id="Trazado_82"
            data-name="Trazado 82"
            d="M209.627,204h-1.162a.664.664,0,0,0,0,1.328h1.162a.664.664,0,1,0,0-1.328Z"
            transform="translate(-207.8 -204)"
          />
        </g>
      </g>
      <g
        id="Grupo_95"
        data-name="Grupo 95"
        transform="translate(18.466 33.062)"
      >
        <g id="Grupo_94" data-name="Grupo 94">
          <path
            id="Trazado_83"
            data-name="Trazado 83"
            d="M279.827,204h-1.162a.664.664,0,1,0,0,1.328h1.162a.664.664,0,1,0,0-1.328Z"
            transform="translate(-278 -204)"
          />
        </g>
      </g>
      <g id="Grupo_97" data-name="Grupo 97" transform="translate(9.18 37.579)">
        <g id="Grupo_96" data-name="Grupo 96">
          <path
            id="Trazado_84"
            data-name="Trazado 84"
            d="M140.027,272h-1.162a.664.664,0,0,0,0,1.328h1.162a.664.664,0,0,0,0-1.328Z"
            transform="translate(-138.2 -272)"
          />
        </g>
      </g>
      <g id="Grupo_99" data-name="Grupo 99" transform="translate(4.517 37.579)">
        <g id="Grupo_98" data-name="Grupo 98">
          <path
            id="Trazado_85"
            data-name="Trazado 85"
            d="M69.833,272H68.664a.664.664,0,0,0,0,1.328h1.162A.664.664,0,0,0,69.833,272Z"
            transform="translate(-68 -272)"
          />
        </g>
      </g>
      <g
        id="Grupo_101"
        data-name="Grupo 101"
        transform="translate(13.803 37.579)"
      >
        <g id="Grupo_100" data-name="Grupo 100">
          <path
            id="Trazado_86"
            data-name="Trazado 86"
            d="M209.627,272h-1.162a.664.664,0,0,0,0,1.328h1.162a.664.664,0,0,0,0-1.328Z"
            transform="translate(-207.8 -272)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Calendar",
  props: ["role"],
};
</script>
