import Vue from "vue";
import store from "./store";
import Router from "vue-router";
import constants from "./constants";
// import * as jwt from 'jsonwebtoken'
import { verifyToken } from "./utils/jwtHelper";
Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/auth",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "Lock",
          path: "lock",
          component: () => import("@/views/pages/Lock"),
        },
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/pages/Login"),
        },
        {
          name: "PasswordForgot",
          path: "password_forgot",
          component: () => import("@/views/pages/PasswordForgot"),
        },
        {
          name: "PasswordReset",
          path: "password_reset/:token/:email",
          component: () => import("@/views/pages/PasswordReset"),
        },
      ],
    },
    {
      path: "/signup",
      component: () => import("@/views/pages/IndexSignUp"),
      children: [
        {
          name: "SignUp",
          path: "/",
          component: () => import("@/views/pages/SignUp"),
        },
      ],
    },
    {
      path: "/redirect",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "Redirect",
          path: "studio/:token",
          component: () => import("@/views/pages/Redirect"),
        },
      ],
    },
    {
      path: "/cliente",
      component: () => import("@/views/base/IndexOutsite"),
      children: [
        {
          name: "appointmentViewOutsite",
          path: ":cliente_id/appointment/:id",
          component: () =>
            import("@/views/appointments/AppointmentViewOutsite"),
        },
      ],
    },

    {
      path: "/inscription",
      component: () => import("@/views/inscription/Index"),
      children: [
        {
          name: "tattooer_signup",
          path: "tattooer",
          component: () => import("@/views/inscription/tattooer/SignUp"),
        },
        {
          name: "Preview",
          path: "preview",
          component: () => import("@/views/studios/Preview"),
          meta: {
            requiresAuth: true,
          },
        },
        // * Invitación a un tattuador
        {
          name: "existing_tattooer_invite",
          path: "tattooer/:tattooer/studio/:studio/:hash",
          component: () => import("@/views/inscription/tattooer/Invite"),
          meta: {
            allowedRoles: [constants.roles.TATTOOER],
          },
        },
        {
          name: "new_tattooer_invite",
          path: "tattooer/studio/:studio/:hash",
          component: () => import("@/views/inscription/tattooer/Invite"),
          meta: {
            allowedRoles: [constants.roles.TATTOOER],
          },
        },
        {
          name: "accept_tattooer_invite",
          path: "tattooer/:tattooer/studio_accept/:studio",
          component: () => import("@/views/inscription/tattooer/InviteAccept"),
        },
        {
          name: "new_reservation_box",
          path: "studio/:token",
          component: () =>
            import("@/views/inscription/tattooer/ReservationBox"),
        },

        // * Invitación a un Studio
        {
          name: "existing_studio_invite",
          path: "studio/:studio/tattooer/:tattooer/:hash",
          component: () => import("@/views/inscription/studio/Invite"),
          meta: {
            allowedRoles: [constants.roles.NOBODY],
          },
        },
        {
          name: "new_studio_invite",
          path: "studio/tattooer/:tattooer/:hash",
          component: () => import("@/views/inscription/studio/Invite"),
          meta: {
            allowedRoles: [constants.roles.NOBODY],
          },
        },
        {
          name: "edit_pending_inscription",
          path: "appointment/:id/post-form",
          component: () => import("@/views/inscription/studio/PostForm"),
          meta: {},
        },
        {
          name: "edit_pending_inscription",
          path: "appointment/:id/edit",
          component: () => import("@/views/inscription/InscriptionEdit"),
          meta: {},
        },

        //* Incripcion de un cliente

        {
          path: "tattooer/:token/appointment/",
          name: "customer_inscription_tattooer", // Incripcion desde el enlace de un tatooer
          component: () => import("@/views/inscription/tattooer/Inscription"),
        },
        {
          path: "studio/:token/appointment/",
          name: "customer_inscription_studio", // Inscripción desde el enlace de un studio
          component: () => import("@/views/inscription/studio/Inscription"),
        },
        {
          path: "studio/:token/appointment/pre",
          name: "customer_inscription_studio_pre", // Inscripción desde el enlace de un studio
          component: () => import("@/views/inscription/studio/PreCustomer"),
        },
        {
          path: "tattooer/:token/appointment/pre",
          name: "customer_inscription_tattooer_pre", // Inscripción desde el enlace de un studio
          component: () => import("@/views/inscription/tattooer/PreCustomer"),
        },
        {
          path: "studio/:token/appointment/asesoramiento",
          name: "customer_inscription_studio_asesoramiento", // Inscripción desde el enlace de un studio
          component: () => import("@/views/inscription/studio/Asesoramiento"),
        },
        {
          path: "studio/:token/appointment/pre_form",
          name: "customer_inscription_studio_preparation", // Inscripción desde el enlace de un studio
          component: () => import("@/views/inscription/studio/Prepare"),
        },
        {
          path:
            "studio/:token/appointment/:appointment_id/appointment_token/:appointment_token_id",
          name: "customer_payment", // Paga inicial del customer
          component: () => import("@/views/inscription/TokenPayment"),
        },
        {
          path:
            "studio/:token/appointment/:appointment_id/sesion/:appointment_date_id",
          name: "customer_online_payment", // Paga Sesion online
          component: () => import("@/views/inscription/OnlineSesionPayment"),
        },
        {
          path: "expenses/:id",
          name: "customer_payment", // Paga inicial del customer
          component: () => import("@/views/economy/TokenPayment"),
        },
        {
          name: "ConsentFormViewIncription",
          path: "appointment/:id/consent/:customer_id/:date_id",
          component: () =>
            import("@/views/appointments/ConsentFormCustomerView"),
        },

        {
          name: "ConsentFormViewIncriptionNewCustomer",
          path: "appointment/:id/consent/:date_id",
          component: () =>
            import("@/views/appointments/ConsentFormCustomerView"),
        },

        {
          name: "page_policy",
          path: "policy/studio/:token",
          component: () => import("@/views/policy/PagePolicy"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/base/Index"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: "Dashbord",
          path: "/",
          component: () => import("@/views/base/Dashboard"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "More",
          path: "/more",
          component: () => import("@/views/base/More"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "NewUser",
          path: "/",
          component: () => import("@/views/base/NewUser"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "FirstSteps",
          path: "/firstSteps",
          component: () => import("@/views/inscription/studio/FirstStep"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "calendar",
          path: "/calendar",
          component: () => import("@/views/appointments/Calendar"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "help",
          path: "/help",
          component: () => import("@/views/pages/Help"),
        },
        {
          name: "email",
          path: "/email",
          component: () => import("@/views/appointments/Email"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "notifications",
          path: "/notifications",
          component: () => import("@/views/users/UserNotificationsList"),
          meta: {
            requiresAuth: true,
          },
        },
        //*TATOOERS
        {
          name: "tattooerList",
          path: "/tattooer",
          component: () => import("@/views/tattooers/TattooerList"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "tattooerCreate",
          path: "/tattooer/new",
          component: () => import("@/views/tattooers/TattooerNew"),
          meta: {
            allowedRoles: [constants.roles.ADMIN],
          },
        },
        {
          name: "tattooerInvite",
          path: "/tattooer/invite",
          component: () => import("@/views/tattooers/TattooerInvite"),
          meta: {
            allowedRoles: [
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "tattooerGuest",
          path: "/tattooer/guest",
          component: () => import("@/views/tattooers/TattooerGuest"),
          meta: {
            allowedRoles: [
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "tattooerEdit",
          path: "/tattooer/:id/edit",
          component: () => import("@/views/tattooers/TattooerEdit"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
            ],
          },
        },
        {
          name: "tattooerView",
          path: "/tattooer/:id",
          component: () => import("@/views/tattooers/TattooerView"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },

        //*USER
        {
          name: "userList",
          path: "/users",
          component: () => import("@/views/users/UserList"),
          meta: {
            allowedRoles: [constants.roles.ADMIN],
          },
        },
        {
          name: "userCreate",
          path: "/users/new",
          component: () => import("@/views/users/UserNew"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "userEdit",
          path: "/users/:id/edit",
          component: () => import("@/views/users/UserEdit"),
          meta: {
            allowedRoles: [constants.roles.ADMIN],
          },
        },
        {
          name: "UserSettings",
          path: "/settings",
          component: () => import("@/views/users/Settings"),
        },
        {
          name: "UserPlans",
          path: "/plans",
          component: () => import("@/views/users/PlanView"),
        },
        //*CUSTOMER
        {
          name: "customerList",
          path: "/customer",
          component: () => import("@/views/customers/CustomerList"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "customerCreate",
          path: "/customer/new",
          component: () => import("@/views/customers/CustomerNew"),
          meta: {
            allowedRoles: [constants.roles.ADMIN],
          },
        },
        {
          name: "customerCreateWithoutDate",
          path: "/customer/newwithoutapdate",
          component: () => import("@/views/customers/CustomerNewWithoutAPDate"),
          meta: {
            allowedRoles: [
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },

        {
          name: "customerEdit",
          path: "/customer/:id/edit",
          component: () => import("@/views/customers/CustomerEdit"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "customerView",
          path: "/customer/:id",
          component: () => import("@/views/customers/CustomerView"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },

        //*Studios
        {
          name: "studioList",
          path: "/studios",
          component: () => import("@/views/studios/StudioList"),
          meta: {
            allowedRoles: [constants.roles.ADMIN, constants.roles.TATTOOER],
          },
        },
        {
          name: "studioCreate",
          path: "/studios/new",
          component: () => import("@/views/studios/StudioNew"),
          meta: {
            allowedRoles: [constants.roles.ADMIN],
          },
        },
        {
          name: "StudioGuest",
          path: "/studios/guest",
          component: () => import("@/views/studios/StudioGuest"),
          meta: {
            allowedRoles: [constants.roles.ADMIN, constants.roles.TATTOOER],
          },
        },
        {
          name: "studioInvite",
          path: "/studio/invite",
          component: () => import("@/views/studios/StudioInvite"),
          meta: {
            allowedRoles: [
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "studioEdit",
          path: "/studios/:id/edit",
          component: () => import("@/views/studios/StudioEdit"),
        },
        {
          name: "studioView",
          path: "/studios/:id",
          component: () => import("@/views/studios/StudioView"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        //* Payment Sistems
        {
          name: "customerView",
          path: "/stripe/back/:account_id",
          component: () => import("@/views/payments/stripe/back"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },

        //*APPOINTMENTS
        /*
                {
                  name: "ConsentFormViewNoId",
                  path: "/appointment/consent",
                  component: () => import("@/views/appointments/ConsentFormView"),
                  meta: {
                    allowedRoles: [
                      constants.roles.ADMIN,
                      constants.roles.TATTOOER,
                      constants.roles.STUDIO, constants.roles.STUDIO_ADMIN, constants.roles.STUDIO_MANAGER,
                    ],
                  },
                },
                */
        {
          name: "ConsentFormView",
          path: "/appointment/:id/consent/:customer_id/:date_id",
          component: () => import("@/views/appointments/ConsentFormView"),
        },
        {
          name: "ConsentFormPiercerView",
          path: "/appointment/:id/consentpiercer/:customer_id/:date_id",
          component: () =>
            import("@/views/appointments/ConsentFormPiercerView"),
        },
        {
          name: "ConsentFormMicroblandingView",
          path: "/appointment/:id/consentmicroblanding/:customer_id/:date_id",
          component: () =>
            import("@/views/appointments/ConsentFormMicroblandingView"),
        },
        {
          name: "ConsentFormLaserView",
          path: "/appointment/:id/consentlaser/:customer_id/:date_id",
          component: () => import("@/views/appointments/ConsentFormLaserView"),
        },
        {
          name: "appointmentList",
          path: "/appointment",
          component: () => import("@/views/appointments/AppointmentList"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
              ,
              constants.roles.STUDIO_GUEST,
            ],
          },
        },
        {
          name: "appointmentView",
          path: "/appointment/:id",
          component: () => import("@/views/appointments/AppointmentView"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
              constants.roles.STUDIO_GUEST,
            ],
          },
        },
        {
          name: "appointmentCreatingView",
          path: "creating/appointment/:id",
          component: () => import("@/views/appointments/AppointmentView"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.TATTOOER_GUEST,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
              ,
              constants.roles.STUDIO_GUEST,
            ],
          },
        },

        //*BOXES
        {
          name: "boxList",
          path: "/box",
          component: () => import("@/views/boxes/BoxList"),
          meta: {},
        },
        {
          name: "boxListRework",
          path: "/box/rework",
          component: () => import("@/views/boxes/BoxListOld"),
          meta: {},
        },
        {
          name: "boxView",
          path: "/box/:id",
          component: () => import("@/views/boxes/BoxView"),
          meta: {},
        },

        //*ECONOMY

        {
          name: "economyView",
          path: "/economy",
          component: () => import("@/views/economy/EconomyView"),
          meta: {},
        },

        //*STATS
        {
          name: "StatsView",
          path: "/stats",
          component: () => import("@/views/stats/StatsView"),
          meta: {},
        },

        //* Files
        {
          name: "filesView",
          path: "/files",
          component: () => import("@/views/users/Files"),
          meta: {},
        },
        //* Inventory
        {
          name: "inventoryList",
          path: "/inventory",
          component: () => import("@/views/inventory/InventoryTabs"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "inventoryCreate",
          path: "/inventory/new",
          component: () => import("@/views/inventory/InventoryNew"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },

        {
          name: "inventoryEdit",
          path: "/inventory/:id/edit",
          component: () => import("@/views/inventory/InventoryEdit"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "inventoryView",
          path: "/inventory/:id",
          component: () => import("@/views/inventory/InventoryView"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        // //* Category
        {
          name: "categoryList",
          path: "/category",
          component: () => import("@/views/category/CategoryList"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "categoryCreate",
          path: "/category/new",
          component: () => import("@/views/category/CategoryNew"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },

        {
          name: "categoryEdit",
          path: "/category/:id/edit",
          component: () => import("@/views/category/CategoryEdit"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        // //* Brand
        {
          name: "brandList",
          path: "/brand",
          component: () => import("@/views/brand/BrandList"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "brandCreate",
          path: "/brand/new",
          component: () => import("@/views/brand/BrandNew"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "brandEdit",
          path: "/brand/:id/edit",
          component: () => import("@/views/brand/BrandEdit"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "InvoicesList",
          path: "/invoices",
          component: () => import("@/views/invoices/List"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "InvoicesNewForm",
          path: "/invoices/new",
          component: () => import("@/views/invoices/NewInvoice"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
        {
          name: "InvoicesUpdateForm",
          path: "/invoices/:id/edit",
          component: () => import("@/views/invoices/UpdateInvoice"),
          meta: {
            allowedRoles: [
              constants.roles.ADMIN,
              constants.roles.TATTOOER,
              constants.roles.STUDIO,
              constants.roles.STUDIO_ADMIN,
              constants.roles.STUDIO_MANAGER,
            ],
          },
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "404 Error",
          path: "/",
          component: () => import("@/views/pages/Error"),
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  if (store.getters["auth/isLoggedIn"]) {
    store.dispatch("email/getUnreadEmails");
    store.dispatch("appointments/getPendingAppointments");
    store.dispatch("auth/getNotifications");
    store.dispatch("auth/getRelatedUsers");
  }
  const user = store.state.auth.user;
  if (!user) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      return next({
        path: "/auth/login",
      });
    } else {
      return next();
    }
  }

  try {
    // primero verificamos si el token es válido
    await verifyToken();

    // si el token es válido comprobamos si el usuario tiene permisos
    const user = store.state.auth.user;
    const role = user.role.id;

    if (!store.state.auth.active) {
      if (to.name !== "UserPlans" && to.name !== "UserSettings")
        return next("/settings");
    }

    // si la ruta require roles los comprobamos
    if (Object.prototype.hasOwnProperty.call(to.meta, "allowedRoles")) {
      if (!to.meta.allowedRoles.includes(role) && from.path !== "/") {
        // no tiene rol de acceso
        if (to.path !== "/") {
          return next("/");
        }
      }
    }
  } catch (e) {
    /* // si el token ha expirado
     if (e.name === 'TokenExpiredError') {
       if (from.name !== 'Login') {
         return next({
           name: 'Login',
           query: {
             redirect: from.path,
           },
         })
       }
     }*/
  }

  next();
});

export default router;
