import constants from "@/constants";
import * as moment from "moment";
export default {
  basic(state) {
    return [
      [],
      [],
      [],
      [],
      [],
      [],
      []
    ];
  },
  getActiveTimetable(state, getters) {
    if (state.active_timetable && state.active_timetable.timetable)
      return state.active_timetable.timetable;
    return getters.basic;
  },
  getDefaultTimetable(state, getters) {
    if (state.active_timetable && state.default_timetable.timetable)
      return state.default_timetable.timetable;
    return getters.basic;
  },

  getDayTimetable(state, getters) {
    return function (day, studio_id, tattooer_id = null, box_id = null) {
      let timetable = getters.getDefaultTimetable;
      if (box_id == 8) {
        console.log(day, studio_id, tattooer_id, box_id);
      }
      if (
        state.active_timetable &&
        state.active_timetable.d_start < day &&
        state.active_timetable.d_end > day
      )
        timetable = getters.getActiveTimetable;
      let t = state.timetables.find(
        (t) => {
          if (box_id == 8) {
            console.log(t);
          }
          return t.d_start <= day &&
            t.d_end >= day &&
            t.studio_id === studio_id &&
            t.tattooer_id === tattooer_id &&
            t.box_id === box_id
        }
      );
      if (t) timetable = t.timetable;
      else {
        t = state.timetables.find(
          (t) =>
          t.d_start <= day &&
          t.d_end == null &&
          t.studio_id === studio_id &&
          t.tattooer_id === tattooer_id &&
          t.box_id === box_id
        );
        if (t) timetable = t.timetable;
        else {
          t = state.timetables.find(
            (t) =>
            t.d_start <= day &&
            t.d_end >= day &&
            t.studio_id === studio_id &&
            t.tattooer_id === null &&
            t.box_id === null
          );
          if (t) timetable = t.timetable;
        }
      }
      return timetable[getters.dayOfWeek(day)];
    };
  },

  checkTime(state, getters) {
    return function (time, day, studio_id, tattooer_id = null, box_id = null) {
      let timetable = getters.getDayTimetable(
        day,
        studio_id,
        tattooer_id,
        box_id
      );

      let error = true;
      if (timetable)
        timetable.forEach((ha) => {
          if (ha[0] <= time && ha[1] > time) error = false;
        });

      return error;
    };
  },

  dayOfWeek: () => {
    return function (day) {
      let d = new Date(day);
      d = d.getDay() - 1;
      if (d < 0) d = 6;

      return d;
    };
  },

  firstHour: () => {
    return function (timetable) {
      let min_hour = "24:00";

      for (let i = 0; i < timetable.length; i++) {
        for (let j = 0; j < timetable[i].length; j++) {
          if (timetable[i][j][0] < min_hour) {
            min_hour = timetable[i][j][0];
          }
        }
      }

      return min_hour;
    };
  },

  firstHourGlobal(state, getters) {
    let min_hour = getters.firstHour(getters.getActiveTimetable);
    if (getters.firstHour(getters.getDefaultTimetable) < min_hour) {
      min_hour = getters.firstHour(state.default_timetable.timetable);
    }
    if (state.timetables)
      state.timetables.forEach((t) => {
        if (getters.firstHour(t.timetable) < min_hour) {
          min_hour = getters.firstHour(t.timetable);
        }
      });
    return min_hour;
  },

  lastHour: () => {
    return function (timetable) {
      let max_hour = "00:00";
      for (let i = 0; i < timetable.length; i++) {
        for (let j = 0; j < timetable[i].length; j++) {
          if (timetable[i][j][1] > max_hour) {
            max_hour = timetable[i][j][1];
          }
        }
      }
      return max_hour;
    };
  },

  lastHourGlobal(state, getters) {
    let max_hour = getters.lastHour(getters.getActiveTimetable);

    if (getters.lastHour(getters.getDefaultTimetable) > max_hour) {
      max_hour = getters.lastHour(state.default_timetable.timetable);
    }
    console.log(state.timetables);
    if (state.timetables)
      state.timetables.forEach((t) => {
        if (getters.lastHour(t.timetable) > max_hour) {
          max_hour = getters.lastHour(t.timetable);
        }
      });

    return max_hour;
  },

  mins: () => {
    //return [":00", ":30"];
    return [":00", ":15", ":30", ":45"];
  },

  hours: (state, getters) => {
    let hours = [];
    let first = getters.firstHourGlobal;
    if (first == "24:00") first = "00:00";
    console.log("FIRST", first);
    first = parseInt(first.split(":")[0]);
    let last = getters.lastHourGlobal;
    if (last == "00:00") last = "24:00";
    console.log("last", last);
    last = parseInt(last.split(":")[0]);

    for (let x = first; x < last; x++) {
      getters.mins.forEach((y) => {
        if (x < 10) hours.push("0" + x + y);
        else hours.push(x + y);
      });
    }

    hours.push(last + ":00");

    if (getters.lastHourGlobal.split(":")[1] == "30") hours.push(last + ":30");

    return hours;
  },
};