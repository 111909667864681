<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 476 346.88"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,329.4v-312C4.39,4.58,13.38,0,26.8,0Q238.27.39,449.73,0C463,0,471.72,4.82,476,17.39v313c-5.07,12.78-14.73,16.53-28,16.48-100.44-.33-200.88-.18-301.33-.18-40.81,0-81.62.14-122.42-.18-5.05,0-10.92-1.34-14.87-4.19S3,333.82,0,329.4ZM454,152.05H22.11V324.89H454Zm0-130H22.11V64.89H454Z"
        />
        <path
          d="M172.5,238.43q-47.49,0-95,0c-7,0-11.56-3.41-12.46-9.08a10.81,10.81,0,0,1,8-12.16,21,21,0,0,1,5-.46q95.22,0,190.43,0c7.36,0,11.9,3.43,12.64,9.39.89,7.07-3.88,12.24-11.64,12.3-12.66.1-25.32,0-38,0Z"
        />
        <path
          d="M411.07,249.28c0,7,.07,14,0,21S407,281.56,400,281.64q-21.45.21-42.91,0c-6.4-.08-10.75-4.22-10.85-10.64-.24-14.47-.21-29,0-43.42.09-6.11,4.23-10.53,10.38-10.65q22.2-.45,44.41,0c6.17.14,9.88,4.58,10,10.91.16,7.15,0,14.31,0,21.46Z"
        />
        <path
          d="M151.9,260.17c24.32,0,48.63-.13,72.95.15a18.83,18.83,0,0,1,10,3.31c3.33,2.33,4,6.42,2.69,10.42A10.12,10.12,0,0,1,229,281.5a26.54,26.54,0,0,1-4,.19H78.11a31.15,31.15,0,0,1-3.49-.11A10.56,10.56,0,0,1,65,270.76a10.43,10.43,0,0,1,10-10.45c8.31-.28,16.65-.13,25-.14Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "token_payment",
  props: ["role"],
};
</script>
