<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 283 344.55"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M283,59.07v274c-4.08,8.79-11,11.5-20.53,11.47q-120.47-.36-240.94,0c-9.73,0-17.29-2.11-21.53-11.47v-274C3.55,50,10.2,46.57,19.91,46.82c17.64.46,35.3.13,53,.13h5.89c0-2.57.12-4.54,0-6.49-1.46-21.8,6.66-26,25.25-25,7.11.37,14.26.06,21.23.06C128.45,4.33,133.23,0,141.73,0c8.18.05,13,4.55,16.08,15.52,9.44,0,19.06-.06,28.68,0,11.87.09,17.78,6,17.84,17.86,0,4.43,0,8.85,0,13.55,20.36,0,39.83.31,59.29-.13C273.23,46.6,279.56,50.2,283,59.07ZM16,328.69H266.85v-219H16Zm.08-235.08H267V62.91a11.36,11.36,0,0,0-2.17-.45c-19,0-38-.11-57,.09-1.28,0-3.27,1.83-3.69,3.2C201.76,73.31,196.56,78,189,78q-47.49.15-95,0A14.69,14.69,0,0,1,79.25,66.18c-.66-3.46-2.17-3.83-5-3.81-14.83.07-29.66,0-44.5,0H16.09ZM94.25,31.36c0,9,.22,17.53-.1,26-.16,4.26,1.34,5.28,5.4,5.25,28-.15,56-.07,83.93-.09,1.61,0,3.22-.18,5-.29V31.36Z"
        />
        <path
          d="M141,219.11H59.07a33,33,0,0,1-5-.12c-4.17-.67-6.67-3.32-6.78-7.42A7,7,0,0,1,53.25,204a33.07,33.07,0,0,1,6.45-.46q81.92,0,163.81,0a31.15,31.15,0,0,1,6,.36c4.17.82,6.45,3.63,6.25,7.79s-2.65,6.74-6.86,7.31a37.68,37.68,0,0,1-5,.11Z"
        />
        <path
          d="M125.63,266.11H58.71a32,32,0,0,1-4.49-.1c-4.18-.61-6.75-3.21-6.89-7.31s2-7,6.26-7.79a33,33,0,0,1,6-.37q66.16,0,132.34,0a34,34,0,0,1,6,.31c4.17.77,6.37,3.45,6.32,7.69s-2.58,6.7-6.66,7.45a24.76,24.76,0,0,1-4.48.12Z"
        />
        <path
          d="M102.17,172.1c-15.49,0-31,.05-46.46,0-4.94,0-8.31-3.14-8.49-7.36s3-7.56,7.85-8a48.76,48.76,0,0,1,5-.13h54.45c10.66,0,21.31,0,32,0a16.52,16.52,0,0,1,5.89.76c3.7,1.47,5.27,4.55,4.55,8.45-.83,4.46-3.93,6.33-8.29,6.33Q125.39,172.12,102.17,172.1Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "request",
  props: ["role"],
};
</script>
