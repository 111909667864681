import * as jwt from 'jsonwebtoken'
import {
  verifyToken,
  verifySpecificToken
} from "../../../utils/jwtHelper";

export default {
  async SET_USER(state, data) {
    try {
      //console.log("SET_USER")
      //console.log(data)
      state.jwt = data.token;
      state.user = await verifyToken();
      let u = state.users.find((u) => u.user.id == state.user.id)
      if (!u)
        state.users.push({
          user: state.user,
          jwt: data.token
        });
      else {
        u.jwt = data.token
        u.user = state.user
      }
      state.errorDate = null;
      state.error = null;
    } catch (e) {
      state.errorDate = new Date;
      state.error = e;
    }
  },
  SET_USER_FROM_LIST(state, user) {
    state.user = user.user;
    state.jwt = user.jwt;
  },
  SET_STUDIO_FROM_LIST(state, studio) {
    state.user.studio = studio
  },

  SET_SETTINGS(state, data) {
    state.user.setting = data
  },
  UPDATE_USER(state, data) {
    state.user = data;
  },
  UNSET_USER(state) {
    console.log("UNSET_USER")
    state.jwt = null
    state.user = null
    state.errorLogin = true
    state.users = []

  },
  INVALID_CREDENTIALS(state) {
    state.errorLogin = true
  },
  RESET_INVALID_CREDENTIALS(state) {
    try {
      state.jwt = null
      state.user = null

      state.errorLogin = false
    } catch (e) {
      //console.log(e)
    }
  },
  UPDATE_IMAGE_URL(state, url) {
    //console.log(state.user)
    let img = state.user.setting.find(x => x.key === 'profile_image')
    if (img) {
      img.url = url;
    } else {
      state.user.setting.push({
        key: 'profile_image',
        url: url
      })
    }
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_ACTIVE_DATE(state, active_date) {
    state.active_date = active_date;
  },
  BOX_ACTIVE(state, box) {
    state.box_active = box;
    //console.log(box);
  },
  async SET_RELATED_USERS(state, data) {
    for (let i = 0; i < data.length; i++) {
      let token = data[i];
      let user = await verifySpecificToken(token);
      let u = state.users.find((u) => u.user.id == user.id);

      if (!u)
        state.users.push({
          user: user,
          jwt: token
        });
      else {
        u.jwt = token
        u.user = user
      }

    };
  },

}