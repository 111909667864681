<template>
  <svg
    class="svg-icon"
    :role="role"
    id="dashboard"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.5 25.5"
  >
    <path
      id="Trazado_72"
      data-name="Trazado 72"
      d="M9.828,8.5H1.859A1.861,1.861,0,0,1,0,6.641V1.859A1.861,1.861,0,0,1,1.859,0H9.828a1.861,1.861,0,0,1,1.86,1.859V6.641A1.861,1.861,0,0,1,9.828,8.5ZM1.859,1.594a.266.266,0,0,0-.266.266V6.641a.266.266,0,0,0,.266.266H9.828a.266.266,0,0,0,.266-.266V1.859a.266.266,0,0,0-.266-.266Zm0,0"
    />
    <path
      id="Trazado_73"
      data-name="Trazado 73"
      d="M9.828,228.207H1.859A1.861,1.861,0,0,1,0,226.348V215.192a1.861,1.861,0,0,1,1.859-1.86H9.828a1.861,1.861,0,0,1,1.86,1.86v11.156A1.861,1.861,0,0,1,9.828,228.207ZM1.859,214.926a.266.266,0,0,0-.266.266v11.156a.266.266,0,0,0,.266.266H9.828a.266.266,0,0,0,.266-.266V215.192a.266.266,0,0,0-.266-.266Zm0,0"
      transform="translate(0 -202.707)"
    />
    <path
      id="Trazado_74"
      data-name="Trazado 74"
      d="M287.16,349.832h-7.969a1.861,1.861,0,0,1-1.859-1.859v-4.781a1.861,1.861,0,0,1,1.859-1.86h7.969a1.861,1.861,0,0,1,1.859,1.86v4.781A1.861,1.861,0,0,1,287.16,349.832Zm-7.969-6.906a.266.266,0,0,0-.266.266v4.781a.266.266,0,0,0,.266.266h7.969a.266.266,0,0,0,.266-.266v-4.781a.266.266,0,0,0-.266-.266Zm0,0"
      transform="translate(-263.52 -324.332)"
    />
    <path
      id="Trazado_75"
      data-name="Trazado 75"
      d="M287.16,14.875h-7.969a1.861,1.861,0,0,1-1.859-1.86V1.859A1.861,1.861,0,0,1,279.192,0h7.969a1.861,1.861,0,0,1,1.859,1.859V13.016A1.861,1.861,0,0,1,287.16,14.875ZM279.192,1.594a.266.266,0,0,0-.266.266V13.016a.266.266,0,0,0,.266.266h7.969a.266.266,0,0,0,.266-.266V1.859a.266.266,0,0,0-.266-.266Zm0,0"
      transform="translate(-263.52)"
    />
  </svg>
</template>
<script>
export default {
  name: "Dashboard",
  props: ["role"],
};
</script>
