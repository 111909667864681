<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 374 271.96"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,243.47v-215C7.14,4.68,22.71-.09,42.53,0,140.36.46,238.18.21,336,.21c2.16,0,4.34-.06,6.49.12,12.21,1,21.39,6.91,27.3,17.6,1.81,3.28,2.82,7,4.2,10.53v215C369.11,262.66,355,272.09,333,272c-98-.55-195.93-.24-293.9-.24-2.16,0-4.33,0-6.49-.07-12.88-.68-22.4-6.83-28.51-18.09C2.33,250.4,1.33,246.85,0,243.47ZM28.2,17.64c1.85,2,2.88,3.23,4,4.35Q103.06,92.82,174,163.62c8.55,8.54,17.6,8.53,26.16,0Q271,92.82,341.89,22c1.1-1.1,2.08-2.31,3.93-4.36ZM344.68,254.4,239,148.56c-8.84,8.84-18,18.05-27.18,27.19-15,14.9-34.7,14.89-49.6,0-9.19-9.17-18.34-18.38-26.48-26.54q-53,53-105.18,105.18ZM120.32,136.58,17.4,33.65V239.51ZM356.76,239.2V32.62L253.7,135.9Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "mail",
  props: ["role"],
};
</script>
