<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 568 128.91"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M568,72.38c-.61,2.23-1.27,4.44-1.81,6.68-8,32.84-39.07,54-72.08,49.15a64.7,64.7,0,0,1-55-68.24C441,28.35,467.54,1.88,499,.26c33.84-1.75,62.73,20.91,68,53.31.21,1.29.65,2.54,1,3.81Zm-25.85-7.87a38.68,38.68,0,1,0-38.77,38.68A38.73,38.73,0,0,0,542.15,64.51Z"
        />
        <path
          d="M0,57.38c3.36-9.12,5.45-19,10.32-27.23C25.32,4.8,57.09-6.08,85.19,3.37A64.44,64.44,0,1,1,1,75.08a16.2,16.2,0,0,0-1-2.7Zm103.24,7.07A38.68,38.68,0,1,0,65,103.19,38.73,38.73,0,0,0,103.24,64.45Z"
        />
        <path
          d="M284.57.13a64.3,64.3,0,0,1,64,64.43c.05,35.49-29,64.23-64.82,64.2a64.31,64.31,0,0,1-64.16-64.25C219.68,28.44,248.36,0,284.57.13Zm38.3,64.71c.32-21.11-17.11-38.91-38.4-39.2A39.07,39.07,0,0,0,245.18,64c-.28,21.17,17,38.79,38.43,39.19C304.68,103.59,322.55,86.13,322.87,64.84Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "dots",
  props: ["role"],
};
</script>
