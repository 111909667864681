<template>
  <v-dialog
    :width="width"
    v-model="dialog"
    :persistent="persistent"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        v-if="type == 'button' || type == 'block'"
        :color="color"
        elevation="0"
        :block="type == 'block'"
        height="30"
        @click="
          $emit('click_open');
          $emit('open');
        "
        :disabled="disabled"
      >
        <v-icon v-if="icon && !no_icon" left>{{ icon }}</v-icon>
        {{ btn_text || title }}
      </v-btn>
      <v-tooltip v-else-if="type == 'icon' && tooltip" bottom>
        <template v-slot:activator="{ on: onTool }">
          <v-btn
            v-on="{ ...on, ...onTool }"
            icon
            :color="color"
            small
            @click="
              $emit('click_open');
              $emit('open');
            "
            :disabled="disabled"
          >
            <v-icon v-if="icon" :large="!small" :small="x_small">{{
              icon
            }}</v-icon>
          </v-btn>
        </template>
        {{ btn_text || title }}
      </v-tooltip>
      <v-btn
        v-on="on"
        v-else-if="type == 'icon'"
        icon
        :color="color"
        small
        @click="
          $emit('click_open');
          $emit('open');
        "
        :disabled="disabled"
      >
        <v-icon v-if="icon" :large="!small" :small="x_small">{{ icon }}</v-icon>
      </v-btn>
      <v-btn
        v-on="on"
        v-else-if="type == 'fab'"
        icon
        :color="color"
        small
        fab
        outlined
        @click="
          $emit('click_open');
          $emit('open');
        "
        :disabled="disabled"
      >
        <v-icon v-if="icon" :large="!small">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-card :id="card_id">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <div class="close">
        <v-btn icon small color="white" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <slot name="outside" v-if="$slots.outside"></slot>
      <v-card-text v-if="$slots.default">
        <slot />
      </v-card-text>
      <slot name="outsite_after" v-if="$slots.outsite_after"></slot>

      <template v-if="$slots.actions || basic_btn">
        <v-card-actions>
          <slot name="actions" v-if="$slots.actions" />
          <template v-else-if="basic_btn">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="close"
              style="height: 25px; width: 100px"
              elevation="0"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              @click="$emit('save')"
              style="
                width: 100px !important;
                color: #363533;
                height: 25px;
                padding-top: 10px !important;
              "
              elevation="0"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("save", { name: $t("") }) }}
            </v-btn>
          </template>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    card_id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
    title: {
      type: String,
      default: "Abrir Dialog",
    },
    btn_text: { type: String },
    basic_btn: { type: Boolean, default: false },
    icon: {
      type: String,
      default: "mdi-plus-circle",
    },
    no_icon: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    color: {
      type: String,
      default: "primary",
    },
    small: {
      type: Boolean,
      default: false,
    },
    x_small: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$emit("open");
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
  },
};
</script>
