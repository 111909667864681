import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store/index";

import ca from "vuetify/lib/locale/ca";
import es from "vuetify/lib/locale/es";
import en from "vuetify/lib/locale/en";

const messages = {
  ca: {
    ...require("@/locales/ca.json"),
    $vuetify: ca,
  },
  es: {
    ...require("@/locales/es.json"),
    $vuetify: es,
  },
  en: {
    ...require("@/locales/en.json"),
    $vuetify: en,
  },
};
const dateTimeFormats = {
  ca: {
    dateLong: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    dateShort: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    date2digits: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    short: {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
    },
    dateLong: {
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    hours: {
      hour: "2-digit",
      hour12: false,
    },
    minutes: {
      minute: "2-digit",
    },
    calendar: {
      minute: "numeric",
      hour: "numeric",
      hour12: true,
    },
    calendarHour: {
      hour: "numeric",
      hour12: true,
    },
    agenda: {
      day: "numeric",
      month: "short",
    },
    dayMonth: {
      day: "2-digit",
      month: "2-digit",
    },
  },
  es: {
    dateShort: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    month: {
      month: "long",
    },
    date2digits: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    short: {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    dateLong: {
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    hours: {
      hour: "2-digit",
    },
    minutes: {
      minute: "2-digit",
    },
    calendar: {
      minute: "numeric",
      hour: "numeric",
      hour12: true,
    },
    calendarHour: {
      hour: "numeric",
      hour12: true,
    },
    weekDay: {
      weekday: "long",
    },
    // ho he separat basicament perque si ho ajuntava tot em sortia
    // el dia de la setmana abans que el numero
    day: {
      day: "numeric",
    },
    agenda: {
      month: "short",
    },
    week: {
      weekday: "short",
    },
    dayMonth: {
      day: "2-digit",
      month: "2-digit",
    },
  },
  en: {
    dateShort: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    month: {
      month: "long",
    },
    date2digits: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    short: {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    dateLong: {
      month: "long",
      day: "numeric",
      weekday: "long",
    },
    hours: {
      hour: "2-digit",
    },
    minutes: {
      minute: "2-digit",
    },
    calendar: {
      minute: "numeric",
      hour: "numeric",
      hour12: true,
    },
    calendarHour: {
      hour: "numeric",
      hour12: true,
    },
    weekDay: {
      weekday: "long",
    },
    agenda: {
      day: "numeric",
      month: "short",
    },
    dayMonth: {
      day: "2-digit",
      month: "2-digit",
    },
  },
};

const numberFormats = {
  ca: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    currency_no_icon: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      maximumFractionDigits: 2,
    },
    no_decimal_percent: {
      style: "percent",
      maximumFractionDigits: 0,
    },
    nodecimals: {
      maximumFractionDigits: 0,
    },
  },
  es: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    currency_no_icon: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    amount: {
      style: "currency",
      currency: "EUR",
    },
    percent: {
      style: "percent",
      maximumFractionDigits: 2,
    },
    no_decimal_percent: {
      style: "percent",
      maximumFractionDigits: 0,
    },
    no_decimal: {
      maximumFractionDigits: 0,
    }
  },
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
    currency_no_icon: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    nodecimals: {
      maximumFractionDigits: 0,
    },
    percent: {
      style: "percent",
      maximumFractionDigits: 2,
    },
    no_decimal_percent: {
      style: "percent",
      maximumFractionDigits: 0,
    },
  },
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: store.state.app.language || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: store.state.app.fallback_language ||
    process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
  dateTimeFormats,
  numberFormats,
});