export default {

  SET_TIMETABLES(state, timetables) {
    state.timetables = timetables;
  },

  SET_ACTIVE_TIMETABLE(state, timetable) {
    state.active_timetable = timetable;
  },

  SET_DEFAULT_TIMETABLE(state, timetable) {
    state.default_timetable = timetable;
  },

}