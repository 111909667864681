import constants from "@/constants";
import browserDetect from "../../../plugins/browser-detect";

export default {
  isNumber: () => {
    return function (e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    }
  },
  isMobile: () => {
    console.log("TEST");
    console.log("bd", browserDetect);
    //if (browserDetect.isSafari) return browserDetect.isIOS;
    //return window.navigator.userAgentData.mobile;
  },
  isLight: (state) => {
    return function (color) {
      // Check the format of the color, HEX or RGB?
      let r;
      let g;
      let b;
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {
        return false;
      }

      return true;
    };
  },
  language: (state) => {
    return state.language;
  },
  range: () => {
    return function (min, max) {
      let a = [];
      for (let i = min; i <= max; i++) a.push(i);
      return a;
    };
  },
  randomColor: () => {
    return function () {
      let rgb1 = Math.floor(Math.random() * 255) + 0;
      let rgb2 = Math.floor(Math.random() * 255) + 0;
      let rgb3 = Math.floor(Math.random() * 255) + 0;
      return "rgb(" + rgb1 + "," + rgb2 + "," + rgb3 + ")";
    };
  },
  formatTime: () => {
    return function (time) {
      let format = "";

      if (time >= 60) {
        format += Math.floor(time / 60) + " h ";
      }

      if (time % 60 > 0) {
        format += (time % 60) + " min";
      }

      return format;
    };
  },

  yesNo: () => {
    return [{
        text: "Si",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ];
  },

  addObject: (state, getters) => {
    return function (formData, object, prefix = null) {
      console.log(
        "==========================================================="
      );
      console.log("object", object);
      console.log("perfix", prefix);
      Object.keys(object).forEach((key) => {
        console.log("key", key);
        //console.log(object[key]);
        console.log(typeof object[key]);
        if (typeof object[key] === "object" && object[key] !== null) {
          //console.log("object");
          let new_prefix = prefix ? prefix + "[" + key + "]" : key;
          getters.addObject(formData, object[key], new_prefix);
        } else if (typeof object[key] === "array") {
          //console.log("array");
          object[key].forEach((item, index) => {
            let new_prefix = prefix ?
              prefix + "[" + key + "][" + index + "]" :
              key + "[" + index + "]";
            getters.addObject(formData, item, new_prefix);
          });
        } else {
          //console.log("other");
          console.log(prefix ? prefix + "[" + key + "]" : key, object[key]);
          formData.append(prefix ? prefix + "[" + key + "]" : key, object[key]);
        }
      });
    };
  },

  getInner: () => {
    return function (object, key) {
      let item = object;
      key.split(".").forEach((k) => {
        item = item[k];
      });
      return item;
    };
  },
  dayOfWeek: () => {
    return function (date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    };
  }
};