import constants from "@/constants";
import * as moment from "moment";
export default {
  studioId: (state) => {

  },
  timeFrom: (state) => {
    if (state.timetableStudio.length > 0) {
      let min = "23:00";

      state.timetableStudio.forEach((dia) => {
        dia.forEach((opening_time) => {
          let h = opening_time[0];
          if (h <= min) {
            min = h;
          }
        });
      });
      return min;
    }
  },
  timeTo: (state) => {
    if (state.timetableStudio.length > 0) {
      let max = "00:00";

      state.timetableStudio.forEach((dia) => {
        dia.forEach((close_time) => {
          let h = close_time[1];
          if (h >= max) {
            max = h;
          }
        });
      });
      return max;
    }
  },
  mins: () => {
    //return [":00", ":30"];
    return [":00", ":15", ":30", ":45"];
  },
  today: () => {
    return moment().format("YYYY-MM-DD");
  },
  hours: (state, getters) => {
    if (state.timetableStudio.length > 0) {

      let hours = [];
      let first = parseInt(getters.timeFrom.split(":")[0]);
      let last = parseInt(getters.timeTo.split(":")[0]);
      for (let x = first; x < last; x++) {
        getters.mins.forEach((y) => {
          if (x < 10) hours.push("0" + x + y);
          else hours.push(x + y);
        });
      }
      //console.log(hours);
      hours.push(last + ":00");
      if (getters.timeTo.split(":")[1] == "30") hours.push(last + ":30");
      return hours;
    }
  },
  hoursRow: (state, getters) => {
    if (state.timetableStudio.length > 0) {
      let hours = [];
      let first = parseInt(getters.timeFrom.split(":")[0]);
      let last = parseInt(getters.timeTo.split(":")[0]);
      for (let x = first; x < last; x++) {
        getters.mins.forEach((y) => {
          if (x < 10) hours.push("0" + x + y);
          else hours.push(x + y);
        });
      }
      return hours;
    }
  },
  checkTime: (state, getters) => {
    return function (h, day) {
      let dof = getters.dayOfWeek(day);
      let dh = state.timetableStudio[dof];
      if (state.filtersWeekState.tattooer) {
        let t = state.tattooers.find(t => t.id === state.filtersWeekState.tattooer);
        if (t.timetable) {
          dh = t.timetable[dof];
        }
      }

      //TODO Tattooer filter & Holidays

      let error = true;

      dh.forEach((ha) => {
        if (ha[0] <= h && ha[1] > h) error = false;
      });
      return error;
    }
  },
  dayOfWeek: () => {
    return function (day) {
      let d = new Date(day);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    }
  },
  daysWeek: (state) => {
    let arr = [];
    if (state.filtersState.from) {

      let n_days = 7;
      let time = new Date(state.filtersState.from);

      for (let i = 0; i < n_days; i++) {
        arr.push(time.toISOString().slice(0, 10));
        time.setDate(time.getDate() + 1);
      }

    }
    return arr;
  }
}