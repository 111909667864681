import {
  set,
  toggle
} from '@/utils/vuex'

export default {
  SET_BAR_IMAGE: set('barImage'),
  SET_SCRIM: set('barColor'),
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  SET_HOVER(state, payload) {
    state.hover = payload
  },
  API_ERROR(state, e) {
    state.error = e;
  },
  SET_SESSION_EXPIRED: set('session_expired'),
  SET_PROCESSING(state, action) {
    if (action.show)
      state.processing++;
    else state.processing--;
    if (state.processing < 0) state.processing = 0;
  },
  SET_NOTIFICATION: set('notification'),
  SET_UPDATE: set('update'),
  SET_CHANGE_USER: set('change_user'),
  SET_TAB(state, tab) {
    state.tab_state[tab.tab] = tab.value;
  }
}