<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 488 267.55"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M488,241v19c-3.44,6-8.69,7.56-15.43,7.54-64.11-.19-128.22-.11-192.33-.12-10.14,0-13.66-3.52-14.14-13.71-.43-9-2.86-17.41-9.06-24.25-7.94-8.76-18.68-8.57-26.41.37-5.79,6.71-8.2,14.76-8.66,23.43-.59,10.87-3.92,14.17-14.72,14.17-63.78,0-127.56-.08-191.33.11C9.05,267.57,3.6,266.13,0,260V241c1.26-9.53,2.15-19.12,3.83-28.56C17.57,135.54,56.28,74.63,123.76,34.57,193.44-6.81,267.24-11.28,341,22.44c78.16,35.76,123.85,98.72,141.83,182.13C485.39,216.54,486.31,228.86,488,241ZM66.9,245.09c.17-1.49.31-2.48.39-3.47,3.81-45.11,19.83-85.2,51.16-118.14C161.34,78.41,214,59.69,275.6,70.85c72,13,126.74,72.73,141.34,144.7,2,9.71,3.07,19.59,4.56,29.39h43.88c-.43-5-.71-9.63-1.22-14.22C457.24,168.54,431,116.09,383.4,75.27,336.71,35.24,282.11,18,220.86,24.5c-54.58,5.82-100.17,30.37-137,70.84-34,37.35-53.24,81.58-59.68,131.48-.77,6-1,12.08-1.44,18.27Zm66.72-.05c.16-1.27.28-2.08.37-2.88,2.76-24.92,11.65-47.36,27.34-67,30.67-38.39,81-51.82,124.3-31.76,41.13,19,62,53.32,68.16,97.21.58,4.06,2,4.81,5.56,4.75,11.49-.17,23-.05,34.49-.07,1.6,0,3.19-.2,4.74-.3a16.64,16.64,0,0,0,.14-2.05c-3-35.76-15.3-67.83-37.72-96-41.19-51.79-110.19-71.17-170.75-46.69-48.61,19.66-78.68,56.58-94.16,106A149.74,149.74,0,0,0,89.4,245Zm22.16.25c14.69,0,28.5.07,42.31-.16,1.07,0,2.73-2,3.07-3.35,2.39-9.5,6.2-18.26,12.56-25.81,12.77-15.14,31.66-19.33,48-10.31,13.9,7.68,21.25,20.24,24.73,35.25.8,3.42,2.07,4.59,5.61,4.48,8.33-.28,16.66-.09,25-.09h14.81c-.24-15-7.82-35.89-18.13-50.76-28.41-41-80.77-50.38-119.11-21.08C171.22,191.34,159.5,215.78,155.78,245.29Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "color",
  props: ["role"],
};
</script>
