<template>
  <v-card
    elevation="4"
    id="border-card"
    @click="$emit('card_clicked')"
    :disabled="disabled"
    :class="[{ disabled: disabled_class }, classes]"
  >
    <v-row
      v-if="$slots.corner_action"
      style="
        display: flex;
        justify-content: center !important;
        flex-direction: column;
        align-items: center !important;
        position: absolute;
        right: 20px;
      "
    >
      <slot name="corner_action"></slot>
    </v-row>
    <v-card-title
      v-if="icon"
      class="
        justify-center
        text-decoration-none text-center
        d-block
        text-uppercase
        py-5
      "
      :class="[{ double }]"
    >
      <v-row justify="center">
        <v-icon>{{ icon }}</v-icon>
        <v-col
          :cols="two_lines ? 12 : null"
          v-html="title"
          class="py-0 title_disabled"
        ></v-col>
      </v-row>
    </v-card-title>
    <v-card-title
      v-else
      class="
        justify-center
        text-decoration-none text-center
        d-block
        text-uppercase
        py-5
      "
      :class="[{ double: (two_lines && !titleIsMultiple) || subtitle == '' }]"
      v-html="formatedTitle"
    />
    <v-card-subtitle
      v-if="subtitle"
      class="mt-0 pb-2 text-center primary--text"
    >
      {{ subtitle }}
    </v-card-subtitle>
  </v-card>
</template>
<script>
export default {
  mounted() {
    console.log(this.$slots);
  },
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false },
    two_lines: { type: Boolean, default: false }, //* para que se vea en dos lineas
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: null },
    disabled_class: { type: String, default: null },
    classes: { type: String, default: null },
  },
  computed: {
    formatedTitle() {
      let title = "";
      this.titleSplit.forEach((x, i) => {
        if (i > 0)
          if (i == 1 && this.two_lines) title += "<br />";
          else title += " ";
        title += x;
      });
      return title;
    },
    titleIsMultiple() {
      return this.titleSplit.length > 1;
    },
    titleSplit() {
      return this.title.split(" ");
    },
  },
};
</script>
<style lang="sass" scoped>
#border-card
  border: 1px solid var(--v-primary-base) !important
  &.disabled,
  &.v-card--disabled
    border: 1px solid var(--v-gris1-base) !important
    .v-card__title
      color: var(--v-gris1-base) !important
    .v-card__text
      color: #fff !important
      text-align: center !important

    .svg-icon
      color: var(--v-gris1-base) !important
    .title_disabled
      color: var(--v-gris1-base) !important

  .v-card__title
    font-size: 18px !important

    &.double
      padding-top: 34px !important
      padding-bottom: 34px !important

  .v-card__text
    color: #fff !important
    text-align: center !important
</style>
