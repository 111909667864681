<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 374 374.32"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,187.29c.88-3.88,1.56-7.82,2.67-11.64,7-24.06,22-41,45-50.93,1.36-.58,2.74-1.11,4.4-1.79C48.45,100.55,54.16,81.09,71.29,66s37.15-18.25,59.3-11.6c.8-1.26,1.7-2.6,2.52-4C155.06,13.53,197.44-6,238.84,1.65c43,8,75.77,41.06,82.8,84,1.07,6.53.76,13.28,1.41,19.9.15,1.61,1.14,3.89,2.42,4.55,27.06,13.93,43.26,35.66,47.81,65.89a4.17,4.17,0,0,0,.72,1.27v19c-2.24,8-3.52,16.36-6.9,23.81-14.67,32.35-40.1,50-75.6,51.62-23.12,1-46.32.3-69.48.24-5.92,0-9.46-3.54-9.37-8.74.1-5,3.53-8.16,9.34-8.18,22.16-.09,44.32.06,66.48-.07,31.2-.19,57.22-19.76,65.9-49.34,9.36-31.9-6.67-66.77-36.95-80.36-4.07-1.83-8.17-3.6-12.74-5.6,3.95-25.71-.59-49.47-17-70.18-11.84-15-27.18-24.71-45.61-29.76-30.7-8.41-81.33,1.8-103.35,55.76-4.31-1.47-8.67-2.9-13-4.46-16.2-5.86-31-3.27-43.74,8.18S65.68,105,69.36,121.51c.9,4,1.77,8.09,2.77,12.65-4.92,1.71-9.75,3.31-14.52,5.06C33.76,148,19.68,165.13,17.43,190.3c-2.23,25,8.64,44.21,30.23,57.08C57.06,253,67.46,255,78.3,255c24.16,0,48.32-.09,72.48.14,2.89,0,6.68,1,8.38,3s2.39,6.34,1.28,8.53c-1.22,2.43-4.81,4.57-7.68,5.08-4.69.83-9.63.26-14.46.23-22.82-.13-45.71.74-68.45-.68C34.54,269.11,6.26,241.89.94,207a15.18,15.18,0,0,0-.94-2.7Z"
        />
        <path
          d="M186,374.32c-2.87-2.18-6-4.07-8.55-6.57C162.5,353,147.7,338.13,132.84,323.3c-.94-.94-1.93-1.84-2.79-2.85-3.26-3.83-3.18-9,.13-12.16s8.48-3.11,12.12.45c8.09,7.93,16.07,16,24,24,3.61,3.64,7.1,7.41,10.64,11.12l1.26-.42c.08-1.86.23-3.71.23-5.57q0-93.7,0-187.41a49,49,0,0,1,.3-7.48,8,8,0,0,1,8.5-6.84c4.45.16,7.07,2.66,8,6.88a27,27,0,0,1,.23,5.48q0,94.46,0,188.91v6.89c1.9-1.64,3-2.49,4-3.48Q214.4,326,229.22,311.16c.95-.94,1.85-1.93,2.87-2.79,3.7-3.12,8.59-3.13,11.77-.07a8.25,8.25,0,0,1,.07,12.09Q218.63,346,193,371.26c-1.36,1.34-3.35,2.05-5,3.06Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "download",
  props: ["role"],
};
</script>
