<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 356 354.47"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,93c3.7-9.53,10.78-12.75,20.8-12.67,39.82.3,79.66.12,119.49.12h3c6.76.11,10.49,3,10.44,8.12,0,5-3.89,8-10.64,8q-60,.06-120,0H16.39v32.33H141.47a33.45,33.45,0,0,1,5.49.18c4.16.71,6.57,3.45,6.71,7.52s-2.18,7-6.19,8a22.58,22.58,0,0,1-5.47.28H48.85V338H307.16V144.93h-5.65c-29.5,0-59,0-88.5-.08-2.51,0-5.89-.39-7.32-2-1.8-2-3.27-5.81-2.57-8.15s4-4.19,6.61-5.59c1.42-.77,3.59-.21,5.42-.21H339.54v-32c-2-.1-4.05-.3-6.13-.3l-118,0a38.88,38.88,0,0,1-6.48-.33c-4.22-.71-6.46-3.65-6.5-7.68s2.15-7,6.4-7.73a34.94,34.94,0,0,1,6-.34c40.33,0,80.67.15,121-.13C345.66,80.31,352.48,83.7,356,93v40c-6.23,15.43-20.24,11.22-32.28,12.05v94.58q0,48.25,0,96.5c0,8.93-3.75,15.08-10.8,17.28a30.18,30.18,0,0,1-8.87,1q-126,.07-252,0a48.74,48.74,0,0,1-5-.19,15.67,15.67,0,0,1-14.4-13.65,51.77,51.77,0,0,1-.3-7.48q0-90.73,0-181.48V145c-12.52-.42-26,3-32.35-12Z"
        />
        <path
          d="M187.3,229.6q11.89-12.06,23.78-24.13c3-3.08,6-6.2,9.21-9.17,4-3.8,8.62-3.91,12-.5s3.16,8-.75,12c-12.35,12.4-24.8,24.68-37.21,37l-8.51,8.46c-6.07,6-9.56,6.09-15.55.13Q148,231.18,125.66,208.94c-.47-.47-1-.93-1.4-1.43-3.48-3.86-3.69-8.39-.55-11.63,3.33-3.45,8.08-3.46,12,.36q14.63,14.35,29,29a36.68,36.68,0,0,1,3.53,4.87l1.81-.91v-217a34.59,34.59,0,0,1,.1-5c.63-4.35,3.21-6.87,7.64-7.13a7.61,7.61,0,0,1,8.18,7.06,48,48,0,0,1,.11,5.5V228.92Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "file",
  props: ["role"],
};
</script>
