import {
  isValid,
  isNIE
} from 'better-dni'

export function dni(nif) {
  return isValid(nif)
}

export function dniOrNieOrPassport(doc) {
  const regex = new RegExp('^[A-Z][0-9]{8}$')
  return isValid(doc) || isNIE(doc) || regex.test(doc)
}

export function cif(cif) {
  if (!cif || cif.length !== 9) {
    return false
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = cif.substr(1, cif.length - 2)
  const letter = cif.substr(0, 1)
  const control = cif.substr(cif.length - 1)
  let sum = 0
  let i
  let digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = parseInt(digit / 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

export function ss(ssn) {

  var ssnDiv = ssn.match(/(\d{2})\/(\d{7,8})-(\d{2})/)
  if (ssn === "00/0000000-00" || ssn === "00/00000000-00") return false
  else if (ssnDiv != null) {

    let num1 = ssnDiv[1];
    let num2 = ssnDiv[2]
    let num3 = ssnDiv[3]
    let num4 = null
    if (num1 && num2 && num3) {
      if (num2.length === 8 && num2[0] === "0")
        num4 = `${+num1}${num2.substr(1)}`
      else num4 = `${+num1}${num2}`
      let validate = num4 % 97
      if (validate === parseInt(num3)) {
        return true
      }
    }
  }
  return false;
}

export function isGmail(email) {
  return email.indexOf("@gmail.") != -1
}
export function fValidarTarjeta(codigo) {
  codigo = codigo.replace('-', '');
  let VISA = /^4[0-9]{3}-?[0-9]{4}-?[0-9]{4}-?[0-9]{4}$/;
  let MASTERCARD = /^5[1-5][0-9]{2}-?[0-9]{4}-?[0-9]{4}-?[0-9]{4}$/;
  let AMEX = /^3[47][0-9-]{16}$/;
  let CABAL = /^(6042|6043|6044|6045|6046|5896){4}[0-9]{12}$/;
  let NARANJA = /^(589562|402917|402918|527571|527572|0377798|0377799)[0-9]*$/;


  if (luhn(codigo)) {
    return codigo.match(VISA) || codigo.match(MASTERCARD) || codigo.match(NARANJA) || codigo.match(CABAL) || codigo.match(AMEX)

  } else {
    return false;
  }
}

function luhn(value) {
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;
  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false;
  value = value.replace(/\D/g, "");
  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
    nCheck += nDigit;
    bEven = !bEven;
  }
  return (nCheck % 10) == 0;
}