<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 432 352.67"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,320.27v-248a8.58,8.58,0,0,0,.87-1.74C6.18,50.25,20.48,39.23,41.51,39.22c11.82,0,23.65-.11,35.47,0a29.86,29.86,0,0,0,22.83-9.4c4.27-4.43,8.35-9,12.46-13.63C121.91,5.49,133.64-.07,148.34,0q67.93.35,135.89,0c13.28,0,24.37,4.48,33.44,14.07,4.92,5.2,9.6,10.62,14.59,15.75a30,30,0,0,0,22.84,9.41c12.65-.16,25.31-.1,38,0a38.61,38.61,0,0,1,27,11c6.45,6,9.56,13.79,11.91,22v248a7.69,7.69,0,0,0-.92,1.65c-5.24,19.51-19.39,30.69-39.62,30.7q-175.18.11-350.37,0c-20.72,0-34.52-10.78-40.15-30.72A7.9,7.9,0,0,0,0,320.27Zm20-65.62c-.1,2.14-.24,3.76-.24,5.38q0,25.49,0,51c0,14.44,7.73,22.12,22.26,22.12q174.21,0,348.42,0c14.09,0,21.81-7.82,21.83-22q0-25.49,0-51v-5.46H318.48c2.49-5.5,4.93-10.21,6.76-15.15,1.22-3.31,2.9-4.43,6.48-4.4,25,.17,50,.09,75,.09h5.59v-5.71c0-49.49-.23-99,.16-148.47.1-13-8.6-22.79-22.26-22.33-12,.4-24,0-36,.1-14,.11-25.58-5-34.91-15.44-4.56-5.08-9.13-10.16-13.93-15a28.82,28.82,0,0,0-21.09-8.83q-68.23,0-136.47,0a28.52,28.52,0,0,0-21.09,8.81c-4.65,4.77-9.13,9.7-13.57,14.67-9.55,10.69-21.36,16-35.76,15.8-12-.17-24-.11-36,0-14,.12-21.62,7.8-21.62,21.73q0,74.49,0,149c0,1.77.16,3.53.26,5.7h5.83c25,0,50,0,75,.07,1.6,0,4.09.52,4.63,1.58,2.85,5.58,5.2,11.41,8,17.81H20Z"
        />
        <path
          d="M314.11,196.16a98.09,98.09,0,1,1-196.18-.94c0-53.54,44.22-97.3,98.08-97.16C270.63,98.21,314.21,141.79,314.11,196.16Zm-19.52-.08c-.49-44-35.5-78.85-78.91-78.59a78.55,78.55,0,0,0-78.16,79.28c.22,43.44,36.11,78,80.34,77.49C259.89,273.73,295.05,237.9,294.59,196.08Z"
        />
        <path
          d="M363.16,78.35c16-.08,29.49,13.1,29.64,29a29.43,29.43,0,1,1-29.64-29Zm.11,39.06a9.66,9.66,0,1,0-.14-19.31,9.66,9.66,0,0,0,.14,19.31Z"
        />
        <path
          d="M216,58.76c-15.66,0-31.32,0-47,0-4.43,0-8.29-.92-10.62-5.11-3.67-6.61.87-14.26,8.75-14.41,9.49-.17,19,0,28.49,0q34.23,0,68.46,0c6.06,0,10,3.12,10.79,8.3.68,4.23-1.91,9-6,10.43a20.63,20.63,0,0,1-6.39.76C247,58.79,231.45,58.76,216,58.76Z"
        />
        <path
          d="M373.05,166.36a9.75,9.75,0,1,1-9.55-9.56A9.69,9.69,0,0,1,373.05,166.36Z"
        />
        <path
          d="M215.42,254.65A58.89,58.89,0,0,1,157.08,195c.51-32.51,27.34-58.2,60.31-57.73,32.21.45,58,27.33,57.54,59.93C274.46,229.12,247.58,255.1,215.42,254.65Zm39.79-58.76a39.19,39.19,0,1,0-39.39,39.28A39.08,39.08,0,0,0,255.21,195.89Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "save",
  props: ["role"],
};
</script>
