<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160.94 160.94"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,159.71v-4c2-5.44,4.12-10.81,5.8-16.33,1.38-4.53,2.77-8.61,8.63-7.84V66.11A42.11,42.11,0,0,1,7.74,66,73.52,73.52,0,0,1,0,63.89v-18c2.27-1.32,4.52-2.66,6.8-4L80,0h1A8.83,8.83,0,0,0,82.1.91l78.84,45v19l-14.33,1.91v65c5.29-.92,6.94,2.19,8.2,6.2,1.86,5.95,4.07,11.8,6.13,17.7v4c-2.23.41-4.47,1.17-6.71,1.18q-73.75.11-147.52,0C4.48,160.88,2.24,160.12,0,159.71ZM19.59,43.1l.27.79H141.59c-.68-.74-.91-1.16-1.27-1.37-19.17-11-38.32-22-57.6-32.77-1.31-.73-3.87-.31-5.35.49C68,15.33,58.85,20.66,49.63,25.93Q34.6,34.5,19.59,43.1ZM9.29,153.44h142.8c-1.06-3.18-2.36-5.93-2.84-8.8-.76-4.54-3.14-5.61-7.54-5.6-38.45.17-76.89.1-115.34.1C12.46,139.14,12.46,139.14,9.29,153.44Zm85.49-87H66.2v65.08H94.78ZM153.59,52H7.66v6.46H153.59ZM36.32,131.64V66.47h-14v65.17Zm7.89-65.23v65.11h14V66.41Zm80.5,0v65.09h13.94V66.36Zm-7.94-.25c-3.36,0-6.51.24-9.6-.06-3.83-.36-4.8,1.18-4.77,4.82.16,19,.07,37.93.09,56.9a36.61,36.61,0,0,0,.44,3.8h13.84Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Studio",
  props: ["role"],
};
</script>
