import constants from "@/constants";
export default {
    isLoggedIn: (state) => {
        return !!state.user;
    },
    isAdmin: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.ADMIN;
        }
        return false;
    },
    isTattooer: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.TATTOOER || state.user.role.id === constants.roles.TATTOOER_GUEST;
        }
        return false;
    },
    isTattooerMain: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.TATTOOER;
        }
        return false;
    },
    isTattooerStudio: (state) => {
        if (state.user) {
            return (
                state.user.role.id === constants.roles.TATTOOER &&
                state.user.tattooer.has_studio
            );
        }
        return false;
    },
    isStudio: (state) => {
        if (state.user) {
            return (
                state.user.role.id === constants.roles.STUDIO ||
                state.user.role.id === constants.roles.STUDIO_MANAGER ||
                state.user.role.id === constants.roles.STUDIO_ADMIN ||
                state.user.role.id === constants.roles.STUDIO_GUEST
            );
        }
        return false;
    },
    isMainStudio: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.STUDIO;
        }
        return false;
    },
    isStudioAdmin: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.STUDIO_ADMIN;


        }
        return false;
    },
    isStudioManager: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.STUDIO_MANAGER

        }
        return false;
    },
    isStudioGuest: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.STUDIO_GUEST;
        }
        return false;
    },
    isCustomer: (state) => {
        if (state.user) {
            return state.user.role.id === constants.roles.CUSTOMER;
        }
        return false;
    },
    canSeeCashRegisters(state, getters) {
        if (state.user) {
            console.log("canSeeCashRegisters", getters.isMainStudio);
            return getters.isMainStudio || state.user.can_see_cash_registers
        }
        return false
    },
    canEditCashRegisters(state, getters) {
        if (state.user) {
            console.log("canEditCashRegisters", getters.isMainStudio);
            return getters.isMainStudio || state.user.can_edit_cash_registers
        }
        return false
    },
    filterCashRegisters(state, getters) {
        return function (cashRegisters) {
            if (getters.isMainStudio) {
                return cashRegisters
            } else {
                return cashRegisters.filter(cashRegister => {
                    let cr = state.user.access_to_cash_registers.find(access => access === cashRegister.id);
                    if (cr)
                        return true;
                    return false;
                })
            }
        }
    },
    userImage: (state) => {
        let img = state.user.setting.find((x) => x.key === "profile_image");
        if (!img) {
            img = {
                url: require("@/assets/mini-logo.png")
            };
        }

        return img.url;
    },
    hasUserImage: (state) => {
        let img = state.user.setting.find((x) => x.key === "profile_image");
        if (img) {
            return true;
        }

        return false;
    },
    rrss: (state) => {
        if (state.user.role.id === constants.roles.TATTOOER)
            return state.user.tattooer.social_networks || {};
        if (state.user.role.id === constants.roles.STUDIO)
            return state.user.studio.social_networks || {};
        return {};
    },
    accounts: (state, getters, rootState) => {
        if (state.user) {
            let accounts = state.user.setting; //.filter(x => x.key.startsWith("account_"));
            let formattedAccounts = [];
            let a;
            for (a of accounts) {
                if (a.key === "account_google_access_token") {
                    let v = JSON.parse(a.value);
                    let account;
                    for (account of v) {
                        formattedAccounts.push({
                            mail_name: "Gmail",
                            mail_id: 1,
                            icon: "$gmail",
                            email: account.user.email,
                            image: account.user.picture,
                        });
                    }
                } else if (a.key === "account_microsoft_access_token") {
                    formattedAccounts.push({
                        mail_name: "Outlook",
                        mail_id: 2,
                        icon: "$outlook",
                        email: "Outlook",
                    });
                } else if (a.key === "account_IMAP_access_token") {
                    //console.log(a);

                    formattedAccounts.push({
                        mail_name: "IMAP",
                        mail_id: 3,
                        icon: "$imap",
                        email: "IMAP",
                    });
                }
            }
            return formattedAccounts;
        }
        return false;
    },

    accountsCalendar: (state, getters, rootState) => {
        if (state.user) {
            let accounts = state.user.setting.filter((x) =>
                x.key.startsWith("account_")
            );
            let formattedAccounts = [];
            formattedAccounts.push({
                calendar_name: "Quickink",
                calendar_id: 0,
                img: "@/assets/logo.png",
                email: "Quickink",
            });
            let a;
            for (a of accounts) {
                if (a.key === "account_google_access_token") {
                    let v = JSON.parse(a.value);
                    let account;
                    for (account of v) {
                        formattedAccounts.push({
                            calendar_name: "Gmail",
                            calendar_id: 1,
                            icon: "$gmail",
                            email: account.user.email,
                            image: account.user.picture,
                        });
                    }
                } else if (a.key === "account_microsoft_access_token") {
                    formattedAccounts.push({
                        calendar_name: "Outlook",
                        calendar_id: 2,
                        icon: "$outlook",
                        email: "Outlook",
                    });
                }
            }
            return formattedAccounts;
        }
        return false;
    },
    googleAccounts: (state, getters, rootState) => {
        if (state.user) {
            let accounts = state.user.setting.find(
                (x) => x.key === "account_google_access_token"
            );
            if (accounts) return JSON.parse(accounts.value);
        }
        return false;
    },

    notificationdCount: (state) => {
        return state.notifications.length;
    },
    getLaborExange: (state) => {
        ////console.log("getter", state.user);

        if (state.user.setting.length) {
            let s = state.user.setting.find((x) => x.key === "labor_exange");
            if (s) {
                return s.value;
            }
        }
        return null;
    },
    getPlan: (state) => {
        return function (key) {
            return state.user.plan[key];
        };
    },
    getSetting: (state) => {
        return function (key) {
            ////console.log("getter", state.user);
            if (state.user)
                if (state.user.setting.length) {
                    let s = state.user.setting.find((x) => x.key === key);
                    if (s) {
                        if (isNaN(s.value)) return s.value;
                        return +s.value;
                    }
                }
            switch (key) {

                case "labor_exange":
                case "labor_exange_tattooer":
                case "labor_exange_help":
                    return null;
                case "studio_services":
                    return "[]";

                case "comision_type":
                    return "from"
                case "assistance_appointment":
                case "box_active":
                case "comision_has_definition":
                case "box_price":
                case "alert_active":
                case "qr_active":
                case "calendar_active":
                case "consentForm_active":
                case "payment_box":
                case "box_tattooer_associate":
                case "consentForm_edit":
                case "pm_stripe_id":
                case "alert_sms":
                case "alert_email":
                case "boxes_obligatoris":
                case "marketplace_active_tattooer":
                case "marketplace_active_piercer":
                case "marketplace_active_microblading":
                case "marketplace_active_laser":
                case "calendar_relations_configurated":
                    return 0;
                case "comisions_active":
                case "payment_appointment":
                    return 1;
                case "alert_days_0":
                case "alert_days_1":
                case "calendar_id":
                case "policy_appointment":
                case "link_appointment":
                case "page_policy_appointment":
                case "labor_exange":
                    return null;
                case "center_image_mark":
                    return "0px";
                case "center_image_align":
                    return "center";
                case "commisions_predeterminated":
                    return {
                        type: null,
                            price: null,
                            comisions: [],
                    };
                case "pending_setting":
                    return {
                        tattooer: true,
                            date: true,
                            box: false,
                            presupuesto: false,
                            token_payment: false,
                            consent: false,
                    };
                case "requested_client_information":
                    return {
                        first_name: {
                                view: true,
                                required: true,
                            },
                            last_name: {
                                view: true,
                                required: true,
                            },
                            email: {
                                view: true,
                                required: true,
                            },
                            phone: {
                                view: true,
                                required: false,
                            },
                            date_of_birth: {
                                view: true,
                                required: false,
                            },
                            height: {
                                view: true,
                                required: false,
                            },
                            weight: {
                                view: true,
                                required: false,
                            },
                            allergies: {
                                view: true,
                                required: false,
                            },
                            nativeCity: {
                                view: true,
                                required: false,
                            },
                            meet: {
                                view: true,
                                required: false,
                            },
                    };
                default:
                    return "";
            }
        };
    },
};