import Vue from 'vue'

export default {

  deleteEmail: async function ({
    commit,
    state
  }, {
    email_id,
    account
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.delete(`users/me/emails/${email_id}`, {
        body: {
          mail_id: account
        }
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  deleteEmails: async function ({
    commit,
    state
  }, {
    emails_id,
    account
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.delete(`users/me/emails/massive`, {
        body: {
          mail_id: account,
          emails_id
        }
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  archiveEmail: async function ({
    commit,
    state
  }, {
    email_id,
    label_id,
    actual_label_id,
    account
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`users/me/emails/${email_id}/archive`, {
        mail_id: account,
        label_id,
        actual_label_id

      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  archiveEmails: async function ({
    commit,
    state
  }, {
    emails_id,
    label_id,
    actual_label_id,
    account
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`users/me/emails/archive-massive`, {

        mail_id: account,
        emails_id,
        label_id,
        actual_label_id,
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getEmails: async function ({
    commit,
    state
  }, filters) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...filters
      }
      return await Vue.http.get(`users/me/emails`, {
        params
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getLabels: async function ({
    commit,
    state
  }, filters) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...filters
      }
      return await Vue.http.get(`users/me/emails/labels`, {
        params
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  setAsRead: async function ({
    commit,
    state
  }, {
    message_id,
    mail_id
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        message_id,
        mail_id
      };
      return await Vue.http.post(`users/me/emails/read`, params).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  setAsUnread: async function ({
    commit,
    state
  }, {
    message_id,
    mail_id
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        message_id,
        mail_id
      };
      return await Vue.http.post(`users/me/emails/unread`, params).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  setEmailsAsRead: async function ({
    commit,
    state
  }, {
    emails_id,
    mail_id
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        emails_id,
        mail_id
      };
      return await Vue.http.post(`users/me/emails/read-massive`, params).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getUnreadEmails: async function ({
    commit,
    state
  }) {
    try {

      const {
        body
      } = await Vue.http.get(`users/me/emails/unread`);
      commit('email/SET_UNREAD_EMAILS', body, {
        root: true
      })
    } catch (e) {
      //console.log(e);
    } finally {

    }
  },

  sendEmail: async function ({
    commit,
    state
  }, email) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`users/me/emails`, email).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
}