import Vue from 'vue'
export default {
  getCashRegisters: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {}
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...pagination,
        ...filters
      }
      return await Vue.http.get(`cashRegisters`, {
        params
      }).then(response => {
        return response.json();
      });
    } catch (e) {
      console.log("getCashRegistersError", e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getCashRegister: async function ({
    commit,
    state
  }, {
    id
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`cashRegisters/${id}`).then(response => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addCashRegister: async function ({
      commit,
      state
    },
    cashRegister
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`cashRegisters`, cashRegister).then(response => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  updateCashRegister: async function ({
    commit,
    state
  }, {
    id,
    cashRegister
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`cashRegisters/${id}`, cashRegister).then(response => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getDefaultCashRegister: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`users/me/cash_register`).then(response => {
        return response.json();
      });
    } catch (e) {
      console.log("getDefaultCashRegisterError", e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  }


}