import constants from '@/constants'
export default {
  getTattooerLaborExange: (state) => {
    return function (tattooer, key) {
      ////console.log("getter", tattooer);
      if (tattooer) {
        if (tattooer.user.setting.length) {
          let s = tattooer.user.setting.find(x => x.key === key);
          if (s) {
            return s.value
          }
        }
      }
      return "day";
    }
  },
  servicePrice: (state) => {
    return function (service) {
      switch (service.marketplace_pay) {
        case "token_payment":
          return service.paymentSignalPrice;
        case "price":
          return service.price * service.session;
      }
    }
  },
  serviceSelected: state => {
    let s = state.subServicesState.find(s => s.id === state.customerState.sub_type_id);
    console.log(s);
    if (s) return s;
    return {
      body_part: "all",
      sub_body_part: "all"
    }

  }
}