<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    width="30.935"
    height="22.235"
    viewBox="0 0 30.935 22.235"
  >
    <g id="cash-money" transform="translate(0 -72)">
      <g id="Grupo_114" data-name="Grupo 114" transform="translate(9.184 74.9)">
        <g id="Grupo_113" data-name="Grupo 113">
          <path
            id="Trazado_110"
            data-name="Trazado 110"
            d="M156.35,120a4.35,4.35,0,1,0,4.35,4.35A4.356,4.356,0,0,0,156.35,120Zm0,7.734a3.384,3.384,0,1,1,3.384-3.384A3.387,3.387,0,0,1,156.35,127.734Z"
            transform="translate(-152 -120)"
          />
        </g>
      </g>
      <g
        id="Grupo_116"
        data-name="Grupo 116"
        transform="translate(17.884 73.933)"
      >
        <g id="Grupo_115" data-name="Grupo 115">
          <path
            id="Trazado_111"
            data-name="Trazado 111"
            d="M302.767,105.45h-.483a.968.968,0,0,1-.967-.967.483.483,0,0,0-.483-.483H296v.967h4.412a1.936,1.936,0,0,0,1.872,1.45v5.8a1.936,1.936,0,0,0-1.872,1.45H296v.967h4.834a.483.483,0,0,0,.483-.483.968.968,0,0,1,.967-.967h.483a.483.483,0,0,0,.483-.483v-6.767A.483.483,0,0,0,302.767,105.45Z"
            transform="translate(-296 -104)"
          />
        </g>
      </g>
      <g
        id="Grupo_118"
        data-name="Grupo 118"
        transform="translate(1.933 73.933)"
      >
        <g id="Grupo_117" data-name="Grupo 117">
          <path
            id="Trazado_112"
            data-name="Trazado 112"
            d="M34.839,104.967H39.25V104H34.417a.484.484,0,0,0-.483.483.968.968,0,0,1-.967.967h-.483a.484.484,0,0,0-.483.483V112.7a.484.484,0,0,0,.483.483h.483a.968.968,0,0,1,.967.967.484.484,0,0,0,.483.483H39.25v-.967H34.839a1.938,1.938,0,0,0-1.873-1.45v-5.8A1.938,1.938,0,0,0,34.839,104.967Z"
            transform="translate(-32 -104)"
          />
        </g>
      </g>
      <g
        id="Grupo_120"
        data-name="Grupo 120"
        transform="translate(20.303 77.297)"
      >
        <g id="Grupo_119" data-name="Grupo 119" transform="translate(0 0)">
          <rect
            id="Rectángulo_285"
            data-name="Rectángulo 285"
            height="5"
            transform="translate(-0.303 -0.297)"
          />
        </g>
      </g>
      <g
        id="Grupo_122"
        data-name="Grupo 122"
        transform="translate(21.186 77.297)"
      >
        <g id="Grupo_121" data-name="Grupo 121" transform="translate(0 0)">
          <rect
            id="Rectángulo_286"
            data-name="Rectángulo 286"
            width="1"
            height="5"
            transform="translate(-0.186 -0.297)"
          />
        </g>
      </g>
      <g
        id="Grupo_124"
        data-name="Grupo 124"
        transform="translate(5.296 77.297)"
      >
        <g id="Grupo_123" data-name="Grupo 123" transform="translate(0 0)">
          <rect
            id="Rectángulo_287"
            data-name="Rectángulo 287"
            height="5"
            transform="translate(-0.296 -0.297)"
          />
        </g>
      </g>
      <g
        id="Grupo_126"
        data-name="Grupo 126"
        transform="translate(7.945 77.297)"
      >
        <g id="Grupo_125" data-name="Grupo 125" transform="translate(0 0)">
          <rect
            id="Rectángulo_288"
            data-name="Rectángulo 288"
            height="5"
            transform="translate(0.055 -0.297)"
          />
        </g>
      </g>
      <g id="Grupo_128" data-name="Grupo 128" transform="translate(0 72)">
        <g id="Grupo_127" data-name="Grupo 127">
          <path
            id="Trazado_113"
            data-name="Trazado 113"
            d="M26.585,72H.483A.484.484,0,0,0,0,72.483V86.017a.484.484,0,0,0,.483.483h12.6a4.35,4.35,0,1,0,8.64,0h4.864a.483.483,0,0,0,.483-.483V72.483A.483.483,0,0,0,26.585,72ZM17.4,90.368a3.387,3.387,0,0,1-3.384-3.384,3.435,3.435,0,0,1,.037-.483h6.692A3.364,3.364,0,0,1,17.4,90.368Zm8.7-4.834H.967V72.967H26.1Z"
            transform="translate(0 -72)"
          />
        </g>
      </g>
      <g
        id="Grupo_130"
        data-name="Grupo 130"
        transform="translate(3.867 79.734)"
      >
        <g id="Grupo_129" data-name="Grupo 129">
          <path
            id="Trazado_114"
            data-name="Trazado 114"
            d="M90.585,200H88.168v.967H90.1v12.567H64.967v-5.8H64v6.284a.484.484,0,0,0,.483.483h26.1a.483.483,0,0,0,.483-.483V200.483A.483.483,0,0,0,90.585,200Z"
            transform="translate(-64 -200)"
          />
        </g>
      </g>
      <g
        id="Grupo_132"
        data-name="Grupo 132"
        transform="translate(21.751 83.117)"
      >
        <g id="Grupo_131" data-name="Grupo 131">
          <path
            id="Trazado_115"
            data-name="Trazado 115"
            d="M366.767,256h-.483v6.767a1.937,1.937,0,0,0-1.872,1.45H360v.967h4.834a.483.483,0,0,0,.483-.483.968.968,0,0,1,.967-.967h.483a.483.483,0,0,0,.483-.483v-6.767A.483.483,0,0,0,366.767,256Z"
            transform="translate(-360 -256)"
          />
        </g>
      </g>
      <g id="Grupo_134" data-name="Grupo 134" transform="translate(5.8 87.468)">
        <g id="Grupo_133" data-name="Grupo 133">
          <path
            id="Trazado_116"
            data-name="Trazado 116"
            d="M98.839,331.867a1.937,1.937,0,0,0-1.873-1.45V328H96v2.9a.484.484,0,0,0,.483.483h.483a.968.968,0,0,1,.967.967.484.484,0,0,0,.483.483h4.834v-.967Z"
            transform="translate(-96 -328)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Economy",
  props: ["role"],
};
</script>
