import Vue from "vue";

export default {
  getUsers: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {},
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const filterParams = Object.keys(filters)
        .map(
          (k) => `${encodeURIComponent(k)}=${encodeURIComponent(filters[k])}`
        )
        .join("&");
      const filterPagination = Object.keys(pagination)
        .map(
          (k) => `${encodeURIComponent(k)}=${encodeURIComponent(pagination[k])}`
        )
        .join("&");

      return await Vue.http
        .get(`users?${filterPagination}&${filterParams}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  checkUserEmail: async function ({
    commit,
    state
  }, {
    email,
    id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        email
      }
      return await Vue.http
        .get(`users/${id}/check-email`, {
          params
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  countUsers: async function ({
    commit,
    state
  }, {

    filters
  } = {
    filters: {},
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters
      }
      return await Vue.http
        .get(`users/count`, {
          params
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getUser: async function ({
    commit,
    state
  }, user_id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`users/${user_id}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  deleteUser: async function ({
    commit,
    state
  }, user_id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.delete(`users/${user_id}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  editUser: async function ({
    commit,
    state
  }, {
    user_id,
    user
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/${user_id}`, user)
        .then((response) => {
          return response.json();
        })
        .catch((errorResponse) => {
          const err = errorResponse.data.errors;
          Object.keys(err).forEach((key) => {
            err[key].forEach((errorMsg) => {
              commit(
                "app/SET_NOTIFICATION", {
                  message: errorMsg,
                  show: true,
                  color: "error",
                }, {
                  root: true,
                }
              );
            });
          });
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addUser: async function ({
    commit,
    state
  }, {
    user
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users`, user)
        .then((response) => {
          return response.json();
        })
        .catch((errorResponse) => {
          const err = errorResponse.data.errors;
          Object.keys(err).forEach((key) => {
            err[key].forEach((errorMsg) => {
              commit(
                "app/SET_NOTIFICATION", {
                  message: errorMsg,
                  show: true,
                  color: "error",
                }, {
                  root: true,
                }
              );
            });
          });
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  restablishPassword: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/${id}/password-reset`)
        .then((response) => {
          return response.json();
        })
        .catch((errorResponse) => {
          const err = errorResponse.data.errors;
          Object.keys(err).forEach((key) => {
            err[key].forEach((errorMsg) => {
              commit(
                "app/SET_NOTIFICATION", {
                  message: errorMsg,
                  show: true,
                  color: "error",
                }, {
                  root: true,
                }
              );
            });
          });
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  settingUpdate: async function ({
      dispatch,
      commit,
      state
    },

    user
  ) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.post(`users/settings`, user);
      commit("auth/SET_USER", body, {
        root: true,
      });
      await dispatch("auth/getSettings", {}, {
        root: true
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  deleteSetting: async function ({
      dispatch,
      commit,
      state
    },

    user
  ) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.delete(`users/settings/${user}`);
      commit("auth/SET_USER", body, {
        root: true,
      });
      await dispatch("auth/getSettings", {}, {
        root: true
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getSetting: async function ({
      commit,
      state
    },

    key
  ) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`users/settings`, {
        params: {
          key
        }
      }).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
};