<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 364.41 341.51"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M343,0V63.32c1.75.44,3.15.82,4.57,1.14a21,21,0,0,1,16.75,20.3c.14,6.49.07,13,.06,19.49,0,13.94-6.48,21.5-20.83,23.94v85c.89.2,2.11.5,3.35.77,10.59,2.31,17.29,10.43,17.46,21.34.09,6,0,12,0,18,0,14.65-6.36,22.24-21.21,24.74v63.46H0V0ZM321.09,213.41v-85c-15.44-2.35-21.94-12.56-21-25.73.41-5.81,0-11.66.11-17.5.22-10.42,6.71-18.32,16.88-20.74l4.15-1V21.33H21.52V320.27H321.18V278.2c-15.75-3.43-21-10.05-21-26.13,0-5,0-10,0-15C300.22,223.31,307.06,215.5,321.09,213.41Z"
        />
        <path
          d="M171.56,85.4a85.56,85.56,0,0,1,85.63,86.28c-.27,47-39.39,85.18-86.82,84.78-46.75-.4-85.12-39.14-84.89-85.7A85.58,85.58,0,0,1,171.56,85.4ZM236,181.54c-4.94,0-7.92.11-10.89,0-6.14-.27-10.6-4.59-10.78-10.29-.19-6.07,4-10.52,10.5-11,3.09-.21,6.2,0,11.21,0-4.13-9.7-7.67-18-11.2-26.34l-2.13-.27a73.23,73.23,0,0,1-5.79,6.83c-4.7,4.39-11.06,4.31-15.23.09s-4.26-10.49.19-15.23c2-2.14,4.36-4,7-6.33-8.68-5.91-16.95-9.57-26.75-11,0,3.37.12,6.18,0,9-.34,6.75-4.81,11.12-11.07,11-6-.12-10.16-4.47-10.46-11.07-.14-2.9,0-5.82,0-9-9.93,1.94-18.47,5-26.51,11.4,2.45,2.19,4.56,3.85,6.4,5.76,4.79,5,5,11.09.59,15.57-4.23,4.32-10.74,4.17-15.62-.4-2.12-2-4.19-4-7.33-7-3.88,9.32-7.32,17.59-11.26,27.1,5,0,8.1-.19,11.2,0,6.26.46,10.34,4.79,10.33,10.66s-4.44,10.38-10.9,10.65c-3,.12-5.94,0-10.62,0l10.92,26.23,2.39.75c1.57-2.15,2.91-4.53,4.75-6.4,5.28-5.36,11.7-5.59,16.31-.86,4.35,4.45,4,10.72-1.24,15.95-1.84,1.85-4.19,3.2-6.74,5.1,9.24,6.21,17.5,10,27.32,11.39,0-3.27-.08-5.91,0-8.55.26-6.87,4.52-11.44,10.61-11.5,6.34-.06,10.75,4.6,11,11.67.09,2.72,0,5.45,0,9.78l27.61-11.51c-3.35-3-5.56-4.77-7.5-6.79-4.77-5-5-11.16-.67-15.61s10.7-4.42,15.55.34c2.11,2.06,3.59,4.75,5.36,7.16l2.4-.93Z"
        />
        <path
          d="M150,170.79a21.39,21.39,0,0,1,42.77.09,21.39,21.39,0,1,1-42.77-.09Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Appointments",
  props: ["role"],
};
</script>
