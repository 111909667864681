<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 454 453.64"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M454,446.14c-2.66,5.71-7.25,7.5-13.36,7.5q-182.73-.13-365.46-.08c-9.84,0-13.17-3.41-13.18-13.25V291.82c0-1.33-.06-2.67.06-4a10.39,10.39,0,0,1,10-9.45,10.27,10.27,0,0,1,10.34,9,44.26,44.26,0,0,1,.19,6q0,66.24,0,132.48v6.87H432.94V20.9H82.55v6.72q0,66.48,0,133a37.09,37.09,0,0,1-.3,6,9.78,9.78,0,0,1-10.54,8.57A10.15,10.15,0,0,1,62,165.52c-.09-1.16,0-2.33,0-3.5q0-74.24,0-148.48C62,3.3,65.2.11,75.44.11q182.48,0,365-.11C446.58,0,451,1.83,454,7.14Z"
        />
        <path
          d="M0,224.14c2.8-6.16,7.78-7.73,14.22-7.72q156.95.17,313.9.08h7.75c-1.91-2.1-3-3.4-4.2-4.59q-29.51-29.53-59-59c-3.14-3.13-5.12-6.55-3.85-11.12a10.08,10.08,0,0,1,15.92-5.57,28.65,28.65,0,0,1,3.31,3q39.09,39,78.15,78.07c7.14,7.13,7.11,11.9,0,19.06q-38.88,38.88-77.79,77.72c-1.86,1.85-3.93,3.84-6.3,4.73a9.34,9.34,0,0,1-11.26-3c-2.81-3.57-3.38-7.61-.9-11.62a26.07,26.07,0,0,1,3.85-4.55q28.8-28.83,57.65-57.57a51,51,0,0,1,4.29-3.45l-.55-1.5h-5.55q-157.45,0-314.91.11c-6.41,0-11.28-1.57-14.65-7.08Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "move",
  props: ["role"],
};
</script>
