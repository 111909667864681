<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    width="24.423"
    height="16.282"
    viewBox="0 0 24.423 16.282"
  >
    <g id="email_1_" data-name="email (1)" transform="translate(0 -85.333)">
      <g id="Grupo_68" data-name="Grupo 68" transform="translate(0 85.333)">
        <path
          id="Trazado_62"
          data-name="Trazado 62"
          d="M23.17,85.333H1.252A1.256,1.256,0,0,0,0,86.585v13.777a1.256,1.256,0,0,0,1.252,1.252H23.17a1.256,1.256,0,0,0,1.252-1.252V86.585A1.256,1.256,0,0,0,23.17,85.333Zm-.47.939L12.932,93.6a1.309,1.309,0,0,1-1.441,0L1.722,86.272Zm-5.218,7.81,5.323,6.575c.005.007.012.012.018.018H1.6c.006-.006.012-.011.018-.018L6.94,94.083a.47.47,0,0,0-.73-.591L.939,100V86.859l9.988,7.491a2.242,2.242,0,0,0,2.567,0l9.988-7.491V100l-5.271-6.511a.47.47,0,0,0-.73.591Z"
          transform="translate(0 -85.333)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Email",
  props: ["role"],
};
</script>
