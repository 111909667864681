import Vue from 'vue'
import i18n from '../i18n'
import validationMessagesEs from 'vee-validate/dist/locale/es'
import validationMessagesEn from 'vee-validate/dist/locale/en'

import validationMessagesCA from 'vee-validate/dist/locale/ca'
import VeeValidate, {
  Validator,
  localize
} from "vee-validate";

import {
  cif,
  dni,
  dniOrNieOrPassport,
  ss,
  isGmail,
  fValidarTarjeta
} from '../utils/veeValidateRules'
import iban from 'iban'

Validator.extend('cif', {
  getMessage: field => 'The ' + field + ' value is not valid.',
  validate: value => cif(value),
})

Validator.extend('dni', {
  getMessage: field => 'The ' + field + ' value is not valid.',
  validate: value => dni(value),
})

Validator.extend('dniOrNieOrPassport', {
  getMessage: field => 'The ' + field + ' value is not valid.',
  validate: value => dniOrNieOrPassport(value),
})

Validator.extend('ss', {
  // 281234567840
  getMessage: field => 'The ' + field + ' value is not valid.',
  validate: value => ss(value),
})

Validator.extend('iban', {
  // ES3731906691405439544486
  getMessage: field => 'The ' + field + ' value is not valid.',
  validate: value => iban.isValid(value),
})

Validator.extend('isGmail', {
  // ES3731906691405439544486
  getMessage: field => 'Debe ser un Gmail',
  validate: value => isGmail(value),
})

Validator.extend('ccn', {
  // ES3731906691405439544486
  getMessage: field => 'Número de tarjeta incorrecto',
  validate: value => fValidarTarjeta(value),
})

const dictionary = {
  en: {
    messages: {
      required: () => 'required',

    }
  },
  es: {
    messages: {
      required: () => 'obligatorio',

    }
  }
};

//console.log(validationMessagesEs);
Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    es: {
      messages: {
        ...validationMessagesEs.messages,
        required: () => 'Obligatorio',
      }
    },
    en: {
      messages: {
        ...validationMessagesEn.messages,
        required: () => 'Required',
      }
    },
  },
})