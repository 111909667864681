import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";
import store from "@/store/index";

import TikTok from "@/components/ui/icons/TikTok";
import Google from "@/components/ui/icons/Google";
import Calendar from "@/components/ui/icons/Calendar";
import Email from "@/components/ui/icons/Email";
import Dashboard from "@/components/ui/icons/Dashboard";
import Files from "@/components/ui/icons/Files";
import Tattooers from "@/components/ui/icons/Tattooers";
import Appointments from "@/components/ui/icons/Appointments";
import ArrowBack from "@/components/ui/icons/Arrow_Back";
import Customers from "@/components/ui/icons/Customers";
import Economy from "@/components/ui/icons/Economy";
import UnderAge from "@/components/ui/icons/UnderAge";
import Location from "@/components/ui/icons/Location";
import Clock from "@/components/ui/icons/Clock";
import Facebook from "@/components/ui/icons/Facebook";
import Gmail from "@/components/ui/icons/Gmail";
import Outlook from "@/components/ui/icons/Outlook";
import Imap from "@/components/ui/icons/Imap";
import Invoice from "@/components/ui/icons/Invoice";
import Delete from "@/components/ui/icons/Delete";
import Clip from "@/components/ui/icons/Clip";
import Save from "@/components/ui/icons/Save";
import File from "@/components/ui/icons/File";
import Star from "@/components/ui/icons/Star";
import Send from "@/components/ui/icons/Send";
import Move from "@/components/ui/icons/Move";
import Origin from "@/components/ui/icons/Origin";
import Mail2 from "@/components/ui/icons/Mail2";
import Mail from "@/components/ui/icons/Mail";
import List from "@/components/ui/icons/List";
import Inbox from "@/components/ui/icons/Inbox";
import Inventory from "@/components/ui/icons/Inventory";
import Eye from "@/components/ui/icons/Eye";
import More from "@/components/ui/icons/More";
import Tattoo_Style from "@/components/ui/icons/Tattoo_Style";
import TattooType from "@/components/ui/icons/TattooType";
import EditProfile from "@/components/ui/icons/EditProfile";
import Edit from "@/components/ui/icons/Edit";
import Dots from "@/components/ui/icons/Dots";
import Square from "@/components/ui/icons/Square";
import Copy from "@/components/ui/icons/Copy";
import Color from "@/components/ui/icons/Color";
import Request from "@/components/ui/icons/Request";
import Reservation_sistem from "@/components/ui/icons/Reservation_sistem";
import Folder from "@/components/ui/icons/Folder";
import Client from "@/components/ui/icons/Client";
import Info from "@/components/ui/icons/Info";
import Camera from "@/components/ui/icons/Camera";
import ConsentForm from "@/components/ui/icons/ConsentForm";
import Time from "@/components/ui/icons/Time";
import Euro from "@/components/ui/icons/Euro";
import Download from "@/components/ui/icons/Download";
import Token from "@/components/ui/icons/Token_payment";
import Boxes from "@/components/ui/icons/Boxes";
import Calendar3 from "@/components/ui/icons/Calendar3";
import Form from "@/components/ui/icons/Form";
import Report from "@/components/ui/icons/Report";
import Refund from "@/components/ui/icons/Refund";
import Ticket from "@/components/ui/icons/Ticket";
import Help from "@/components/ui/icons/Help";
import Lock from "@/components/ui/icons/Lock";
import Unlock from "@/components/ui/icons/Unlock";
import Portion from "@/components/ui/icons/Portion";
import Iphone from "@/components/ui/icons/Iphone";
import Phone from "@/components/ui/icons/Phone";
import Dolar from "@/components/ui/icons/Dolar";
import Grafic from "@/components/ui/icons/Grafic";
import Refresh from "@/components/ui/icons/Refresh";
import Hide from "@/components/ui/icons/Hide";
import Studio from "@/components/ui/icons/Studio";

import Artists from "@/components/ui/icons/Artists";
import Notes from "@/components/ui/icons/Notes";
import Notification from "@/components/ui/icons/Notification";

import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const dark = {
  primary: "#e4bb6d",
  secondary: "#2196F3",
  green1: "#4CAF50",
  gris1: "#7b7b7b",
  gris2: "#393939",
  gris3: "#272727",
  gris4: "#303030",
  card_background: "#1e1e1e",
  accent: "#2196F3",
  info: "#00CAE3",
  login_error: "#8D2222",
  silver: "#C0C0C0",
  bronze: "#cd7f32",
  purple: "#9c27b0",
};

const light = {
  primary: "#e4bb6d",
  secondary: "#2196F3",
  green1: "#4CAF50",
  gris1: "#7b7b7b",
  gris2: "#7b7b7b",
  gris3: "#272727",
  gris4: "#303030",
  grey: "white",
  accent: "#2196F3",
  info: "#00CAE3",
  login_error: "#8D2222",
  silver: "#C0C0C0",
  bronze: "#cd7f32",
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    current: store.state.app.language,
  },

  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark,
      light,
    },
  },
  icons: {
    values: {
      tik_tok: {
        // name of our custom icon
        component: TikTok, // our custom component
      },

      client: {
        component: Client,
      },

      save: {
        component: Save,
      },
      calendar1: {
        component: Calendar3,
      },
      refresh: {
        component: Refresh,
      },
      dolar: {
        component: Dolar,
      },
      grafic: {
        component: Grafic,
      },
      hide: {
        component: Hide,
      },
      studio: {
        component: Studio,
      },
      portion: {
        component: Portion,
      },
      lock: {
        component: Lock,
      },
      unlock: {
        component: Unlock,
      },
      help: {
        component: Help,
      },
      form: {
        component: Form,
      },
      report: {
        component: Report,
      },
      refund: {
        component: Refund,
      },
      ticket: {
        component: Ticket,
      },

      folder: {
        component: Folder,
      },
      boxes: {
        component: Boxes,
      },
      request: {
        component: Request,
      },
      reservation_sistem: {
        component: Reservation_sistem,
      },
      token_payment: {
        component: Token,
      },
      download: {
        component: Download,
      },
      euro: {
        component: Euro,
      },
      color: {
        component: Color,
      },

      artists: {
        component: Artists,
      },
      notes: {
        component: Notes,
      },
      notification: {
        component: Notification,
      },

      copy: {
        component: Copy,
      },

      square: {
        component: Square,
      },

      dots: {
        component: Dots,
      },

      edit: {
        component: Edit,
      },

      editprofile: {
        component: EditProfile,
      },

      tattoo_style: {
        component: Tattoo_Style,
      },
      tattoo_type: {
        component: TattooType,
      },

      eye: {
        component: Eye,
      },
      phone: {
        component: Phone,
      },
      iphone: {
        component: Iphone,
      },
      inbox: {
        component: Inbox,
      },
      inventory: {
        component: Inventory,
      },
      list: {
        component: List,
      },

      mail: {
        component: Mail,
      },

      mail2: {
        component: Mail2,
      },

      move: {
        component: Move,
      },
      origin: {
        component: Origin,
      },

      send: {
        component: Send,
      },

      star: {
        component: Star,
      },

      file: {
        component: File,
      },
      arrow_back: {
        component: ArrowBack,
      },

      clip: {
        component: Clip,
      },

      delete: {
        component: Delete,
      },

      google: {
        component: Google,
      },

      calendar: {
        component: Calendar,
      },

      email: {
        component: Email,
      },

      dashboard: {
        component: Dashboard,
      },

      files: {
        component: Files,
      },

      tattooers: {
        component: Tattooers,
      },

      appointments: {
        component: Appointments,
      },

      customers: {
        component: Customers,
      },

      economy: {
        component: Economy,
      },

      location: {
        component: Location,
      },

      clock: {
        component: Clock,
      },

      facebook: {
        component: Facebook,
      },

      gmail: {
        component: Gmail,
      },

      outlook: {
        component: Outlook,
      },

      imap: {
        component: Imap,
      },

      info: {
        component: Info,
      },

      camera: {
        component: Camera,
      },

      consent_form: {
        component: ConsentForm,
      },

      time: {
        component: Time,
      },
      more: {
        component: More,
      },
      invoice: {
        component: Invoice,
      },
      underage: {
        component: UnderAge,
      },
    },
  },
});
