import Vue from 'vue'

export default {
  getArchives: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...pagination,
        ...filters
      };

      return await Vue.http.get(`archives`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getArchive: async function ({
    commit,
    state
  }, id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`archives/${id}`).then(response => {
        return response.json();
      });

    } catch (e) {
      console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  generateArchives: async function ({
    commit,
    state
  }, formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.post(`archives`, formData).then(response => {
        return response.json();
      });

    } catch (e) {
      console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  updateArchives: async function ({
    commit,
    state
  }, {
    id,
    formData
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.post(`archives/${id}`, formData).then(response => {
        return response.json();
      });

    } catch (e) {
      console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

}