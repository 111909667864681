<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 270 270"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M270,123v24a22.94,22.94,0,0,0-.87,3.3,134.19,134.19,0,0,1-96.36,114c-9.37,2.68-19.17,3.87-28.77,5.74H125a38,38,0,0,0-3.75-.93C64.64,261.28,26.3,230.64,7,176.87,3.52,167.32,2.26,157,0,147V124c.68-4.07,1.22-8.17,2.05-12.21C13,58.72,44.11,23.37,95.42,6.16,104.6,3.08,114.46,2,124,0h23a25.28,25.28,0,0,0,3.24.92C204.55,8.77,242,38.19,261.82,89.28,266,100,267.35,111.73,270,123ZM12.23,135c.1,67.85,55.5,122.85,123.41,122.53,67.48-.31,122.25-55.35,122.19-122.78S202.48,12.12,134.94,12.23C67.25,12.34,12.13,67.51,12.23,135Z"
        />
        <path
          d="M130.25,195.62c2.05-7.29-1.36-9-7.72-10.35C107,182,98,169.35,99.37,154.27h13c2.92,5.9,5,11.61,8.44,16.39,1.59,2.23,5.71,2.64,9.68,4.28,0-11.69.07-22.13-.12-32.56,0-1-1.59-2.3-2.72-2.84-3.61-1.7-7.48-2.85-11-4.67-11.73-6.07-16.89-15.85-15.46-28.71,1.22-10.91,8.94-19.57,21.3-22.2,6-1.27,10-2.77,7.89-10.14h7.91c.23,2.75.46,5.49.68,8.16,21.28,5.81,29,13.44,27.63,27.78-4.07,0-8.24.3-12.31-.19-1.19-.14-2.68-2.4-3.06-3.94-1.73-7-5.08-10.06-12.54-10.23,0,8.95-.09,17.91.12,26.85,0,1,1.48,2.35,2.59,2.89,2.83,1.38,5.85,2.38,8.77,3.57,14.93,6.1,20.76,16.07,18.91,32.28-1.48,13-11.3,22.59-25.49,25l-4.81.77v8.86Zm8.65-22c8.93-.42,14.58-5.83,15.09-14.18.57-9.25-4.47-15-15.09-17Zm-8.84-51.37V95.29c-8.83.36-12.78,3.9-13.56,11.76C115.74,114.69,120.3,119.89,130.06,122.21Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Dolar",
  props: ["role"],
};
</script>
