<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 402 400.81"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M402,241.33c-4.34,10.43-12.27,14.13-23.36,13.9-21.65-.44-43.33-.11-65-.13-14,0-21.29-7.18-21.33-21.2q-.09-33.75,0-67.5c0-13.45,7.26-20.63,20.78-20.67,21.84,0,43.68.32,65.5-.15,11.06-.24,19,3.39,23.4,13.75Zm-90.94-4.89a21.14,21.14,0,0,0,2.6.35c22.14,0,44.28,0,66.42.09,3.8,0,3.79-2,3.78-4.74-.05-21.31,0-42.62,0-63.94,0-1.27-.24-2.54-.38-3.9H311.06Z"
        />
        <path
          d="M0,160.33c3.68-10.85,11.44-14.89,22.63-14.71,22,.34,44,.08,66,.11,14,0,21.15,7.2,21.17,21.29q0,33,0,66c0,15.06-7,22.09-21.92,22.1-21.16,0-42.32-.35-63.47.15C13,255.52,4.59,252.18,0,241.33Zm18.23,3.95v4.49c0,21,.12,41.95-.1,62.92,0,4.32,1.44,5.25,5.43,5.21,21-.17,42-.07,62.92-.09,1.44,0,2.88-.22,4.49-.34V164.28Z"
        />
        <path
          d="M0,14.33C4,3.65,11.68-.24,22.93,0c22.15.5,44.32.13,66.48.15,13.13,0,20.32,7.22,20.35,20.44q.06,34,0,68c0,13.45-7.35,20.73-20.69,20.74-21.83,0-43.66-.35-65.47.15-11.3.26-19.37-3.36-23.6-14.14ZM18.5,90.91H90.89V18.57H18.5Z"
        />
        <path
          d="M0,305.33C4.36,294.67,12.38,291,23.69,291.25c21.82.52,43.65.14,65.49.16,13,0,20.52,7.35,20.57,20.38q.12,34.5,0,69c-.06,12.53-7.4,19.79-20,19.83-22.33.08-44.66-.31-67,.16C11.52,401,3.85,397,0,386.33Zm18.5,76.81H90.84V309.89H18.5Z"
        />
        <path
          d="M402,387.33c-4.64,10.54-12.88,13.75-24,13.47-21.62-.56-43.27-.14-64.91-.17-13.51,0-20.69-7.08-20.73-20.55q-.1-34,0-67.91c0-13.63,7.37-20.76,21.13-20.76,21.64,0,43.28.35,64.91-.15,11.27-.26,19.37,3.31,23.64,14.07Zm-18.51-5.1V310H311.1v72.26Z"
        />
        <path
          d="M402,95.33c-4.1,10.58-11.91,14.37-23.07,14.14-22.15-.47-44.31-.12-66.47-.15-12.48,0-20-7.26-20.11-19.76q-.23-35,0-70C292.43,7.36,299.9.2,312.19.17c22.49-.05,45,.32,67.47-.15C390.78-.22,398.19,3.87,402,14.33Zm-18.55-4.4V18.55H311.17V90.93Z"
        />
        <path
          d="M146.17,200c0-11.5-.06-23,0-34.49.08-12.14,7.5-19.69,19.65-19.74q35-.15,70,0c12.76.05,20.05,7.55,20.07,20.32q0,34.49,0,69c0,12.39-7.43,20-19.89,20q-35,.15-70,0c-12.44-.06-19.79-7.66-19.83-20.09C146.13,223.3,146.17,211.64,146.17,200Zm91.18-35.5H165v72h72.37Z"
        />
        <path
          d="M200.64,109.33c-11.66,0-23.33,0-35,0s-19.42-7.62-19.46-19.38q-.12-35.25,0-70.48c0-11.77,7.63-19.25,19.5-19.28q35.49-.09,71,0c11.55,0,19.15,7.57,19.19,19.06q.12,35.49,0,71c0,11.39-7.8,19-19.23,19.09C224.63,109.38,212.64,109.33,200.64,109.33Zm36.65-90.64H164.9V91h72.39Z"
        />
        <path
          d="M200.65,400.63c-11.67,0-23.33.08-35,0-12.15-.1-19.46-7.54-19.48-19.79q-.06-34.49,0-69c0-13.13,7.34-20.41,20.48-20.43q34.5,0,69,0c12.76,0,20.2,7.45,20.22,20.16q.06,34.5,0,69c0,12.62-7.21,19.88-19.73,20.07H200.65Zm-36.14-18.41h72.67V309.56c-19.59,0-38.73-.05-57.88,0-4.76,0-11.05-1.24-13.81,1.24-2.4,2.15-1,8.76-1,13.39C164.47,343.38,164.51,362.53,164.51,382.22Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Boxes",
  props: ["role"],
};
</script>
