<template>
  <span
    v-if="getInner()(item, header.value) == undefined"
    :key="`empty_${header.value}`"
  >
    -
  </span>
  <span v-else-if="type == 'basic'" :key="`basic_${header.value}`">
    {{ getInner()(item, header.value) }}
  </span>
  <span v-else-if="type == 'number'" :key="`number_${header.value}`">
    {{ $n(getInner()(item, header.value), cast) }}
  </span>
  <span v-else-if="type == 'date'" :key="`date_${header.value}`">
    {{ $d(new Date(getInner()(item, header.value)), cast) }}
  </span>

  <span v-else-if="type == 'boolean'" :key="`boolean_${header.value}`">
    <span v-if="cast == 'text'">
      {{ getInner()(item, header.value) ? header.true : header.false }}
    </span>
    <span v-else-if="cast == 'icons'">
      <v-icon
        :color="
          getInner()(item, header.value)
            ? header.true_color
            : header.false_color
        "
        >{{
          getInner()(item, header.value) ? header.true : header.false
        }}</v-icon
      >
    </span>
    <span v-else>BAD BOOLEAN CAST</span>
  </span>
  <span v-else> TIPO MAL DEFINIDO </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      if (this.header.type) return this.header.type.split(".")[0];
      return "basic";
    },
    cast() {
      if (this.header.type) return this.header.type.split(".")[1];
      return null;
    },
  },

  methods: {
    ...mapGetters("app", ["getInner"]),
  },
  mounted() {
    console.log(
      this.header.value,
      this.getInner()(this.item, this.header.value)
    );
  },
};
</script>