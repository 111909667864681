<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 367 378.08"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M367,175v29a20.44,20.44,0,0,0-.83,3.3,189.54,189.54,0,0,1-22.59,74.47c-40,73.58-121.87,110.46-202.67,91.3C71.25,356.55,14.13,294.62,3.05,223.58Q1.52,213.8,0,204V176a41.28,41.28,0,0,0,.85-4.38c2.39-26.17,9.47-51,22.32-73.93C52.5,45.4,96.33,13,155.81,2.81,164.77,1.27,173.93.91,183,0h2c6.6.62,13.21,1.12,19.79,1.87C281.25,10.65,344.5,68.94,362,145.55,364.18,155.25,365.35,165.18,367,175Zm-16.72,4.51c-.69-40.33-18.62-83.35-55.51-118C239.62,9.59,152.92,3.12,91.5,46.13A174.7,174.7,0,0,0,56.13,300.18a163.72,163.72,0,0,0,188.22,49C308,323.84,350.21,260.22,350.28,179.51Z"
        />
        <path
          d="M282.16,155.86q-14.32,11.5-28.66,23c-9.36,7.5-18.63,15.12-28.16,22.39-2.77,2.11-3.11,3.89-2.08,7,8.72,26.52,17.28,53.1,25.88,79.66.25.78.49,1.55,1.18,3.75l-71-53.48-70.93,53.47c1.35-4.32,2.26-7.29,3.22-10.24,7.88-24.38,15.71-48.78,23.73-73.12,1.05-3.17.67-4.94-2.06-7.09-17.81-14-35.44-28.23-53.12-42.39-1.23-1-2.39-2.08-4.4-3.84h75c9.46-25.73,18.81-51.15,28.55-77.66,5,13.54,9.6,26,14.2,38.55,4.13,11.23,8.42,22.4,12.27,33.72,1.32,3.89,3,5.4,7.33,5.35,22.82-.25,45.64-.12,68.46-.12Zm-156.5,16.07-.51,1c8.78,7.05,17.41,14.31,26.42,21.05,3.81,2.85,4.49,5.33,2.9,9.84-4.74,13.43-8.88,27.07-13.73,42.09l38.62-29,38,28.57c-.47-2.16-.62-3.28-1-4.34-3.41-10.6-6.87-21.19-10.3-31.78-3.69-11.42-3.68-11.41,5.61-18.85,7.33-5.87,14.64-11.77,23.15-18.61-12,0-22.4-.24-32.81.11-4.34.15-6.38-1.07-7.82-5.33-4.52-13.32-9.57-26.46-14.69-40.41-.79,1.81-1.3,2.82-1.69,3.88-4.42,12-9,24-13.16,36.11-1.44,4.14-3.24,6-8.07,5.79C146.31,171.59,136,171.93,125.66,171.93Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "TikTok",
  props: ["role"],
};
</script>
