<template>
  <svg
    class="svg-icon"
    :role="role"
    id="Layer_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m256 0c-103.388 0-187.5 84.112-187.5 187.5 0 38.607 11.653 75.694 33.673 107.215l147.654 214.044c1.4 2.029 3.708 3.241 6.173 3.241s4.773-1.212 6.173-3.241l147.628-214.007c22.046-31.558 33.699-68.645 33.699-107.252 0-103.388-84.112-187.5-187.5-187.5zm141.48 286.197-141.48 205.095-141.505-205.131c-20.277-29.026-30.995-63.142-30.995-98.661 0-95.117 77.383-172.5 172.5-172.5s172.5 77.383 172.5 172.5c0 35.519-10.718 69.635-31.02 98.697z"
    />
    <path
      d="m353.5 187.5c0-53.762-43.738-97.5-97.5-97.5s-97.5 43.738-97.5 97.5 43.738 97.5 97.5 97.5 97.5-43.738 97.5-97.5zm-180 0c0-45.491 37.009-82.5 82.5-82.5s82.5 37.009 82.5 82.5-37.009 82.5-82.5 82.5-82.5-37.009-82.5-82.5z"
    />
    <path
      d="m256 30c-36.257 0-71.657 12.627-99.676 35.556-3.206 2.623-3.678 7.348-1.055 10.554s7.348 3.679 10.554 1.055c25.347-20.742 57.373-32.165 90.177-32.165 78.575 0 142.5 63.925 142.5 142.5s-63.925 142.5-142.5 142.5-142.5-63.925-142.5-142.5c0-32.805 11.423-64.831 32.165-90.177 2.623-3.206 2.151-7.931-1.054-10.554s-7.931-2.151-10.554 1.054c-22.93 28.02-35.557 63.419-35.557 99.677 0 86.846 70.654 157.5 157.5 157.5s157.5-70.654 157.5-157.5-70.654-157.5-157.5-157.5z"
    />
  </svg>
</template>
<script>
export default {
  name: "Location",
  props: ["role"],
};
</script>
