<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon-stroke "
    width="50"
    height="50"
    viewBox="0 0 22 22"
    stroke-width="1.5"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M14 3v4a1 1 0 0 0 1 1h4" fill="none" />
    <path
      d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
      fill="none"
    />
    <line x1="9" y1="7" x2="10" y2="7" />
    <line x1="9" y1="13" x2="15" y2="13" />
    <line x1="13" y1="17" x2="15" y2="17" />
  </svg>
</template>
<script>
export default {
  name: "Invoice",
  props: ["role"],
};
</script>
