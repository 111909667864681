<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 137 137.38"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,77V61a13.45,13.45,0,0,0,.84-2.29c4.47-25.5,18.65-43.5,42.51-53.18C51.05,2.41,59.76,1.77,68,0h2a34,34,0,0,0,3.84.8C97.48,3,115.75,14.16,127.51,34.66c4.43,7.72,6.4,16.85,9.49,25.34V78a16.79,16.79,0,0,0-.95,2.68c-5.39,29.78-29.67,52.93-59,56.24C46.44,140.37,18.18,124.43,6,96.37,3.28,90.22,2,83.47,0,77ZM110,23.24C91.68,3.9,57.13,1.4,33.71,17.32A62.48,62.48,0,0,0,12.39,96c12.47,26.63,43.66,40.82,72.47,33,26-7.09,47.55-34.06,45.09-56.95H124.6C107,72,89.3,72.09,71.66,72c-1.94,0-3.86-1.12-5.79-1.73.94-1.94,1.46-4.28,2.89-5.76,7.38-7.68,15-15.15,22.54-22.67Q100.65,32.47,110,23.24Zm20.05,42.4c.88-12.14-7.41-31.74-14.71-36.91l-37,36.91Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Portion",
  props: ["role"],
};
</script>
