import Vue from 'vue'

export default {
  getInventorymovements: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...filters,
        ...pagination,

      }

      return await Vue.http.get(`inventorymovements`, {
        params
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  delInventorymovements: async function ({
    commit,
    state
  }, id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.delete(`inventorymovements/delete/${id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addInventorymovements: async function ({
      commit,
      state
    },
    formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`inventorymovements`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addMovementExcel: async function ({
    commit,
    state
  }, formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`inventorymovements/excel`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  confirmInventorymovements: async function ({
    commit,
    state
  }, id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`inventorymovements/${id}/confirm`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  editInventorymovements: async function ({
    commit,
    state
  }, inventorymovementsData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`inventorymovements/${inventorymovementsData.id}`, inventorymovementsData).then(response => {
        return response.json();
      })

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAll: async function ({
    commit,
    state
  }, {
    id
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`inventorymovements/all/${id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAllTotal: async function ({
    commit,
    state
  }, ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`inventorymovements/all`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAllIssues: async function ({
    commit,
    state
  }, ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`inventorymovements/all/entrada`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAllReceipts: async function ({
    commit,
    state
  }, ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`inventorymovements/all/receipts`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  inventorymovementsAddInvoce: async function ({
      commit,
      state
    },
    formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`inventorymovements/invoice`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
}