<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 148 182.4"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,177V31c2.58-4.52,6.48-6.19,11.63-5.83,4.13.29,8.31.06,12.46.06h17c0-2.43,0-4.07,0-5.72.07-7.78,2.81-10.59,10.4-10.62,4.64,0,9.27,0,14.1,0C66.12,3.52,69.73,1.43,74,0h1l10,8.89c2.8,0,7.61-.05,12.42,0,6.42.09,9.32,3,9.49,9.52.05,2.11,0,4.22,0,6.81,10.32,0,20.1.18,29.87-.07,5-.13,8.72,1.45,11.16,5.84V177c-2.64,4.26-6.53,5.38-11.4,5.37q-62.61-.18-125.23,0C6.46,182.41,2.72,181.07,0,177Zm139.51-3.13V58.71H8.53V173.88ZM8.44,49.78h131V33.63c-10.24,0-20.05-.1-29.86.12-1.13,0-2.66,1.58-3.25,2.78-1.74,3.54-4.36,5.35-8.26,5.35H50.6c-4.23,0-7.32-1.64-9-5.73-.42-1-1.66-2.39-2.54-2.41-10.1-.17-20.22-.11-30.59-.11ZM49.58,33.27H98.39v-16H49.58Z"
        />
        <path
          d="M74.56,108q21.21,0,42.41,0c3,0,6,.1,6.11,4s-3,4.12-6,4.12H30.77c-3,0-6-.3-5.86-4.28.12-3.62,2.91-3.9,5.75-3.9Q52.62,108,74.56,108Z"
        />
        <path
          d="M66,132.84c11.49,0,23,0,34.46,0,3,0,6.21.17,6.31,3.92.1,4.06-3.13,4.31-6.4,4.3-23.13-.06-46.27,0-69.41-.15-2,0-4.68-1.16-5.69-2.68-1.75-2.62.7-5.3,4.78-5.33C42,132.79,54,132.85,66,132.84Z"
        />
        <path
          d="M53.67,91.39c-7.66,0-15.32,0-23,0-2.79,0-5.72-.22-5.77-3.9-.05-3.84,2.8-4.38,5.91-4.37,15.15,0,30.31,0,45.46,0,2.83,0,5.64.36,5.74,4,.12,4-2.86,4.28-5.88,4.28Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Form",
  props: ["role"],
};
</script>
