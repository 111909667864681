import Vue from 'vue'

export default {
  getStudioTattooersReport: async function ({
    commit,
    state
  }, {
    studioId,
    options,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...options,
        ...filters
      }

      return await Vue.http.get(`reports/studios/${studioId}/tattooers`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getMonthlyStudioTattooersReport: async function ({
    commit,
    state
  }, {
    studioId,
    options,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...options,
        ...filters
      }

      return await Vue.http.get(`reports/studios/${studioId}/tattooers/monthly`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getTattooerStudiosReport: async function ({
    commit,
    state
  }, {
    tattooerId,
    options,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...options,
        ...filters
      }

      return await Vue.http.get(`reports/tattooers/${tattooerId}/studios`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getStudiosReport: async function ({
    commit,
    state
  }, {
    options
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...options
      }

      return await Vue.http.get(`reports/admin/studios`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getTattooersReport: async function ({
    commit,
    state
  }, {
    options
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...options
      }

      return await Vue.http.get(`reports/admin/tattooers`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getAdminMonthlyGraph: async function ({
    commit,
    state
  }, {
    options
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...options
      }

      return await Vue.http.get(`reports/admin/monthly`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },



  getMonthlyTattooerStudiosReport: async function ({
    commit,
    state
  }, {
    tattooerId,
    options,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...options,
        ...filters
      }

      return await Vue.http.get(`reports/tattooers/${tattooerId}/studios/monthly`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getTattooerSummary: async function ({
    commit,
    state
  }, {
    tattooerId,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...filters
      }

      return await Vue.http.get(`reports/tattooers/${tattooerId}/summary`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getStudioSummary: async function ({
    commit,
    state
  }, {
    studioId,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...filters
      }

      return await Vue.http.get(`reports/studios/${studioId}/summary`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getStudioTattooersReport: async function ({
    commit,
    state
  }, {
    studioId,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...filters
      }

      return await Vue.http.get(`reports/studios/${studioId}/tattooers-dates`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAppointmentsReport: async function ({
    commit,
    state
  }, {
    studioId,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...filters
      }

      return await Vue.http.get(`reports/studios/${studioId}/tattooers-dates`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getPendingAppointmentsReport: async function ({
    commit,
    state
  }, {
    studioId,
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      const params = {
        ...filters
      }

      return await Vue.http.get(`reports/studios/${studioId}/appointments`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  }
}