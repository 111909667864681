<template>
  <v-card>
    <v-card-title
      class="text-uppercase text-decoration-underline"
      v-if="card_title && $vuetify.breakpoint.smAndDown"
      >{{ card_title }}</v-card-title
    >
    <v-data-table
      :headers="headers"
      :items="data || []"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="no_info"
      v-if="$vuetify.breakpoint.mdAndUp"
      :loading="!data"
    >
      <template v-slot:body.prepend="{ isMobile }">
        <slot name="body_prepend"></slot>
      </template>
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <slot :name="`item_${header.value}`" v-bind="item">
          <base-inner-table :item="item" :header="header" />
        </slot>
      </template>
    </v-data-table>

    <v-row v-else class="px-4">
      <v-col cols="12" v-if="$slots.body_prepend">
        <slot name="body_prepend"></slot>
      </v-col>
      <v-col cols="12" v-for="(item, i) in data" :key="i">
        <base-list-card
          :headers_small="headers_small"
          :headers="headers"
          :item="item"
          :title="title"
          :no_dialog="no_dialog"
        >
          <template
            v-for="header in all_headers"
            v-slot:[`item_${header.value}`]
          >
            <slot :name="`item_${header.value}`" v-bind="item">
              <base-inner-table :item="item" :header="header" />
            </slot>
          </template>
        </base-list-card>
      </v-col>
      <v-col cols="12" v-if="data && data.length == 0">
        <slot name="no_info">
          <div align="center">
            {{ no_info }}
          </div>
        </slot>
      </v-col>
      <v-col cols="12" class="pagination" v-if="data && data.length > 0">
        <!--PAGINATION-->
        <template>
          <div class="text-center">
            <v-pagination v-model="options.page" :length="pages"></v-pagination>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    console.log("table", this.$slots);
    if (this.$vuetify.breakpoint.smAndDown) {
      this.options.page = 1;
    }
    this.options.itemsPerPage = this.itemsPerPage;
  },
  watch: {
    options: {
      handler() {
        this.$emit("update", this.options);
      },
      deep: true,
    },
  },
  props: {
    title: { type: String, required: true },
    headers: { type: Array, required: true },
    headers_small: { type: Array, required: false, default: () => [] },
    data: { required: true },
    itemsPerPage: { type: Number, default: 10 },
    totalItems: { type: [Number], required: false },
    no_dialog: { type: Boolean, default: false },
    no_info: {
      type: String,
      default: "No se ha encontrado información",
    },
    no_info_img: { type: String },
    card_title: { type: String },
  },
  computed: {
    all_headers() {
      return this.headers.concat(this.headers_small);
    },
    pages() {
      //console.log(totalItems, itemsPerPage);
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: null,
      },
    };
  },
};
</script>

<style lang="sass"></style>
