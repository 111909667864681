import Vue from "vue";

export default {
  getTimetables: async function ({
    commit,
    state
  }, {
    pagination,
    filters,
    save_state
  } = {
    pagination: {},
    filters: {},
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...pagination,
        ...filters,
        itemsPerPage: -1,
      };
      const {
        body
      } = await Vue.http.get(`timetables`, {
        params,
      });
      if (save_state !== false)
        commit("SET_TIMETABLES", body.data);
      return body.data
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getTimetable: async function ({
    commit,
    state
  }, timetable_id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .get(`timetables/${timetable_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getTimetableResume: async function ({
    commit,
    state
  }, filters) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
      };
      const {
        body
      } = await Vue.http.get(`timetables/resume`, {
        params,
      });
      if (filters.save_state !== false) {

        commit("SET_ACTIVE_TIMETABLE", body.active);
        commit("SET_DEFAULT_TIMETABLE", body.default);
      }
      return body;
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addTimetable: async function ({
    commit,
    state
  }, timetable) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.post(`timetables`, timetable).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  updateTimetable: async function ({
    commit,
    state
  }, {
    timetable,
    id_timetable
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`timetables/${id_timetable}`, timetable)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  deleteTimetable: async function ({
    commit,
    state
  }, {
    id_timetable
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .delete(`timetables/${id_timetable}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
};