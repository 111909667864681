<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 369 450.55"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M369,75c-3.27,5.35-8,7.41-14.23,6.93-5.29-.41-10.62-.08-16.44-.08-.75,11.07-1.52,21.81-2.19,32.56q-5.47,87.06-10.9,174.11-3.67,59.1-7.27,118.22c-1.2,20-14.9,38.5-32.2,42.6a55.13,55.13,0,0,1-12.39,1.12q-88.49.11-177,0c-23.79,0-40.67-13.77-44.25-37.43-2-13-2.25-26.2-3.08-39.32q-3.91-61.1-7.67-122.22Q37.68,191.71,34,131.84C33,115.41,31.88,99,30.78,81.89c-5.51,0-10.81-.34-16,.08C8.32,82.49,3.45,80.46,0,75V69c2.69-5.9,7.37-7.76,13.68-7.72,31,.17,62,.07,92.94.07h6.26V55.26c0-11.49-.11-23,0-34.48C113.07,8.59,121.6.09,133.82.06q50.72-.12,101.43,0c12.43,0,20.8,8.66,20.86,21.23.06,11.5,0,23,0,34.49v5.61h6.77c30.82,0,61.63.11,92.44-.08,6.34,0,11,1.74,13.67,7.73ZM51.29,82.14c.59,9.59,1.13,18.57,1.68,27.54Q56.18,161.33,59.39,213,63.16,273.08,67,333.19C68.55,358.46,70,383.74,71.81,409c.87,12.39,9.63,21.1,21.09,21.11q91.75.12,183.5.06c6.89,0,12.05-3.08,16.17-8.43,4.69-6.09,4.9-13.28,5.34-20.43q4.21-68.1,8.41-136.19Q311.56,181.57,316.88,98c.33-5.23.51-10.48.77-15.86Zm184.3-61.43H133.4V61.07H235.59Z"
        />
        <path
          d="M194.79,256q0,59.72,0,119.42a23.59,23.59,0,0,1-.62,6.41c-1.35,4.71-4.77,7.19-9.6,7.19s-8.25-2.44-9.62-7.16a21.63,21.63,0,0,1-.68-5.91q0-119.93,0-239.84a20.1,20.1,0,0,1,1-6.84c1.53-4.14,4.83-6.25,9.26-6.26a9.31,9.31,0,0,1,9.33,6.23,19.33,19.33,0,0,1,.93,6.84Q194.83,196.06,194.79,256Z"
        />
        <path
          d="M269.31,163.21q-3.72,62.13-7.46,124.25-2.76,45.41-5.66,90.8c-.47,7.19-4.82,11.26-11,10.8-5.91-.45-9.57-4.93-9.17-12q2.58-45.42,5.38-90.82Q244.16,240.6,247,195c1.23-20.12,2.41-40.25,3.72-60.37.48-7.38,4.61-11.67,10.55-11.39,6.3.29,10,5,9.7,12.58-.42,9.15-1.07,18.3-1.62,27.44Z"
        />
        <path
          d="M97.86,138.72c0-1.67,0-3.33,0-5,.09-6,4.19-10.4,9.77-10.57s10,4,10.42,9.93c1,14.95,2,29.91,2.88,44.87q3.88,62.55,7.72,125.12c1.52,24.76,2.94,49.53,4.44,74.29.27,4.54-.89,8.85-5.3,10.28-3.09,1-7.43.95-10.17-.55-2.35-1.28-4.34-5.2-4.55-8.1-2.23-31.22-4.08-62.46-6-93.7q-3.31-53.6-6.6-107.19Q99.24,158.41,98,138.72Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "delete",
  props: ["role"],
};
</script>
