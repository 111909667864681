import Vue from "vue";
export default {
  getAlerts: async function({ commit, state }, { filters, pagination }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...pagination,
      };
      return Vue.http
        .get(`appointments/alerts/${id}`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },

  getAlertsById: async function({ commit, state }, id) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return Vue.http.get(`appointments/alerts/${id}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },

  addAlert: async function({ commit, state }, alert) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/alerts`, alert)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  deleteAlert: async function({ commit, state }, id) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http
        .delete(`appointments/alerts/${id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  editAlert: async function({ commit, state }, { id, alert }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/alerts/${id}`, alert)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
};
