export default {
  totalEmails: (state) => {
    let count = 0;
    //console.log(state.unreadEmails)

    Object.keys(state.unreadEmails).forEach(element => {
      count += state.unreadEmails[element];
    });

    return count;
  }
}