// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

// Store functionality
import actions from './actions'
import getters from './getters'
import modules from './modules'
import state from './state'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: 'base',
  asyncStorage: true,
  storage: localforage,
  modules: ['auth'],
});

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  modules,
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION
  },
  state,
  plugins: [
    vuexLocal.plugin,
  ],
});
export default store