<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 192.8 192.58"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M92,87.38c0-6.84,0-13,0-19.12,0-3.26-.15-7,4.43-6.79,4.35.16,4.36,3.81,4.37,7.07,0,6.12,0,12.23,0,18.84h6.42q28.24,0,56.49,0c5.77,0,7.18,1.36,7.22,7,.05,10,0,20,0,30,0,3.29-.59,6.46-4.44,6.56s-4.49-3-4.45-6.37c.09-9.15,0-18.3,0-27.89h-61.3c0,8.42,0,16.71,0,25,0,1.66.49,3.64-.23,4.91-1,1.76-2.94,3-4.48,4.44-1.36-1.59-3.8-3.12-3.89-4.77-.41-8-.19-16-.2-24V96.55H30.65v23.88c0,1.84.52,3.92-.18,5.44-.89,1.95-2.78,3.45-4.24,5.15-1.42-1.69-3.92-3.29-4.08-5.1-.53-6-.21-12-.22-18,0-4.5,0-9,0-13.5.06-6,1-7,7-7q28.5,0,57,0Z"
        />
        <path
          d="M140.21,166.35c-1.58-21.64,7.79-27.82,26.4-26.49,3.65.26,7.35,0,11,.41,7.7.86,13.95,6.36,14.66,14.07a126.8,126.8,0,0,1,0,23.38c-.76,8.26-7.56,14.1-15.93,14.54-6.47.34-13,.33-19.47,0-9.4-.45-16.32-7.79-16.65-17.27C140.12,172,140.21,169,140.21,166.35Zm26.26,17.16v.05c2.49,0,5,.05,7.48,0,6.84-.16,10-3.3,10.12-10.18.09-5.15.16-10.31,0-15.46-.22-5.61-3.56-9.07-9.05-9.28q-8.47-.33-17,0c-5.62.22-8.83,3.65-8.94,9.25s-.13,11.31,0,17c.14,5.19,3.19,8.29,8.4,8.63C160.48,183.66,163.48,183.51,166.47,183.51Z"
        />
        <path
          d="M95.79,192.39C77,193.9,68.61,186.3,70.06,166.57c.25-3.32-.07-6.67.13-10,.55-9,6.78-15.73,15.79-16.35a150.72,150.72,0,0,1,20.93,0c8.66.61,14.76,6.74,15.43,15.51a134,134,0,0,1,0,20.43c-.73,9.72-7.76,15.87-17.61,16.17C101.78,192.47,98.78,192.39,95.79,192.39Zm.49-8.83c2.49,0,5,.05,7.48,0,6.84-.15,10-3.26,10.2-10.12.13-5.15.19-10.31,0-15.45-.26-5.88-3.67-9.2-9.5-9.37-5.48-.17-11-.2-16.46,0s-8.82,3.64-9,9.2c-.09,2.82,0,5.65,0,8.48C78.94,183.14,79.36,183.56,96.28,183.56Z"
        />
        <path
          d="M96.18,52.41c-3,0-6,.1-9,0-9.54-.4-16.61-7.28-17-16.84-.26-6.31-.28-12.65,0-19A16.82,16.82,0,0,1,86.57.24c6.47-.32,13-.32,19.46,0,9.09.43,15.63,6.59,16.32,15.74a135.27,135.27,0,0,1,0,20.43c-.73,9.52-7.67,15.67-17.2,16C102.17,52.5,99.18,52.42,96.18,52.41ZM78.94,26c0,2.66,0,5.32,0,8,.13,6.37,3.07,9.49,9.41,9.69,5.31.16,10.64.16,16,0,6.22-.18,9.43-3.37,9.64-9.65.17-5.14.17-10.3,0-15.44-.2-6.22-3.39-9.5-9.6-9.8-4.81-.23-9.63-.12-14.45-.05C81.82,8.8,79,11.77,78.94,20,78.93,22,78.94,24,78.94,26Z"
        />
        <path
          d="M.09,166.16c0-3.32-.18-6.66,0-10,.56-8.46,6.71-15.25,15.13-15.86a151.3,151.3,0,0,1,21.92,0,16.05,16.05,0,0,1,15,15.36,172.09,172.09,0,0,1,.06,20.44c-.51,9.13-7.37,15.76-16.54,16.18-6.3.29-12.65.3-19,0-9.51-.44-16.3-7.56-16.7-17.14-.12-3,0-6,0-9Zm8.72-.1h0c0,2.66-.06,5.32,0,8,.19,6.3,3.22,9.35,9.63,9.5,5.32.12,10.64.13,16,0,5.79-.15,9-3.08,9.36-8.86a162.24,162.24,0,0,0,0-16.94c-.26-5.43-3.57-8.84-8.74-9.07-5.8-.26-11.63-.25-17.44,0-5.34.22-8.51,3.64-8.74,8.94C8.7,160.41,8.81,163.23,8.81,166.06Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "request",
  props: ["role"],
};
</script>
