<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 169 169.37"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,131V0H169V165c-2.81,2.65-5.76,4.34-10,4.33-38.92-.16-77.84-.05-116.75-.21a10.23,10.23,0,0,1-6.4-2.72C23.78,154.76,11.93,142.86,0,131Zm161,30.44V8.41H8.18V123.2c7.56,0,14.71,0,21.86,0,18.81.14,15.79-2.14,16,15.74.09,7.43,0,14.86,0,22.53ZM38,156.61V131.39H12.82C21.19,139.77,29.38,148,38,156.61Z"
        />
        <path
          d="M84.51,69.52q-23.2,0-46.42,0c-1.49,0-3.23.27-4.4-.39s-2.62-2.36-2.57-3.56,1.56-2.69,2.81-3.4c1-.58,2.6-.21,3.93-.21q46.68,0,93.35,0c1.33,0,2.9-.36,3.92.22,1.24.7,2.75,2.21,2.78,3.4s-1.36,2.9-2.57,3.59-2.91.35-4.41.35Q107.73,69.54,84.51,69.52Z"
        />
        <path
          d="M73.25,85h35.41c2.82,0,6.24-.46,6.35,3.64s-3.17,4-6.09,4q-35.91,0-71.82,0c-2.82,0-6.15.17-6.1-4,0-3.39,2.64-3.68,5.34-3.67C48.64,85,61,85,73.25,85Z"
        />
        <path
          d="M57.52,46.52c-6.81,0-13.62,0-20.43,0-2.85,0-6.17.17-6.11-4,.06-3.77,3.1-3.66,5.84-3.66H78.69c2.81,0,5.7.11,5.61,3.88s-3.12,3.73-5.84,3.74C71.48,46.53,64.5,46.52,57.52,46.52Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Report",
  props: ["role"],
};
</script>
