import Vue from "vue";

export default {
  getSales: async function ({ commit, state }, { paginate, filters }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...paginate,
        ...filters,
        ...state.filters,
      };
      return await Vue.http
        .get(`reports/sales`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  getClients: async function ({ commit, state }, { paginate, filters }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...paginate,
        ...filters,
        ...state.filters,
      };
      return await Vue.http
        .get(`reports/clients`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  getAppointments: async function ({ commit, state }, { paginate, filters }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...paginate,
        ...filters,
        ...state.filters,
      };
      return await Vue.http
        .get(`reports/appointments`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },

  getExpenses: async function ({ commit, state }, { paginate, filters }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...paginate,
        ...filters,
        ...state.filters,
      };
      return await Vue.http
        .get(`reports/expenses`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },

  getProducts: async function ({ commit, state }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...paginate,
        ...filters,
        ...state.filters,
      };
      return await Vue.http
        .get(`inventorys/all`, { params })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
};
