<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-card
        elevation="4"
        class="my-0 pa-2 primary-border"
        v-on="no_dialog ? null : on"
      >
        <v-row align="center">
          <template v-for="(header, i) in headers_small || headers">
            <span
              v-if="header.no_col"
              :key="'no_cols' - i"
              :class="header.class"
            >
              <slot :name="`item_${header.value}`">
                <base-inner-table :item="item" :header="header" />
              </slot>
            </span>
            <v-col
              :key="'cols-' + i"
              v-else
              :cols="header.cols"
              :class="header.class"
              class="no-wrap"
            >
              <slot :name="`item_${header.value}`">
                <base-inner-table :item="item" :header="header" />
              </slot>
            </v-col>
          </template>
        </v-row>
      </v-card>
    </template>
    <v-card class="primary-border">
      <v-card-title v-html="title"> </v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row justify="center">
          <v-col
            align="center"
            :cols="header.value == 'actions' ? 12 : 6"
            v-for="(header, i) in headers"
            :key="i"
          >
            <div v-if="header.value != 'actions'" class="bigger_title">
              {{ header.text }}
            </div>
            <span class="white--text">
              <slot :name="`item_${header.value}`">
                <base-inner-table :item="item" :header="header" />
              </slot>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    console.log("CARD", this.$slots);
  },
  props: {
    url: { type: String, required: false },
    title: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: null },
    headers: { type: Array, required: true },
    headers_small: { type: Array, required: false },
    item: { type: Object, required: true },
    no_dialog: { type: Boolean, default: false },
  },
  methods: {
    ...mapGetters("app", ["getInner"]),
  },

  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style lang="sass" scoped>
.bigger_title
  font-size: 18px
</style>
