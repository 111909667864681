import Vue from "vue";
import App from "./App.vue";
import "./filters";
import router from "./router";
import store from "./store/index";
import "./plugins/base";
import "./plugins/global";
//import "./plugins/vue-mobile-detection";
import "./plugins/vee-validate";
import "./plugins/browser-detect";
import "./plugins/vue-oauth2-google";
import "./plugins/vue-resource";
import vuetify from "./plugins/vuetify";
import "./plugins/vue-simple-alert";
import i18n from "./i18n";
import { checkJWTExpired } from "./utils/jwtHelper";
import "./registerServiceWorker";

Vue.config.productionTip = false;

// CHECK EVERY TWO MINUTES IF JWT EXPIRED
setInterval(() => {
  if (store.getters["auth/isLoggedIn"]) {
    store.dispatch("email/getUnreadEmails");
    store.dispatch("appointments/getPendingAppointments");
    store.dispatch("auth/getNotifications");
    //store.dispatch('auth/getSettings');
  }
}, 60000);
setInterval(() => {
  if (store.getters["auth/isLoggedIn"]) {
    store.dispatch("auth/checkActive");
    store.dispatch("auth/checkBoxActive");
    //store.dispatch('auth/getSettings');
  }
}, 1800000);
/*visualViewport.addEventListener('resize', () => {
  if (visualViewport.scale > 0) {
    window.body.style = "zoom: 80%"
    // Post data to analytics service
  }
});*/
console.log(window.location.href);
console.log(!window.location.href.startsWith(window.location.origin + "/?#/"));
if (!window.location.href.startsWith(window.location.origin + "/?#/"))
  window.location.href = window.location.origin + "/?#/";

Vue.http.interceptors.push((request, next) => {
  //console.log(request);
  if (store.state.auth.jwt && request.url !== "authenticate") {
    request.headers.set("Authorization", "Bearer " + store.state.auth.jwt);
  }
  next((response) => {
    if (
      response.status === 401 &&
      router.history.current.name !== "Login" &&
      router.history.current.name !== "appointmentViewOutsite" &&
      router.history.current.name !== "ConsentFormViewIncription" &&
      router.history.current.name !== "ConsentFormPiercer"
    ) {
      router.replace({
        name: "Login",
        query: {
          redirect: router.history.current.path,
        },
      });
    }
  });
});
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
