<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 301 551.48"
    class="svg-icon"
    :role="role"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,509.14v-466a29.16,29.16,0,0,0,1-3.25C5.56,17.65,25.58.25,48.11.17Q150.53-.19,253,.21c15.75.06,28.93,7.07,37.79,20C295.39,27,297.66,35.44,301,43.12v466c-.28.39-.72.74-.82,1.17-6.24,26.42-24.79,41.12-52,41.13h-126c-23.82,0-47.65.1-71.47,0-21.73-.11-39.08-11.79-47.17-31.56C2.2,516.37,1.2,512.72,0,509.14ZM275.84,100.61H25.38V450.79H275.84ZM25.07,476.52c0,7.38.35,14.51-.07,21.6-.92,15.7,10.27,28.58,28.51,28.44,64.44-.49,128.89-.19,193.34-.19,1.83,0,3.66,0,5.49-.11,10.5-.53,20.44-7.35,22.21-17.63,1.8-10.47,1.34-21.33,1.88-32.11Zm.05-401.75H276c0-8.37.09-16.36,0-24.34-.19-14.48-10.85-25.28-25.39-25.3Q150.33,25,50.1,25.13A24.67,24.67,0,0,0,25.2,48.85C24.79,57.32,25.12,65.83,25.12,74.77Z"
        />
        <path
          d="M150.43,488.94A12.47,12.47,0,0,1,163,501.54a12.51,12.51,0,0,1-25,.2A12.42,12.42,0,0,1,150.43,488.94Z"
        />
        <path
          d="M150.57,62.68c-4.16,0-8.33.17-12.48,0-7.35-.38-12.55-5.53-12.64-12.22-.09-7,5.09-12.47,12.58-12.69q12.47-.37,24.95,0c7.31.19,12.42,5.35,12.6,12.16S170.8,62,163.53,62.59c-4.3.33-8.64.06-13,.06Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Iphone",
  props: ["role"],
};
</script>
