import Vue from "vue";

export default {
  getExpenses: async function ({
    commit,
    state
  }, {
    paginate,
    filters
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...paginate,
        ...filters,
      };
      return await Vue.http
        .get(`expenses`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpense: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`expenses/${id}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpenseToPay: async function ({
    commit,
    state
  }, {
    id,
    form
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/${id}/pay`, form)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpenseInvoice: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`expenses/${id}/invoice`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpenseToPayWithCustomer: async function ({
    commit,
    state
  }, {
    id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/${id}/pay`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpenseInforme: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`expenses/${id}/informe`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  delExpense: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.delete(`expenses/${id}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpensesSumByDay: async function ({
    commit,
    state
  }, {
    filters
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
      };
      return await Vue.http
        .get(`expenses/sum-by-day`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addExpenses: async function ({
    commit,
    state
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.post(`expenses`, formData).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getPartialExpenses: async function ({
    commit,
    state
  }, {
    appointment_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .get(`expenses/partials/${appointment_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {} finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addPartialExpenses: async function ({
    commit,
    state
  }, {
    appointment_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/new-partial/${appointment_id}`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  cashOutput: async function ({
    commit,
    state
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/cash_output`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addExpenseExcel: async function ({
    commit,
    state
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/excel`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  confirmExpense: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.post(`expenses/${id}/confirm`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  confirmExpenseCommision: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/${id}/confirm-commission`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  confirmExpenseCommisionTattooer: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/${id}/confirm-commission-tattooer`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  confirmExpenseCommisionAppointment: async function ({
    commit,
    state
  }, {
    id,
    method,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(
          `expenses/${id}/confirm-commission-appointment/${method}`,
          formData
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  editExpense: async function ({
    commit,
    state
  }, expenseData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/${expenseData.id}`, expenseData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getTotalAmount: async function ({
    commit,
    state
  }, {
    filters
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      console.log("FILTERS", filters);
      const params = {
        ...filters,
      };
      return await Vue.http
        .get(`expenses/totalamount`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getAll: async function ({
    commit,
    state
  }, {
    filters
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
      };
      return await Vue.http
        .get(`expenses/all`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpensesPayed: async function ({
    commit,
    state
  }, {
    filters,
    pagination
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...pagination,
      };
      return await Vue.http
        .get(`expenses/pending`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpensesCustomerPayed: async function ({
    commit,
    state
  }, {
    filters,
    pagination
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...pagination,
      };
      return await Vue.http
        .get(`expenses/pending/customer`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpensesStudioPayed: async function ({
    commit,
    state
  }, {
    filters,
    pagination
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...pagination,
      };
      return await Vue.http
        .get(`expenses/pending/studio`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getExpensesDayByDay: async function ({
    commit,
    state
  }, {
    paginate,
    filters
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...paginate,
        ...filters,
      };
      return await Vue.http
        .get(`expenses/day-by-day`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getExpensesCashClose: async function ({
    commit,
    state
  }, filters) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
      };
      return await Vue.http
        .get(`expenses/cash-close`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  transferBetweenCashRegister: async function ({
    commit,
    state,
  }, change) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`expenses/change_register_box`,
          change,
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
};