<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="sass">
*
  font-family: "Roboto Mono"

@font-face
  font-family: "Roboto Mono"
  src: url("~@/assets/fonts/RobotoMono-VariableFont_wght.ttf")
  font-weight: normal
  font-style: normal

.v-list-item .v-list-item__title.headline ~ .v-list-item__subtitle
  font-size: 1.5em
.v-application
  background-color: var(--v-gris4-base) !important
</style>
