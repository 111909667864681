import Vue from 'vue'

export default {
  getAccounts: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`users/me/accounts`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  setAccount: async function ({
    dispatch,
    commit,
    state
  }, accountInfo) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const {
        body
      } = await Vue.http.post(`users/me/accounts`, accountInfo);

      //console.log(body)
      commit('auth/SET_USER', body, {
        root: true
      });
      dispatch("auth/getSettings", {}, {
        root: true
      });
      return body;
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  configurateCalendars: async function ({
    dispatch,
    commit,
    state
  }, configuration) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const {
        body
      } = await Vue.http.post(`users/me/calendars/conf`, configuration);

      //console.log(body)
      commit('auth/SET_USER', body, {
        root: true
      });
      dispatch("auth/getSettings", {}, {
        root: true
      });
      return body;
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

}