<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122 167.1"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,80.06c3.39-8.78,9.89-12,19.14-11.93,24.64.28,49.29.09,73.93.09H100c-1-12-.4-23.88-3.27-34.86C92.05,15.54,73.76,5.45,54.85,8.1,37.88,10.48,24.27,25.39,23,42.92a10.29,10.29,0,0,1-1.4,5.1c-.6.82-3.45,1.25-3.86.73-1.2-1.52-2.41-3.64-2.34-5.47C16,24.89,28.57,8.51,46.31,2.47c29.4-10,60.14,11.66,60.51,42.71.09,7.48,0,15,0,22.78,7.6,1,12.6,5.11,15.17,12.1v76c-2.71,5.08-6.44,9-12,11H12a22.56,22.56,0,0,1-12-11Zm60.8,79.2c14.66,0,29.32,0,44,0,7,0,9.48-2.41,9.5-9.17q.07-32.51,0-65c0-6.56-2.58-9.11-9-9.12Q61,75.9,16.79,76c-6.4,0-9,2.68-9.06,9.2q-.11,32.25,0,64.49c0,7.18,2.48,9.58,9.61,9.6C31.82,159.29,46.31,159.26,60.8,159.26Z"
        />
        <path
          d="M45.88,114.93c0-7.6,3.72-12.82,9.71-15.19a15.21,15.21,0,0,1,16.85,4.1,15.44,15.44,0,0,1,2.25,16.79,35.3,35.3,0,0,0-2.52,6.86,11.38,11.38,0,0,1-11.56,9c-5.43-.17-9.58-3.94-10.77-9.36-.53-2.36-1.79-4.55-2.54-6.88C46.59,118.12,46.12,115.86,45.88,114.93ZM53,112.4c2,6,3.42,10.62,5.28,15,.35.84,2.88,1.61,3.79,1.17,1.23-.6,2.09-2.32,2.72-3.73,1-2.3,1.51-4.85,2.64-7.08,1.63-3.18,1.92-6.51-.82-8.63-2-1.59-5.57-2.83-7.83-2.16C56.33,107.74,54.57,110.85,53,112.4Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Unlock",
  props: ["role"],
};
</script>
