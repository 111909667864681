<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 244.41 244.37"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M198.58,217.46c-22.79,17.77-48.1,26.78-76.37,26.79s-53.59-9-76.43-26.84c-4.48,8.07-8.58,15.79-13.06,23.29A7.47,7.47,0,0,1,27.77,244a5.74,5.74,0,0,1-4.63-2.81,7.89,7.89,0,0,1,.54-5.94c4.33-8.26,9-16.34,13.65-24.72-3.56-4.11-7.28-8.11-10.67-12.37C-28.41,128.74,6,26.17,91.75,3.9c69.49-18,140.57,28.47,151.14,99.53,5.9,39.69-5.43,74.48-33.09,103.69-2.48,2.62-2.5,4.3-.77,7.17,4.12,6.84,7.8,14,11.82,20.85,2.06,3.54,1.74,6.61-1.82,8.5s-5.95,0-7.71-3.21C207.26,233,203.1,225.59,198.58,217.46ZM11.18,122A111.06,111.06,0,1,0,122.63,11.25,110.93,110.93,0,0,0,11.18,122Z"
        />
        <path
          d="M200,122.19c0,42.76-35.39,78.09-78,77.85S44.4,164.89,44.34,122.29,79.71,44.23,122.4,44.45,200,79.58,200,122.19Zm-11.18-.56c-.92-37.09-30.92-66.64-67-66-37.16.63-67.09,31-66.37,67.24C56.19,160,87,190,123,188.86,160.39,187.65,189.76,157.65,188.86,121.63Z"
        />
        <path
          d="M155.38,122.48c-.08,18.55-14.86,33-33.59,32.91a33.19,33.19,0,1,1,33.59-32.91Zm-55.26-.32a22.08,22.08,0,1,0,22.26-22A22,22,0,0,0,100.12,122.16Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "move",
  props: ["role"],
};
</script>
