import Vue from 'vue'

export default {
  getStudios: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {}
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const filterParams = Object.keys(filters).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filters[k])}`).join('&');
      const filterPagination = Object.keys(pagination).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(pagination[k])}`).join('&');

      return await Vue.http.get(`studios?${filterPagination}&${filterParams}`).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },


  getStudio: async function ({
    commit,
    state
  }, studio_id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`studios/${studio_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getStudioByToken: async function ({
    commit,
    state
  }, token) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`studios/token/${token}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  inviteStudio: async function ({
    commit,
    state
  }, {
    studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`studios/invite`, studio).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  editStudio: async function ({
    commit,
    state
  }, {
    studio_id,
    studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      })
      return await Vue.http.post(`studios/${studio_id}`, studio).then(response => {

        return response.json();

      }).catch(errorResponse => {
        const err = errorResponse.data.errors;
        Object.keys(err).forEach(key => {
          err[key].forEach(errorMsg => {
            commit(
              "app/SET_NOTIFICATION", {
                message: errorMsg,
                show: true,
                color: "error"
              }, {
                root: true
              }
            );
          })

        })
      })
    } catch (e) {
      //console.log(e)
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  addStudio: async function ({
    commit,
    state
  }, {
    studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`studios`, studio).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addStudioGuest: async function ({
    commit,
    state
  }, {
    studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`studios/guest`, studio).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  addStudioToTattooer: async function ({
    commit,
    state,
    dispatch
  }, {
    studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const {
        body
      } = await Vue.http.post(`studios/tattooer`, studio);
      commit("auth/SET_USER", body, {
        root: true,
      });
      await dispatch("auth/getSettings", {}, {
        root: true
      });

      return body;

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },


  getStudioTattooersByDate: async function ({
    commit,
    state
  }, {
    studio_id,
    date,
    start_time,
    end_time,
    appointment_id
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        date,
        start_time,
        end_time,
        appointment_id
      }
      return await Vue.http.get(`studios/${studio_id}/tattooers`, {
        params
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getStudioTimetable: async function ({
      commit,
      state
    },
    studio_id,
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`studios/${studio_id}/timetable`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getStudioSettingBox: async function ({
      commit,
      state
    },
    tattooer_id,
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`studios/setting/box/${tattooer_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  editStudioTimetable: async function ({
    commit,
    state
  }, {
    studio_id,
    formData
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`studios/${studio_id}/timetable`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getActiveTattooer: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`studios/tattooer/active`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAllTattooers: async function ({
    commit,
    state
  }, {
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`studios/tattooer/all`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  consentFormSettings: async function ({
      dispatch,
      commit,
      state
    },
    form
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      })
      const {
        body
      } = await Vue.http.post(`studios/consent`, form)
      commit('auth/SET_USER', body, {
        root: true
      });
      dispatch("auth/getSettings", {}, {
        root: true
      });
    } catch (e) {
      //console.log(e)
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  consentFormPiercerSettings: async function ({
      dispatch,
      commit,
      state
    },
    form
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      })
      const {
        body
      } = await Vue.http.post(`studios/consent/piercer`, form)
      commit('auth/SET_USER', body, {
        root: true
      });
      dispatch("auth/getSettings", {}, {
        root: true
      });
    } catch (e) {
      //console.log(e)
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  redirectionLinks: async function ({
    commit,
    state
  }, token) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`studios/redirection/${token}`).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getQR: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`studios/qr`).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  tranformStudio: async function ({
      commit,
      state,
      dispatch
    },
    studio
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const {
        body
      } = await Vue.http.post(`studios/transform`, studio);
      commit("auth/SET_USER", body, {
        root: true,
      });
      await dispatch("auth/getSettings", {}, {
        root: true
      });

      return body;

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  associateNewStudioToUser: async function ({
      commit,
      state,
      dispatch
    },
    studio
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const {
        body
      } = await Vue.http.post(`studios/add_related`, studio);
      commit("auth/SET_USER", body, {
        root: true,
      });
      await dispatch("auth/getSettings", {}, {
        root: true
      });

      return body;

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  }
}