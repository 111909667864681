<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 374 288.82"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,96.14C3.07,90.34,5.39,84,9.37,78.88,15.31,71.33,23.9,68,33.58,68q119.43,0,238.85.06c18.93,0,33.47,14.8,33.52,33.77q.18,76.47,0,152.93c-.05,18.36-13.73,32.5-32.1,33.71-2.49.16-5,.16-7.49.16-74.79,0-149.58-.33-224.37.22-21.42.15-36.17-7.43-42-28.65Zm22.43-8.35c1.65,2,2.13,2.66,2.7,3.23q58,58,116,116c7.34,7.33,16.82,7.05,24.32-.44q57.14-57.06,114.23-114.15c1.11-1.11,2.11-2.32,4.23-4.69C278.81,86.56,275,85,271.24,85q-118.49-.21-237,0C30.67,85,27.07,86.64,22.43,87.79Zm78.51,102.92-78,78c4.32,1,8.35,2.84,12.39,2.85q117.7.24,235.42,0c4,0,8.08-1.82,12-2.76L205,190.44c-9.15,9.27-18.34,18.67-27.64,28-14.38,14.41-34.32,14.37-48.84-.07C119.22,209.07,110,199.73,100.94,190.71Zm-83.75,57,69-69.32-69-69Zm202.13-69.42,69.43,69.48v-139Z"
        />
        <path
          d="M374,193.16c-.24.21-.62.39-.69.65-4.54,18.52-19.55,28.91-42,26.77-5-.47-8.2-3.9-8.18-8.43s3.35-7.92,8.3-8.25c3.49-.24,7,0,10.47-.41,9.3-1.12,15.11-7.81,15.14-17.39.07-20.33,0-40.67,0-61q0-44.5,0-89c0-12.56-6.5-19.16-19-19.17q-117,0-234,0c-12.49,0-19,6.6-19.11,19.12,0,2.33.14,4.68-.09,7a8.4,8.4,0,0,1-7.82,7.75c-4,.25-8.2-2.61-8.73-6.71C65.61,23.11,74.26,5.42,95.49.64A43,43,0,0,1,104.93,0Q221.16,0,337.4,0c19.05,0,30.53,8.64,35.83,26.89a5.42,5.42,0,0,0,.77,1.24Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "mail2",
  props: ["role"],
};
</script>
