<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 151 185.33"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M84,0h1c5.74,4.57,1.59,11.28,3.59,16.82h7.93c0-3.9,0-7.39,0-10.88,0-2.74.73-5.17,4-5.21,3.51,0,4.38,2.41,4.38,5.4,0,3.6,0,7.2,0,10.72h8.25c0-3.84-.06-7.32,0-10.79.06-2.93,1-5.87,4.44-5.11,1.67.37,3.59,3.13,3.94,5.07.64,3.49.18,7.18.18,11.21,6.3,0,11.94.25,17.55-.07,5.18-.3,9.06,1.35,11.69,5.84V180c-2.75,4.07-6.5,5.34-11.43,5.32q-64.09-.24-128.18,0c-4.91,0-8.65-1.27-11.39-5.32V23c2.77-4.65,6.88-6.16,12.19-5.84,5.58.33,11.18.07,17.12.07,0-4.22,0-7.7,0-11.17,0-3,1.06-5.84,4.42-5.11,1.65.36,3.57,3.12,3.92,5.05.63,3.5.18,7.19.18,10.93h8.29c0-3.42.21-6.56-.06-9.66C45.77,3.85,46.94,1.48,50,0h1c6.63,4.24,2,11.33,4.2,16.81H63c0-3.54.2-6.68-.06-9.78-.28-3.43,1-5.71,4.09-7h1c1.08,2.38,2.67,4.66,3.1,7.15.54,3.15.13,6.46.13,9.81h8.6c0-3.53.2-6.81,0-10.07C79.52,3.44,80.78,1.19,84,0Zm37.76,26c0,3.86,0,7.32,0,10.78,0,2.92-.76,5.59-4.27,5.56s-4.34-2.51-4.32-5.52c0-3.59,0-7.18,0-10.64h-8.24c0,3.88.46,7.45-.18,10.81-.37,2-2.24,4.8-3.87,5.15-3.33.7-4.37-2.14-4.38-5.15V26.09H88.06c0,4.09.43,8-.19,11.66-.28,1.72-2.58,3.1-4,4.63-1.35-1.55-3.59-3-3.87-4.69-.6-3.7-.19-7.56-.19-11.54H71.22c0,3.83,0,7.3,0,10.77,0,3-.84,5.88-4.28,5.11-1.62-.36-3.47-3.16-3.81-5.1-.61-3.5-.17-7.19-.17-10.83H54.59c0,3.95.06,7.58,0,11.21-.06,2.79-1.22,5.5-4.26,4.75-1.71-.42-3.65-3.09-4-5-.64-3.5-.18-7.2-.18-11H37.83c0,3.74,0,7.2,0,10.66,0,3-.81,5.55-4.24,5.58s-4.24-2.68-4.28-5.57c-.05-3.57,0-7.14,0-10.74H8.76c-.14,1.5-.35,2.79-.35,4.09,0,35.15-.07,70.31.11,105.47a10.25,10.25,0,0,0,2.86,6.38q15.85,16.31,32.18,32.14a10.89,10.89,0,0,0,6.87,2.77c29.15.19,58.31.13,87.47.11,1.44,0,2.89-.17,4.45-.27V26ZM8.82,153.33v23.3H32.35Z"
        />
        <path
          d="M75.28,101.23H28.86c-1,0-2.23.37-2.94-.08-1.74-1.11-4.31-2.4-4.6-4-.54-3,1.88-4.26,4.81-4.2.83,0,1.67,0,2.5,0h93.82c1.49,0,3.29-.38,4.4.3,1.34.84,2.91,2.63,2.88,4s-1.74,2.95-3.12,3.71c-1.18.64-2.93.26-4.42.26Z"
        />
        <path
          d="M63,126.51H27.55c-3.15,0-6.53-.17-6.38-4.36.13-3.66,3.14-4.1,6.23-4.09q35.47.06,70.93,0c3.24,0,6.5.4,6.42,4.42-.07,3.73-3.24,4-6.27,4C86.66,126.49,74.84,126.51,63,126.51Z"
        />
        <path
          d="M50.5,67.78c7.83,0,15.66,0,23.49,0,3,0,5.59.67,5.59,4.13S77.05,76.1,74,76.1q-23.49-.07-47,0c-2.89,0-5.7-.44-5.86-3.9C21,68.4,23.87,67.75,27,67.76,34.84,67.81,42.67,67.78,50.5,67.78Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Help",
  props: ["role"],
};
</script>
