import Vue from "vue";

export default {
  getDiscounts: async function ({ commit, state }, { filters, paginate }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...paginate,
      };

      return await Vue.http
        .get(`discounts`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  getDiscount: async function ({ commit, state }, id) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );

      return await Vue.http.get(`discounts/${id}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  addDiscount: async function ({ commit, state }, discount) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );

      return await Vue.http.post(`discounts`, discount).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  updateDiscount: async function ({ commit, state }, id) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );

      return await Vue.http.post(`discounts/${id}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  applyDiscount: async function ({ commit, state }, formData) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );

      return await Vue.http
        .post(`discounts/apply`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
};
