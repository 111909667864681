import Vue from "vue";

export default {
  getCashes: async function ({
    commit,
    state
  }, {
    filters,
    pagination
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...pagination
      };
      return await Vue.http
        .get(`cash`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addCash: async function ({
      commit,
      state
    },

    cash
  ) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`cash`,
          cash,
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  updateCash: async function ({
    commit,
    state
  }, {
    id,
    cash
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`cash/${id}`,
          cash,
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getCash: async function ({
    commit,
    state
  }, date) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        //...filters
      };
      return await Vue.http
        .get(`cash/date/${date}`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  saveCash: async function ({
    commit,
    state
  }, {
    date,
    cash
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`cash/date/${date}`, {
          cash,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getLastCash: async function ({
    commit,
    state
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .get(`cash/last`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
};