<template>
  <svg
    class="svg-icon"
    :role="role"
    height="512pt"
    viewBox="0 0 512 512.0019"
    width="512pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m504.34375 187.367188v269.34375c0 13.816406-5.882812 26.253906-15.269531 34.964843-8.515625 7.894531-19.910157 12.722657-32.429688 12.722657h-401.351562c-12.519531 0-23.914063-4.828126-32.417969-12.714844-9.386719-8.71875-15.269531-21.15625-15.269531-34.972656v-269.34375zm0 0"
      fill="#fec970"
    />
    <path
      d="m258.003906 504.398438h-202.710937c-26.335938 0-47.6875-21.351563-47.6875-47.6875v-270.945313l52.96875 45.710937 3.304687 2.746094v135.933594c0 46.246094 18.660156 90.078125 61.207032 108.222656 39.257812 16.742188 109.09375 26.019532 132.917968 26.019532zm0 0"
      fill="#fba028"
    />
    <path
      d="m504.339844 187.367188-190.933594-158.976563c-33.277344-27.710937-81.59375-27.710937-114.871094 0l-190.929687 158.976563 56.273437 46.855468 134.65625 112.121094c33.28125 27.714844 81.597656 27.714844 114.867188 0l134.667968-112.121094 56.273438-46.855468"
      fill="#fba028"
    />
    <path
      d="m448.070312 88.152344v146.070312l-134.667968 112.121094c-33.269532 27.714844-81.585938 27.714844-114.867188 0l-134.65625-112.121094v-146.070312c0-30.453125 24.695313-55.148438 55.160156-55.148438h273.882813c30.460937 0 55.148437 24.695313 55.148437 55.148438zm0 0"
      fill="#bfdadd"
    />
    <path
      d="m448.070312 88.152344v112.617187l-134.667968 112.121094c-33.269532 27.714844-81.585938 27.714844-114.867188 0l-134.65625-112.121094v-112.617187c0-30.453125 24.695313-55.148438 55.160156-55.148438h273.882813c30.460937 0 55.148437 24.695313 55.148437 55.148438zm0 0"
      fill="#e4f5f7"
    />
    <path
      d="m458.039062 473.445312c2.179688 0 4.34375-.929687 5.847657-2.738281 2.6875-3.226562 2.25-8.019531-.976563-10.707031l-121.234375-100.9375c-3.226562-2.6875-8.019531-2.25-10.707031.976562-2.6875 3.226563-2.25 8.023438.976562 10.710938l121.234376 100.9375c1.421874 1.183594 3.144531 1.757812 4.859374 1.757812zm0 0"
    />
    <path
      d="m170.273438 359.0625-121.234376 100.9375c-3.226562 2.6875-3.664062 7.480469-.976562 10.707031 1.5 1.808594 3.664062 2.738281 5.847656 2.738281 1.714844 0 3.4375-.574218 4.859375-1.757812l121.234375-100.941406c3.226563-2.683594 3.664063-7.480469.976563-10.707032-2.683594-3.226562-7.480469-3.664062-10.707031-.976562zm0 0"
    />
    <path
      d="m511.945312 187.367188c0-2.257813-1.003906-4.398438-2.738281-5.84375l-53.535156-44.574219v-48.796875c0-34.601563-28.148437-62.75-62.75-62.75h-71.230469l-3.421875-2.851563c-36.09375-30.066406-88.496093-30.066406-124.601562 0l-3.421875 2.851563h-15.371094c-4.199219 0-7.601562 3.402344-7.601562 7.601562 0 4.199219 3.402343 7.605469 7.601562 7.605469h218.046875c26.214844 0 47.542969 21.328125 47.542969 47.542969v52.320312.003906 90.183594l-131.929688 109.84375c-30.453125 25.371094-74.667968 25.367188-105.136718-.003906l-131.914063-109.839844v-90.179687c0-.003907 0-.007813 0-.007813v-52.320312c0-26.214844 21.332031-47.542969 47.554687-47.542969h25.421876c4.199218 0 7.605468-3.40625 7.605468-7.605469 0-4.199218-3.40625-7.601562-7.605468-7.601562h-25.421876c-34.605468 0-62.761718 28.148437-62.761718 62.75v48.796875l-53.535156 44.578125c-1.71875 1.429687-2.7187505 3.546875-2.7343755 5.78125 0 .023437-.0078125.039062-.0078125.0625v269.339844c0 30.488281 24.804688 55.289062 55.292969 55.289062h340.578125c4.199218 0 7.601562-3.402344 7.601562-7.601562 0-4.199219-3.402344-7.601563-7.601562-7.601563h-340.578125c-22.105469 0-40.085938-17.984375-40.085938-40.085937v-253.121094l43.832031 36.5c.003907 0 .003907 0 .003907.003906l134.625 112.09375c18.058593 15.035156 40.175781 22.550781 62.304687 22.546875 22.121094 0 44.25-7.515625 62.292969-22.546875l134.609375-112.074219c.023438-.019531.042969-.035156.0625-.054687l43.796875-36.464844v253.113281c0 22.101563-17.980469 40.085938-40.085937 40.085938h-30.363282c-4.199218 0-7.601562 3.402343-7.601562 7.601562s3.402344 7.605469 7.601562 7.605469h30.363282c30.488281 0 55.292968-24.804688 55.292968-55.292969v-269.277343c0-.019532.003906-.039063.003906-.0625zm-216.359374-161.964844h-79.226563c24.621094-13.574219 54.609375-13.574219 79.226563 0zm-239.308594 131.335937v61.261719l-36.789063-30.628906zm399.394531 61.257813v-61.257813l36.789063 30.628907zm0 0"
    />
    <path
      d="m367.484375 210.625h-223.019531c-4.199219 0-7.605469 3.402344-7.605469 7.601562 0 4.199219 3.40625 7.605469 7.605469 7.605469h223.019531c4.199219 0 7.605469-3.40625 7.605469-7.605469 0-4.199218-3.40625-7.601562-7.605469-7.601562zm0 0"
    />
    <path
      d="m367.484375 254.78125h-223.019531c-4.199219 0-7.605469 3.40625-7.605469 7.605469s3.40625 7.601562 7.605469 7.601562h223.019531c4.199219 0 7.605469-3.402343 7.605469-7.601562s-3.40625-7.605469-7.605469-7.605469zm0 0"
    />
    <path
      d="m367.484375 122.308594h-223.019531c-4.199219 0-7.605469 3.40625-7.605469 7.605468 0 4.195313 3.40625 7.601563 7.605469 7.601563h223.019531c4.199219 0 7.605469-3.40625 7.605469-7.601563 0-4.199218-3.40625-7.605468-7.605469-7.605468zm0 0"
    />
    <path
      d="m367.484375 166.46875h-55.753906c-4.199219 0-7.601563 3.402344-7.601563 7.601562 0 4.199219 3.402344 7.601563 7.601563 7.601563h55.753906c4.199219 0 7.601563-3.402344 7.601563-7.601563 0-4.199218-3.402344-7.601562-7.601563-7.601562zm0 0"
    />
    <path
      d="m144.464844 181.671875h141.921875c4.199219 0 7.601562-3.402344 7.601562-7.601563 0-4.199218-3.402343-7.601562-7.601562-7.601562h-141.921875c-4.199219 0-7.605469 3.402344-7.605469 7.601562 0 4.199219 3.40625 7.601563 7.605469 7.601563zm0 0"
    />
  </svg>
</template>
<script>
export default {
  name: "Imap",
  props: ["role"],
};
</script>
