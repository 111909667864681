<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 129 116.14"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M62,91l-.33-58.51c0-2,0-3.92,0-6.18H7.8c5.31,5.29,10,9.86,14.55,14.62,1.08,1.14,1.43,3,2.12,4.45-1.65-.54-3.74-.62-4.89-1.7C13.87,38.31,8.49,32.61,2.83,27.19.5,25,.36,23.3,2.75,21,8.64,15.36,14.3,9.45,20.21,3.82c.93-.88,2.72-.87,4.11-1.27-.48,1.38-.57,3.14-1.49,4.08-4.73,4.82-9.69,9.42-14.56,14.1L8.93,22H22.7c12.33,0,24.66,0,37,0a48.6,48.6,0,0,1,6.31.88V91Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Refund",
  props: ["role"],
};
</script>
