<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 602 410.5"
    class="svg-icon"
    :role="role"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M602,227.23a6.73,6.73,0,0,0-.9,1.66C595,256.66,574,273.7,545.43,273.72q-94.45.09-188.91,0H219v6.2q0,57.72,0,115.46c0,10.11-7,16.72-16.11,14.76a19.09,19.09,0,0,1-8.73-5.24q-42-41.7-83.82-83.69Q59.4,270.34,8.55,219.41c-3.12-3.13-5.71-6.78-8.55-10.19v-8c3.32-3.86,6.42-7.95,10-11.54Q102.2,97.33,194.47,5.05C198.64.87,203.3-1,208.9.5c5.24,1.38,8.55,5.05,9.73,10.36a24.92,24.92,0,0,1,.32,5.47q0,57,0,114v6.57h6.72q159.49,0,319,0c24.78,0,44.33,12.33,53.1,33.57,1.82,4.41,2.85,9.15,4.24,13.73ZM191.55,48.91,34.9,205.49,191.64,362.14V246.3h7q173.2,0,346.42,0c18.26,0,29.57-11.25,29.72-29.53.06-7,0-14,0-21,0-20.65-10.82-31.54-31.31-31.54H191.55Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "ArrowBack",
  props: ["role"],
};
</script>
