<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 337 337.13"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,182.13v-26c.29-1.46.55-2.92.89-4.37C4.17,137.53,6,122.76,11,109.19,38.82,34.13,115-10.07,194.44,2,261.7,12.16,316.82,62.9,332.35,129.17c2,8.54,3.12,17.3,4.65,26v27c-.29.92-.65,1.83-.85,2.76-2.74,12.66-4,25.85-8.43,37.88C304.93,284.46,261,321.84,196.19,334.18c-8.61,1.64-17.46,2-26.19,2.95h-3c-6.27-.6-12.55-1.1-18.8-1.82C79.16,327.37,21.13,276,5,208.43,2.9,199.76,1.64,190.9,0,182.13ZM168.3,15.68c-44.93-1.5-101.88,22.47-133,77C4.8,146.15,9.5,214.09,47.65,262.16c30.71,38.7,71.12,57.94,120.65,59.27Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Hide",
  props: ["role"],
};
</script>
