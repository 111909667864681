<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 132 167.07"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M132,80v76c-3.06,5.17-7.16,9.12-13,11H13c-6-2.23-10.7-5.8-13-12V80c2.53-5.25,5.78-9.91,11.93-11,3.73-.65,4.67-2.32,4.66-5.95,0-8.63-.53-17.45,1-25.87C21,17.62,37.47,3.39,59.59.46c29.55-3.92,57.24,17.75,56,47.71-.24,5.48-.2,11,.09,16.47.07,1.32,1.48,3.48,2.57,3.7C125.08,69.7,129.34,73.85,132,80ZM66.53,75.92q-24,0-47.94,0C11,75.94,8.2,78.58,8.18,86q-.06,31.73,0,63.45c0,7,2.74,9.75,9.66,9.76q48.19.07,96.39,0c6.87,0,9.58-2.85,9.58-9.78q0-31.49,0-62.95c0-8-2.51-10.55-10.36-10.56Q90,75.89,66.53,75.92Zm-41.29-8h81.55c0-9.61,1-19-.21-28.15C104.14,21.38,87.25,8.23,67.06,7.67,46.37,7.1,28.21,20.55,25.47,39.72,24.17,48.82,25.24,58.26,25.24,67.88Z"
        />
        <path
          d="M67.55,98.75a15.19,15.19,0,0,1,13,8.09c3,5.24,2.45,10.48-1,15.49A9.78,9.78,0,0,0,78.29,126c-1.23,6.68-5.73,10.56-12.36,10.53s-11-4-12.19-10.71a10,10,0,0,0-1.41-3.61c-3.33-5-3.78-10.31-.78-15.5C54.71,101.18,59.83,98.73,67.55,98.75Zm-9.68,15.81,1.42,3.4A45.5,45.5,0,0,0,62,124.41c1.06,1.71,2.75,3,4.16,4.54A42.81,42.81,0,0,0,70,124.12c1-1.72,1.24-4,2.39-5.54,2.12-2.94,2.54-5.88.51-8.76a7.83,7.83,0,0,0-9.21-3.25C60.26,107.65,58,109.87,57.87,114.56Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Lock",
  props: ["role"],
};
</script>
