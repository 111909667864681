export default {
  tabs: {
    btn: "week",
    tab: true,
    tab_day: true,
    tab_week: true,
  },
  studioId: null,
  dialogState: {
    form: false,
    reserva: false,
  },
  filtersWeekState: {
    box: null,
    tattooer: null
  },
  filtersState: {
    search: "",
    dates: "",
    tattooers: "",
    from: null,
    to: null
  },
  boxState: {
    name: null,
    price: null,
    token_payment: null,

    studio_id: null,
  },
  reservaState: {
    start: null,
    end: null,
    date: null,
    id: null,
    reserva_id: null,
    tattooer: null
  },
  actionState: "save",
  dateState: null,
  tattooers: [],
  studios: [],
  boxes: [],
  timetableStudio: [],
  selectedEventState: null,
  selectedElementState: null,
  selectedOpenState: false,
}