import Vue from 'vue'

export default {
  getProductBrand: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {}
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...pagination,
        ...filters
      }
      return await Vue.http.get(`brands/all`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getBrandsFilters: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`brands/filter`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getBrands: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {}
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...pagination,
        ...filters
      }
      return await Vue.http.get(`brands`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getBrand: async function ({
    commit,
    state
  }, brand_id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`brands/${brand_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  addBrand: async function ({
    commit,
    state
  }, {
    brand
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`brands`, brand).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  editBrand: async function ({
    commit,
    state
  }, {

    brand_id,
    brand
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`brands/${brand_id}`, brand).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  deleteBrand: async function ({
      commit,
      state
    },
    brand_id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.delete(`brands/${brand_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  }


}