<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    width="27.874"
    height="24.639"
    viewBox="0 0 27.874 24.639"
  >
    <path
      id="folder"
      d="M24.72,8.889H22.948V6.094a3.323,3.323,0,0,0-3.319-3.319H14.972a2.213,2.213,0,0,1-1.454-.544L12.034.939A3.155,3.155,0,0,0,9.963.164H3.319A3.323,3.323,0,0,0,0,3.483v17.5A3.825,3.825,0,0,0,3.619,24.8V24.8H22.425a3.161,3.161,0,0,0,3.092-2.534l2.293-9.6,0-.018A3.154,3.154,0,0,0,24.72,8.889ZM.94,20.983V3.483A2.382,2.382,0,0,1,3.319,1.1H9.964a2.214,2.214,0,0,1,1.454.544L12.9,2.939a3.154,3.154,0,0,0,2.071.775h4.657a2.383,2.383,0,0,1,2.38,2.38V8.889h-.446a2.576,2.576,0,0,0-2.529-2.1h-.155A2.621,2.621,0,0,0,16.3,4.639H5.5A3.158,3.158,0,0,0,2.345,7.793V23.456A2.881,2.881,0,0,1,.94,20.983ZM19.034,7.726A1.635,1.635,0,0,1,20.6,8.889H12.618a3.143,3.143,0,0,0-3.067,2.416L6.628,21.628l-.005.02A2.877,2.877,0,0,1,5.969,22.9V9.941A2.217,2.217,0,0,1,8.184,7.726ZM3.284,23.813V7.793A2.217,2.217,0,0,1,5.5,5.578H16.3a1.68,1.68,0,0,1,1.611,1.208H8.184A3.158,3.158,0,0,0,5.029,9.941V23.6A2.887,2.887,0,0,1,3.284,23.813Zm23.61-11.349L24.6,22.06l0,.018a2.22,2.22,0,0,1-2.173,1.786H6.335a3.812,3.812,0,0,0,1.2-1.989l2.923-10.323.005-.02a2.207,2.207,0,0,1,2.155-1.7h12.1a2.215,2.215,0,0,1,2.175,2.635Zm0,0"
      transform="translate(0 -0.164)"
    />
  </svg>
</template>
<script>
export default {
  name: "Files",
  props: ["role"],
};
</script>
