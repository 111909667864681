<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 435 434.37"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M435,88.37c-2.45,8-4.35,16.32-7.47,24.11C422,126.3,411,136.07,400.75,146.31q-104,103.82-208,207.67c-15,14.95-29.89,30-45,44.8a14.74,14.74,0,0,1-6.71,3.75Q72.32,418.48,3.47,434.1a23.43,23.43,0,0,1-3.47.27v-1C3.63,418.18,7.35,403,10.86,387.79c7.12-31,14.07-62,21.29-92.91a17.59,17.59,0,0,1,4.38-8q131.39-131.52,263-262.79C322.48,1.26,350.3-5.6,380.91,4.61c30.2,10.06,47.79,32,53.22,63.43.19,1.13.58,2.22.87,3.33ZM148.18,370.17,360,157.61,277.08,74.7l-210.92,212h69c9.92,0,13,3.16,13.05,13.2q0,32.23,0,64.48Zm227.13-227.6c8.52-8.21,17.95-16.25,26.1-25.42,11.7-13.16,16-29.24,13.2-46.5-7.36-46-59.86-66.35-96.08-37-9.6,7.78-17.23,18-25.4,26.7ZM128.67,306.37c-25.43,0-50.06,0-74.69.15-1.08,0-2.76,2-3.11,3.35-2.5,9.83-4.75,19.72-7.06,29.6-2.77,11.79-5.52,23.59-8.44,36.13H59.19V399a7.79,7.79,0,0,0,2,.19c21.37-5,42.71-10,64.1-14.85,3.4-.77,3.46-2.79,3.46-5.45q-.07-30.21,0-60.44Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "edit",
  props: ["role"],
};
</script>
