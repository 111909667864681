import { set } from "@/utils/vuex";

export default {
  SET_FROM(state, data) {
    state.filters.from = data;
  },
  SET_TO(state, data) {
    state.filters.to = data;
  },
  SET_FILTER_TYPE(state, data) {
    state.filter_type = data;
  },
};
