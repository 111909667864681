<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 169 168.63"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M169,26.18v131c-3.8,8.77-10.57,11.51-19.91,11.44q-64.57-.45-129.17,0c-9.34.07-16.1-2.69-19.92-11.44v-131C6.13,11.92,19.19,15.56,30.84,15c0-3.88,0-7.18,0-10.48,0-2.72,1-5,3.88-4.41,1.46.31,3.23,2.65,3.49,4.29.55,3.36.17,6.87.17,10.33h92.35c0-3.64,0-6.95,0-10.25,0-2.66,1-5.06,3.93-4.38,1.44.33,3.14,2.7,3.4,4.35.53,3.35.16,6.84.16,10.54C150.12,15.6,163,11.9,169,26.18ZM7.77,61.39c0,29.9,0,59.22,0,88.54,0,8.89,2.23,11,11.36,11h131c9,0,11.2-2.14,11.21-11.2q0-41.74,0-83.48c0-1.59-.19-3.19-.29-4.89ZM38.39,23.05c0,3.19,0,5.83,0,8.47-.05,2.81.37,6.34-3.67,6.4s-3.9-3.29-3.88-6.22c0-2.78,0-5.56,0-8.76-5.91,0-11.31-.63-16.44.27-2.41.43-5.92,3.64-6.14,5.86C7.5,36.94,8,44.93,8,53H161.21c0-7.88.44-15.41-.25-22.83-.23-2.47-3.07-6.32-5.2-6.68-5.57-.95-11.41-.32-17.54-.32,0,3.68.44,6.9-.16,9.93-.36,1.83-2.31,3.34-3.55,5-1.27-1.63-3.29-3.13-3.63-4.94-.6-3.17-.17-6.53-.17-10.06Z"
        />
        <path
          d="M88.31,76.66h15.22V91.85c-4.31,0-8.55.17-12.77-.13-.87-.06-2.26-1.78-2.33-2.8C88.15,85,88.31,81,88.31,76.66Z"
        />
        <path
          d="M80.5,76.66V91.87c-4.33,0-8.42.17-12.49-.12a3.77,3.77,0,0,1-2.64-2.51c-.29-4.07-.12-8.18-.12-12.58Z"
        />
        <path d="M111.61,91.57V76.76H126.4V91.57Z" />
        <path d="M134.68,91.57V76.67h14.81v14.9Z" />
        <path d="M34.37,118.34h-15V103.68h15Z" />
        <path d="M57.47,103.56v14.72h-15V103.56Z" />
        <path d="M80.56,103.55V118.3H65.5V103.55Z" />
        <path d="M103.57,103.63v14.58H88.67V103.63Z" />
        <path d="M126.59,103.62v14.6H111.73v-14.6Z" />
        <path d="M149.53,118.38H134.68V103.55h14.85Z" />
        <path d="M19.33,91.44V76.78h15V91.44Z" />
        <path d="M42.55,76.64H57.31V91.41H42.55Z" />
        <path d="M19.47,145.05V130.49H34.3v14.56Z" />
        <path d="M57.38,145.3H42.58V130.6h14.8Z" />
        <path d="M65.47,145V130.5H80.36V145Z" />
        <path d="M88.54,145.22V130.51h14.88v14.71Z" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Calendar3",
  props: ["role"],
};
</script>
