<template>
  <v-dialog v-model="dialog" max-width="70%">
    <v-card>
      <v-btn
        style="right: 0px; position: absolute; top: 0px"
        icon
        small
        @click="dialog = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="pt-0" v-if="fileType !== 'office'">
        <v-row justify="center" align="baseline">
          <v-col
            style="font-size: 24px !important; font-weight: 500 !important"
            :style="$vuetify.breakpoint.xsOnly ? 'font-size: 15px' : ''"
            cols="12"
            >{{ $t("file-viewer") }}</v-col
          >

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                v-on="on"
                :href="url"
                download
                target="_blank"
                class="button"
                style="right: 20px; position: absolute; top: 0px"
              >
                <v-icon color="primary">$download</v-icon>
              </a>
            </template>
            {{ $t("download") }}
          </v-tooltip>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-row justify="center">
          <v-col v-if="fileType === 'pdf'" cols="9">
            <pdf
              :src="url"
              @num-pages="pageCount = $event"
              :page="currentPage"
              class="scrollable"
            >
              <template slot="loading">loading content here...</template>
            </pdf>
            <v-pagination
              v-if="pageCount > 1"
              v-model="currentPage"
              :length="pageCount"
            ></v-pagination>
          </v-col>
          <v-col cols="9" v-else-if="fileType === 'img'">
            <v-img :src="url" max-width="100%" style="margin: auto" />
          </v-col>
          <v-col cols="12" v-else-if="fileType === 'office'">
            <VueDocPreview :value="url" :type="fileType" :height="70"
              >other previer</VueDocPreview
            >
          </v-col>

          <v-icon v-else-if="fileType === 'zip'" size="200" color="grey1">
            mdi-folder-zip-outline
          </v-icon>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FileDialog",
  components: {
    pdf: () => import("vue-pdf"),
  },
  props: {
    url: {
      type: String,
      default: () =>
        "https://quickink-dev.s3.eu-west-3.amazonaws.com/Facturas/4.pdf",
    },
    title: {
      type: String,
      default: () => "Archivo",
    },
  },
  data() {
    return {
      dialog: false,
      pageCount: null,
      currentPage: 1,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.currentPage = 1;
    },
  },
  computed: {
    fileType() {
      let filename = this.url.split(".");
      let type = filename[filename.length - 1];
      switch (type.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "png":
          return "img";
        case "pdf":
          return "pdf";
        case "docx":
        case "pptx":
        case "xlsx":
          return "office";

        case "txt":
          return "text";
        case "zip":
        case "rar":
          return "zip";
        default:
          return type;
      }
    },
  },
};
</script>