export default {
  lists: {
    //SALES
    salesByEmployee: [],
    salesByPeriod: [],
    salesByProduct: [],
    salesByService: [],
    salesBySubService: [],
    //CLIENTS
    topClientsByCash: [],
    topClientsByAppointments: [],
    clientsByAgeGroup: [],
    clientsByAge: [],
    debtsByClient: [],
    moreThanOneService: [],
    moreThanOneServiceClients: [],
    howMeet: [],
    paymentType: [],
    //APPOINTMENTS
    advicements: [],
    onlineDates: [],
    byArtistType: [],
    //EXPENSES
    bySuppliers: [],
    byInternUses: [],
  },
  filters: {
    from: null,
    to: null,
  },
  filter_type: "month",
};