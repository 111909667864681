import Vue from "vue";
export default {
  authenticate: async function ({
    dispatch,
    commit
  }, {
    email,
    password
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.post("authenticate", {
        email,
        password
      });
      commit("auth/SET_USER", body, {
        root: true,
      });
      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );
      dispatch(
        "auth/getRelatedUsers", {}, {
          root: true,
        }
      );
    } catch (e) {
      commit("auth/INVALID_CREDENTIALS", null, {
        root: true,
      });
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getSettings: async function ({
    commit
  }, data) {
    try {
      //console.log("getSettings")

      const {
        body
      } = await Vue.http.get("users/me/settings");
      commit("auth/SET_SETTINGS", body, {
        root: true,
      });
    } catch (e) {} finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getRelatedUsers: async function ({
    commit
  }, data) {
    try {
      //console.log("getSettings")

      const {
        body
      } = await Vue.http.get("users/me/related_studios");
      commit("auth/SET_RELATED_USERS", body, {
        root: true,
      });
    } catch (e) {} finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  forgotPassword: async function ({
    commit
  }, {
    email
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.post("forgot_password", {
        email,
      });
      return body;
    } catch (e) {} finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  resetPassword: async function ({
    commit
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.post("reset_password", formData);
      return body;
    } catch (e) {} finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  authenticateOauth: async function ({
    dispatch,
    commit
  }, {
    token_id,
    type
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.post("authenticate/oauth", {
        token_id,
        type,
      });
      commit("auth/SET_USER", body, {
        root: true,
      });
      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );
    } catch (e) {
      commit("auth/INVALID_CREDENTIALS", null, {
        root: true,
      });
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getUserHash: async function ({
    commit
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`users/hash`).then((response) => {
        return response.json();
      });
    } catch (e) {
      // commit('auth/INVALID_CREDENTIALS', null, { root: true })
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addUser: async function ({
    commit,
    dispatch
  }, data) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.post(`users/add`, data);
      //console.log(body);
      commit("auth/SET_USER", body, {
        root: true,
      });
      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );
    } catch (e) {
      //console.log(e);
      commit("auth/INVALID_CREDENTIALS", null, {
        root: true,
      });
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  checkHash: async function ({
    commit
  }, {
    valors,
    hash
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .post(`users/hash`, {
          valors,
          hash,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      // commit('auth/INVALID_CREDENTIALS', null, { root: true })
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  checkActive: async function ({
    commit,
    dispatch
  }) {
    try {
      const {
        body
      } = await Vue.http.get("users/activated");
      //console.log("ACTIVE", body)
      commit("auth/SET_ACTIVE", body.active, {
        root: true,
      });
      commit("auth/SET_ACTIVE_DATE", body.date, {
        root: true,
      });
      //console.log(body)
      commit("auth/SET_USER", body, {
        root: true,
      });
      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );
    } catch (e) {
      //console.log('%c%s', 'color: #d90000', "Error", e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  checkBoxActive: async function ({
    commit,
    dispatch
  }) {
    try {
      const {
        body
      } = await Vue.http.get("users/activated_box");
      // console.log("box", body.box)
      commit("auth/BOX_ACTIVE", body.box, {
        root: true,
      });
    } catch (e) {
      //console.log('%c%s', 'color: #d90000', "Error", e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  logout: async function ({
    dispatch,
    state,
    commit
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      await Vue.http.post(`logout`);
      commit("auth/UNSET_USER", null, {
        root: true,
      });
      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );
    } catch (e) {
      //console.log(e)
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  imageUpdate: async function ({
    state,
    commit
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      const {
        body
      } = await Vue.http.post(`users/me/profile/photo`, formData);
      if (body.success) commit("UPDATE_IMAGE_URL", body.url);
    } catch (e) {
      //console.log(e)
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  imageForm: async function ({
    state,
    commit
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      const {
        body
      } = await Vue.http.post(`users/me/profile/form`, formData);
      return body;
    } catch (e) {
      //console.log(e)
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  profileUpdate: async function ({
    dispatch,
    state,
    commit
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      const {
        body
      } = await Vue.http.post(`users/me/profile`, formData);
      commit("auth/SET_USER", body, {
        root: true,
      });
      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );
    } catch (e) {
      //console.log(e)
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  updateRRSS: async function ({
    dispatch,
    commit,
    state
  }, user) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const {
        body
      } = await Vue.http.post(`users/me/profile/rrss`, user);
      commit("auth/SET_USER", body, {
        root: true,
      });
      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );
    } catch (e) {
      //console.log(e)
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getRelations: async function ({
    state,
    commit
  }) {
    try {
      const {
        body
      } = await Vue.http.get(`users/me/relations`);
      return body;
    } catch (e) {
      //console.log(e)
    } finally {}
  },
  getNotifications: async function ({
    state,
    commit
  }) {
    try {
      const {
        body
      } = await Vue.http.get(`users/me/notifications`);
      commit("SET_NOTIFICATIONS", body);
    } catch (e) {
      //console.log(e)
    } finally {}
  },
  getAllNotifications: async function ({
    state,
    commit
  }, {
    filters,
    pagination
  }) {
    try {
      const params = {
        ...filters,
        ...pagination,
      };
      return await Vue.http
        .get(`users/me/notifications/all`, {
          params,
        })
        .then((data) => {
          return data.json();
        });
    } catch (e) {
      //console.log(e)
    } finally {}
  },
  markNotification: async function ({
    state,
    commit
  }, id) {
    try {
      const {
        body
      } = await Vue.http.post(`users/me/notifications/${id}`);
      commit("SET_NOTIFICATIONS", body);
    } catch (e) {
      //console.log(e)
    } finally {}
  },
  markAllNotification: async function ({
    state,
    commit
  }) {
    try {
      const {
        body
      } = await Vue.http.post(`users/me/notifications/markAll`);
      commit("SET_NOTIFICATIONS", body);
    } catch (e) {
      //console.log(e)
    } finally {}
  },
};