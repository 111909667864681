<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 529.23 537.7"
    class="svg-icon"
    :role="role"
  >
    <path class="cls-1" d="M255.37,212.83s0,.12,0,.36l.18-.1Z" />
    <path
      class="cls-2"
      d="M266.7,304.1s-.36,4.24-1.53,10.85c-3.86,21.54-16.44,68.06-53.75,73.15C162.66,394.76,135.66,322,135.66,322l16.91,11.45a76.43,76.43,0,0,0,52.75,12.78c.25,0,.5-.06.74-.11a76.42,76.42,0,0,0,47.41-26.43l7.74-9.11Z"
    />
    <path class="cls-2" d="M255.37,212.83s0,.12,0,.36l.18-.1Z" />
    <path
      class="cls-2"
      d="M422.26,133.85,388,137.94a160.2,160.2,0,0,1-97.26-19.09l-30.21-16.77-9.16,4.26-20,101.75c-.19,1-.36,1.94-.52,2.89a160.05,160.05,0,0,1-92.14,10.66l-34-6.1-7.29,7,14,102.74a116.64,116.64,0,0,0,58.38,85.6l44.33,24.74,7.43-1,36.09-35.72a116.38,116.38,0,0,0,31.53-57.2l3.64,4.06,7.38,1.44,45.7-22.12A116.58,116.58,0,0,0,409.15,243l20-101.75Zm-143.68,196A104,104,0,0,1,248.84,390L216.1,422.42,175.88,400a104,104,0,0,1-52.09-76.37l-12.86-94.24,25.56,4.58a172.35,172.35,0,0,0,92.83-8.83c4.37-1.68,8.68-3.56,13-5.6,0,0,0,0,0,0l13.09-6.29c0-.24,0-.36,0-.36l.2.26,10.13-4.86,3,22.31,6.7,49.16,3.11,22.77q.66,4.8.85,9.62A99.83,99.83,0,0,1,278.58,329.9Zm118.31-89.3a104,104,0,0,1-56.45,73.21L299,333.88l-7.38-8.21a113.5,113.5,0,0,0-.65-24.9l-1-7.14a44.69,44.69,0,0,0,15.68,6.26c48.29,9.48,79.48-61.59,79.48-61.59l-17.56,10.45a76.26,76.26,0,0,1-53.4,9.67l-.72-.14a74.61,74.61,0,0,1-30.17-13.56L276.91,198,268,193.25l-23.21,11.16L262,117.17l22.69,12.6a172.63,172.63,0,0,0,104.78,20.59l25.79-3.08Z"
    />
    <path
      class="cls-2"
      d="M390.88,209.05l-53.66-10.53S355,166.31,390.88,209.05Z"
    />
    <path
      class="cls-2"
      d="M314.74,194.1,261.1,183.57C310.51,157.59,314.74,194.1,314.74,194.1Z"
    />
    <path
      class="cls-2"
      d="M185.8,285.05l-54.17,7.39C170,251.88,185.8,285.05,185.8,285.05Z"
    />
    <path
      class="cls-2"
      d="M262.67,274.56,252.26,276l-12.93,1.76L208.49,282s3.58-20.4,25.61-18.38a50.6,50.6,0,0,1,14.43,3.78A99.72,99.72,0,0,1,262.67,274.56Z"
    />
  </svg>
</template>
<script>
export default {
  name: "Appointments",
  props: ["role"],
};
</script>
