import constants from "@/constants";

export default {
  SET_TATTOOER(state, data) {
    state.tattooerState = data;
  },
  SET_STUDIO(state, data) {
    state.studioState = data;
  },
  SET_CUSTOMER(state, data) {
    state.customerState = data;
  },
  RESET_CUSTOMER(state) {
    state.customerState = {
      files: [],
      studio: null,
      user: {
        role_id: constants.roles.CUSTOMER
      },
      body_part: null,
      type_id: null,
      sub_type: null,
      sub_type_id: null,
      inner_part: null,
      inner_part_other: null,
      date: null,
      city: null,
      action: null,
      pre_tattooer: null,
      extra_customers: []
    }
  },
  SET_EXTRA_CUSTOMERS(state, data) {
    state.customerState.extra_customers = [];
    for (let i = 0; i < data; i++) {
      state.customerState.extra_customers.push({
        user: {
          role_id: constants.roles.CUSTOMER
        },
        city: null
      })
    }
  },
  SET_SUBSERVICES(state, data) {
    state.subServicesState = data;
  }
}