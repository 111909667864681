<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 417 508.51"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,156.12c2.23-9.67,5.63-18.72,12.78-26,9.21-9.41,20.3-14.37,33.44-14.38q85.23-.09,170.45.07a8.18,8.18,0,0,1,5.5,2.4q38.7,43.79,77.08,87.88c1.32,1.51,1.75,4.22,1.75,6.37q.13,79.74.07,159.47,0,44.49,0,89c0,27.74-19.75,47.57-47.51,47.59q-102.72.07-205.44,0c-24.62,0-41.81-14-47.3-38.17-.1-.42-.54-.77-.82-1.15ZM185.35,139c-1.71-.13-2.54-.26-3.36-.26-45,0-90-.09-135,0-14.43,0-23.86,9.88-23.9,24.47-.08,23.84,0,47.67,0,71.51q0,112.74,0,225.5c0,15.49,9.48,25.12,24.79,25.12q102.24,0,204.48,0c16.29,0,25.67-9.26,25.67-25.15q0-111.76,0-223.51v-5.63h-6.09c-20,0-40-.55-60,.18-14.37.53-27.15-9-26.72-26.54.49-20,.12-40,.11-60Zm83.44,68.82c-20.13-22.52-39.7-44.4-60-67.07v67.07Z"
        />
        <path
          d="M417,354.14c-3.17,6.86-5.45,14.33-9.69,20.43-8.41,12.1-20.84,17.9-35.49,18.36-11.48.36-23,.17-34.49.08-7.77-.06-13-4.83-12.94-11.6s5.21-11.33,13.05-11.37c10.83-.06,21.67.1,32.5-.05,14.06-.2,24-10,24-24q.13-113.49,0-227c0-.82-.09-1.65-.17-3.08-2-.11-3.86-.31-5.77-.31-20.16,0-40.33,0-60.49,0-17.35,0-26.36-9.17-26.37-26.62q0-30,0-60V23.07h-5.39q-65,0-130,0c-17.77,0-26.8,9-26.81,26.66,0,10,0,20,0,30,0,7.78-4.67,12.66-11.75,12.57-6.71-.08-11.28-5-11.2-12.59.15-14-.78-28.12,1.07-41.89C120,16,139.58.16,161.53.13,218.36.06,275.18.15,332,0c3.6,0,5.45,1.75,7.43,4q37.71,43,75.44,86c.65.74,1.41,1.37,2.12,2Zm-32.5-262-60-67v67Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "copy",
  props: ["role"],
};
</script>
