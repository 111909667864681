import Vue from 'vue'

export default {
  getBoxes: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {}
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...pagination,
        ...filters
      };

      return await Vue.http.get(`boxs`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getBoxesReservations: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {}
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      console.log(filters)
      const params = {
        ...pagination,
        ...filters
      };

      return await Vue.http.get(`boxs/reservations`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getBox: async function ({
    commit,
    state
  }, box_id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`boxs/${box_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getTable: async function ({
    commit,
    state
  }, {
    date,
    studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`boxs/table/${studio}`, {
        params: {
          date
        }
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  showTablePercentage: async function ({
    commit,
    state
  }, {
    dates,
    studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`boxs/table/week/${studio}`, {
        params: {
          dates
        }
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  showWeekTableBox: async function ({
    commit,
    state
  }, {
    dates,
    id_box
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`boxs/table/week/box/${id_box}`, {
        params: {
          dates
        }
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  showWeekTableTattooer: async function ({
    commit,
    state
  }, {
    dates,
    id_studio
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`boxs/table/week/tattooer/${id_studio}`, {
        params: {
          dates
        }
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  generatePdf: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`boxs/pdf`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getTableBox: async function ({
    commit,
    state
  }, {
    id_box,
    date
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`boxs/table/box_view/${id_box}`, {
        params: {
          date
        }
      }).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addBox: async function ({
    commit,
    state
  }, {
    box
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`boxs`, box).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  updateBox: async function ({
    commit,
    state
  }, {
    box,
    id_box
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`boxs/${id_box}`, box).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  deleteBox: async function ({
    commit,
    state
  }, {

    id_box
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.delete(`boxs/${id_box}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAllBoxes: async function ({
    commit,
    state
  }, {
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`boxs/all_export`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  orderBox: async function ({
    commit,
    state
  }, {
    id,
    order
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.post(`boxs/order_up/${id}`, order).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  importToAppointment: async function ({
    commit,
    state
  }, formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.post(`users/me/boxes/import`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },



}