export default {
  SET_EXPENSES_FROM(state, data) {
    state.filtersState.from = data;
  },
  SET_EXPENSES_TO(state, data) {
    state.filtersState.to = data;
  },
  SET_EXPENSES_TYPE(state, data) {
    state.filtersState.type = data;
  },
  SET_EXPENSES_PAY_MODE(state, data) {
    state.filtersState.pay_mode = data;
  },
  SET_EXPENSES_RECURRENT(state, data) {
    state.filtersState.recurrent = data;
  },
  SET_EXPENSES_TATTOOER_ID(state, data) {
    state.filtersState.tattooer_id = data;
  },
  SET_EXPENSES_CASH_REGISTER_ID(state, data) {
    state.filtersState.cash_register_id = data;
  },
  RESET_EXPENSES_FILTERS(state) {
    state.filtersState = {
      from: null,
      to: null,
      tattooer_id: null,
      type: null,
      pay_mode: null,
      recurrent: null,
    }
  }
}