<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 397 325.22"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M332,325.22c-5.41-2.58-7.2-6.74-7.19-12.8q.26-150,.12-300C324.93,2.69,327.55.1,337.42.1c15.83,0,31.66.18,47.49-.1,5.4-.09,9.35,1.53,12.09,6.14V325.22ZM343.28,18.33V306.5h35.39V18.33Z"
        />
        <path
          d="M115,325.22c-5.5-2.92-6.88-7.58-6.87-13.61q.21-131.19.1-262.39c0-10.39,2.63-13,12.94-13h46.47c10.31,0,12.86,2.57,12.86,13.09q0,122.2,0,244.41c0,6.33-.23,12.67.06,19,.28,6-1.82,10.31-7.57,12.55ZM126.3,54.35V300.74a10.53,10.53,0,0,0,.1,3.47c.38,1,1.41,2.53,2.18,2.54,11.11.17,22.21.12,33.38.12V54.35Z"
        />
        <path
          d="M224,325.22c-5.74-2.28-7.5-6.57-7.48-12.6.15-52,.08-103.94.1-155.91,0-9.57,2.87-12.36,12.61-12.37h46c11.11,0,13.64,2.5,13.64,13.43,0,51.31-.08,102.61.11,153.92,0,6.09-1.51,10.61-6.94,13.53Zm46.51-162.46H235.05V306.43h35.46Z"
        />
        <path
          d="M0,204.19c3.29-4.62,7.82-5.87,13.34-5.79,15.31.24,30.63.08,45.94.09,10.23,0,12.83,2.54,12.83,12.56,0,33.8-.11,67.6.12,101.39,0,6-1.71,10.27-7.23,12.78H0Zm18.39,12.74v89.71H53.82V216.93Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Grafic",
  props: ["role"],
};
</script>
