import Vue from "vue";

export default {
  getSubServices: async function ({
    commit,
    state
  }, {
    filters,
    pagination,
    service_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...pagination
      };
      return await Vue.http
        .get(`users/me/services/${service_id}/subservices`, {
          params
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addSubService: async function ({
    commit,
    state
  }, {
    service_id,
    subservice
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/me/services/${service_id}/subservices`, subservice)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  updateSubService: async function ({
    commit,
    state
  }, {
    service_id,
    id,
    subservice
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/me/services/${service_id}/subservices/${id}`, subservice)
        .then((response) => {
          console.log(response);
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  archiveSubService: async function ({
    commit,
    state
  }, {
    service_id,
    id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .delete(`users/me/services/${service_id}/subservices/${id}`)
        .then((response) => {
          console.log(response);
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  attachTattooersSubService: async function ({
    commit,
    state
  }, {
    service_id,
    sub_service_id,
    tattooers
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/me/services/${service_id}/subservices/${sub_service_id}/tattooers`, {
          tattooers
        })
        .then((response) => {
          console.log(response);
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  consentFormSubService: async function ({
    commit,
    state
  }, {
    service_id,
    sub_service_id,
    consent_forms
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/me/services/${service_id}/subservices/${sub_service_id}/consent-form`, {
          consent_forms
        })
        .then((response) => {
          console.log(response);
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  inventorySubService: async function ({
    commit,
    state
  }, {
    service_id,
    sub_service_id,
    inventories
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/me/services/${service_id}/subservices/${sub_service_id}/inventory`, {
          inventories
        })
        .then((response) => {
          console.log(response);
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
};