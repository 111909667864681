<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 475 411.44"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M475,96.47c-1.26,5.13-2.31,10.31-3.8,15.36-4.56,15.39-13.37,28.05-24.77,39.24Q362.61,233.32,279,315.77c-21.62,21.25-42.35,43.55-65.26,63.3C175.52,412,131.05,420,84,402.21c-46.61-17.59-74-52.59-82.74-101.88-.4-2.27-.82-4.54-1.23-6.82v-20c1.89-8.93,3.24-18,5.78-26.75,5.9-20.22,16.8-37.62,31.63-52.51Q116.3,115.08,195.26,36a39.4,39.4,0,0,1,4-3.78,10.69,10.69,0,0,1,14.82,15.19,79.06,79.06,0,0,1-5.85,6.16q-77.36,77.5-154.71,155C31.31,230.86,19.79,257.68,22,289.29c2.64,38.78,22.17,67.65,55.55,86.49,42.6,24,94.44,15.57,129.19-18.91,57.74-57.28,115.89-114.14,173.85-171.18,18-17.77,36.22-35.43,54.08-53.39a64.71,64.71,0,0,0-91.11-91.92q-91.4,91.05-182.34,182.53c-9.48,9.5-10,23-1.42,31.68,8.93,9,22.23,8.7,32-1.1Q247.28,198,302.69,142.37a20.68,20.68,0,0,1,6.35-4.66c4.44-1.83,9.64.14,12.34,4.06a10.92,10.92,0,0,1-.5,12.61,25,25,0,0,1-2.35,2.58q-56.19,56.25-112.4,112.47c-19.95,19.85-51.45,16.83-66.78-6.07-11.57-17.31-9.24-39.76,5.87-54.92q67.6-67.83,135.26-135.57c15.31-15.34,30.54-30.74,45.94-46,24.46-24.24,53.77-32.62,86.67-23,33.14,9.74,53,33.05,60.49,66.77.5,2.25.95,4.52,1.42,6.78Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Clip",
  props: ["role"],
};
</script>
