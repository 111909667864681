<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    width="23.435"
    height="24.503"
    viewBox="0 0 23.435 24.503"
  >
    <g data-name="usuario (1)" transform="translate(-11.178 0.15)">
      <g data-name="Grupo 55" transform="translate(11.328 12.133)">
        <g data-name="Grupo 54" transform="translate(0)">
          <path
            data-name="Trazado 32"
            d="M22.9,257.323A11.581,11.581,0,0,0,11.328,268.89a.5.5,0,0,0,1.006,0,10.561,10.561,0,1,1,21.123,0,.5.5,0,0,0,1.006,0A11.58,11.58,0,0,0,22.9,257.323Z"
            transform="translate(-11.328 -257.323)"
            stroke-width="0.3"
          />
        </g>
      </g>
      <g data-name="Grupo 57" transform="translate(17.363)">
        <g data-name="Grupo 56" transform="translate(0)">
          <path
            data-name="Trazado 33"
            d="M144.86,0a5.532,5.532,0,1,0,5.532,5.532A5.539,5.539,0,0,0,144.86,0Zm0,10.058a4.526,4.526,0,1,1,4.526-4.526A4.532,4.532,0,0,1,144.86,10.058Z"
            transform="translate(-139.328)"
            stroke-width="0.3"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Customers",
  props: ["role"],
};
</script>
