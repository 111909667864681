import Vue from 'vue'

export default {
  getCustomers: async function ({
    commit,
    state
  }, {
    pagination,
    filters,
    loading
  } = {
    pagination: {},
    filters: {},
    loading: true

  }) {
    try {
      if (loading)
        commit('app/SET_PROCESSING', {
          show: true,
          message: null
        }, {
          root: true
        });
      const filterParams = Object.keys(filters).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filters[k])}`).join('&');
      const filterPagination = Object.keys(pagination).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(pagination[k])}`).join('&');

      return await Vue.http.get(`customers?${filterPagination}&${filterParams}`).then(response => {
        return response.json();
      });

    } catch (e) {
      console.log(e);
    } finally {
      if (loading)
        commit('app/SET_PROCESSING', {
          show: false,
          message: null
        }, {
          root: true
        })
    }
  },

  getCustomerFilters: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`customers/filter`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getCustomer: async function ({
    commit,
    state
  }, customer_id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`customers/${customer_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  addCustomer: async function ({
    commit,
    state
  }, {
    customer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`customers`, customer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addCustomerExcel: async function ({
    commit,
    state
  }, formData) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`customers/excel`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  addCustomerWithoutDate: async function ({
    commit,
    state
  }, {
    customer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`customers/no-date`, customer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  updateCustomerWithoutDate: async function ({
    commit,
    state
  }, {
    customer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`customers/no-date-update`, customer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  editCustomer: async function ({
    commit,
    state
  }, {

    customer_id,
    customer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`customers/${customer_id}`, customer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAllCustomers: async function ({
    commit,
    state
  }, {
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.get(`customers/all`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

}