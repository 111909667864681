<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 361.56 363.85"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M56.5,363.85c1-3.16,1.48-5,2.08-6.81q22.78-68.24,45.68-136.44c1.38-4.1,1-6.28-2.6-9-32.75-24.91-65.32-50-97.94-75.13A10.93,10.93,0,0,1,0,132.15H5.26c40.18,0,80.35-.07,120.52.1,4.07,0,5.74-1.3,7.11-5Q155.45,66.37,178.34,5.61C179,3.94,179.72,2.32,180.7,0c5.17,13.69,10.12,26.72,15,39.78,11,29.25,22,58.47,32.78,87.79,1.33,3.61,3,4.68,6.77,4.67,40.17-.15,80.35-.09,120.52-.09h5.31l.46,1c-1.19,1-2.36,2-3.58,2.91q-49.55,38.06-99.16,76c-3,2.26-3.22,4.13-2.1,7.46q23,68.43,45.72,137c.62,1.87,1.21,3.74,2.19,6.76-2.07-1.22-3.29-1.81-4.37-2.59q-57.85-42.36-115.63-84.85c-3.15-2.33-5.1-2.17-8.09,0Q119.39,318,62.1,359.85C60.65,360.92,59.17,362,56.5,363.85ZM47.17,148.76c2.41,2,3.64,3,4.92,4,23.12,17.77,46.19,35.59,69.42,53.21,3,2.31,3.6,4.27,2.39,7.85-11.27,33.28-22.34,66.62-33.47,99.94-.53,1.6-1,3.22-1.87,6.22l92.06-67.41,91.81,67.23c-.72-2.74-1-4.3-1.54-5.81-11.14-33.35-22.24-66.71-33.54-100-1.29-3.79-.58-5.79,2.6-8.19,20.08-15.15,40-30.54,59.92-45.87,4.39-3.37,8.73-6.8,13.1-10.2l-.43-1h-5.32c-27.84,0-55.68-.07-83.52.09-3.58,0-5.28-1.08-6.49-4.43-5.91-16.26-12.05-32.43-18.12-48.63-6-16-12.06-32-18.38-48.8-.81,1.95-1.33,3.11-1.78,4.3-11.6,30.85-23.26,61.67-34.71,92.57-1.36,3.67-3,5.11-7.1,5-14.5-.3-29-.12-43.5-.12Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "star",
  props: ["role"],
};
</script>
