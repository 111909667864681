<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 298 409.42"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,137.13c2.48-5.36,6.68-7.06,12.5-7,32,.19,64,.07,96,.13a21.16,21.16,0,0,1,6.84.89c3.87,1.36,5.75,4.5,5.6,8.55-.16,4.22-2.23,7.35-6.45,8.48a25,25,0,0,1-6.41.66q-41.51.08-83,0H18.94V390.57H279.05V148.85h-5.88q-41.51,0-83,0a30.33,30.33,0,0,1-5.48-.3,9.13,9.13,0,0,1-7.65-9.13,9.36,9.36,0,0,1,8.3-9.14,37.48,37.48,0,0,1,4.5-.07c31.66,0,63.33.12,95-.11,5.66,0,10,1.4,13.17,6v267c-2.83,4.72-6.87,6.3-12.45,6.29q-136.29-.23-272.6,0C7.26,409.43,3,408,0,403.13Z"
        />
        <path
          d="M158.18,303.18c2.23-2.12,3.44-3.22,4.6-4.38,17.44-17.43,34.81-34.93,52.38-52.22,2.1-2.07,5.3-4.24,8-4.21,2.81,0,6.2,2.09,8.21,4.3,2.52,2.77,2,6.56-.21,9.74a25.56,25.56,0,0,1-3,3.3q-35.16,35.18-70.33,70.34c-6.61,6.6-10.77,6.64-17.29.13q-35-35-70-69.95a27.68,27.68,0,0,1-3.64-4.1A8.82,8.82,0,0,1,68,244.7a9.33,9.33,0,0,1,11.9-1,31.62,31.62,0,0,1,3.64,3.41q25.8,25.78,51.57,51.63a27.57,27.57,0,0,1,3,4.42l1.75-1.27v-5.78q0-140.94,0-281.88A46.59,46.59,0,0,1,140,7.75,8.81,8.81,0,0,1,149.46,0a8.73,8.73,0,0,1,8.61,8.09,52.83,52.83,0,0,1,.11,5.49V303.18Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "inbox",
  props: ["role"],
};
</script>
