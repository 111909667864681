import Vue from 'vue'

export default {

  getTattooerStudio: async function ({
    commit,
    state
  }, {
    tattooer_id,
    studio_id
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`tattooers/${tattooer_id}/studio/${studio_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  getAppointmentById: async function ({
      commit,
      state
    },
    appointment_id
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`appointments/${appointment_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  newTattooer: async function ({
    commit,
    state
  }, {
    tattooer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`tattooers/inscription`, tattooer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  newTattooerBox: async function ({
    commit,
    state
  }, {
    tattooer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`tattooers/inscription/box`, tattooer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getReservationPayment: async function ({
    commit,
    state
  }, id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`tattooers/inscription/box/${id}/pay`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  acceptStudio: async function ({
    commit,
    state
  }, {
    accept
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`tattooers/inscription/accept`, accept).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  updateTattooer: async function ({
    commit,
    state
  }, {
    tattooer_id,
    tattooer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`tattooers/inscription/${tattooer_id}`, tattooer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

  updateStudio: async function ({
    commit,
    state
  }, {
    tattooer_id,
    tattooer
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`studios/inscription/${tattooer_id}`, tattooer).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  customerInscription: async function ({
    commit,
    state
  }, ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      let formData = new FormData();
      Object.keys(state.customerState).forEach(key => {
        switch (key) {
          case "files":
            state.customerState[key].forEach(file => formData.append(key + "[]", file.file))

          case "extra_customers":
            state.customerState[key].forEach((item, i) => {
              Object.keys(item).forEach(inner_key => {
                switch (inner_key) {

                  case "user":
                    Object.keys(item[inner_key]).forEach(ii_key => formData.append(`${key}[${i}][${inner_key}][${ii_key}]`, item[inner_key][ii_key]))
                    break;
                  default:
                    formData.append(`${key}[${i}][${inner_key}]`, item[inner_key])
                }
              });
            });
            break
          case "week":
            state.customerState[key].forEach(inner => formData.append(key + "[]", inner))
            break
          case "tattoo_type":
            formData.append(key, state.customerState[key].id)
            break
          case "user":
          case "tattooer":
          case "studio":
            Object.keys(state.customerState[key]).forEach(inner_key => formData.append(`${key}[${inner_key}]`, state.customerState[key][inner_key]))
            break
          default:
            formData.append(key, state.customerState[key])
            break
        }


      })

      return await Vue.http.post(`appointments`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  customerInscriptionAsesoramiento: async function ({
    commit,
    state
  }, ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      let formData = new FormData();
      Object.keys(state.customerState).forEach(key => {
        switch (key) {
          case "files":
            state.customerState[key].forEach(file => formData.append(key + "[]", file.file))

            break
          case "week":
            state.customerState[key].forEach(inner => formData.append(key + "[]", inner))
            break
          case "tattoo_type":
            formData.append(key, state.customerState[key].id)
            break
          case "user":
          case "tattooer":
          case "studio":
            Object.keys(state.customerState[key]).forEach(inner_key => formData.append(`${key}[${inner_key}]`, state.customerState[key][inner_key]))
            break
          default:
            formData.append(key, state.customerState[key])
            break
        }


      })
      return await Vue.http.post(`appointments/asesoramiento`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  customerPreInscription: async function ({
    commit,
    state
  }, ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      let formData = new FormData();
      Object.keys(state.customerState).forEach(key => {
        switch (key) {
          case "files":
            state.customerState[key].forEach(file => formData.append(key + "[]", file.file))

            break
          case "week":
            state.customerState[key].forEach(inner => formData.append(key + "[]", inner))
            break
          case "tattoo_type":
            formData.append(key, state.customerState[key].id)
            break
          case "user":
          case "tattooer":
          case "studio":
            Object.keys(state.customerState[key]).forEach(inner_key => formData.append(`${key}[${inner_key}]`, state.customerState[key][inner_key]))
            break
          default:
            formData.append(key, state.customerState[key])
            break
        }


      })
      return await Vue.http.post(`appointments/pre`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  customerPostInscription: async function ({
    commit,
    state
  }, id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      let formData = new FormData();
      Object.keys(state.customerState).forEach(key => {
        switch (key) {
          case "files":
            state.customerState[key].forEach(file => formData.append(key + "[]", file.file))

            break
          case "week":
            state.customerState[key].forEach(inner => formData.append(key + "[]", inner))
            break
          case "tattoo_type":
            formData.append(key, state.customerState[key].id)
            break
          case "user":
          case "tattooer":
          case "studio":
            Object.keys(state.customerState[key]).forEach(inner_key => formData.append(`${key}[${inner_key}]`, state.customerState[key][inner_key]))
            break
          default:
            formData.append(key, state.customerState[key])
            break
        }


      })
      return await Vue.http.post(`appointments/${id}/post`, formData).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },


  updateInscription: async function ({
      commit,
      state
    },
    appointment_id
  ) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.post(`appointments/${appointment_id}`, state.customerState).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

}