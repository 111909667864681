import store from '../store'
import * as jwt from 'jsonwebtoken'
import router from '../router'

export function checkJWTExpired() {

  if (store.getters['auth/isLoggedIn']) {
    try {
      verifyToken();
    } catch (e) {
      if (e.name === 'TokenExpiredError') {
        if (router.history.current.name !== 'Lock') {
          router.replace({
            name: 'Lock',
            query: {
              redirect: router.history.current.path
            }
          })
        }
      }
    }
  } else {

    if (router.history.current.name !== 'Login' && router.history.current.meta.requiresAuth) {
      router.replace({
        name: 'Login'
      })
    }
  }
}

export function verifySpecificToken(token) {
  return jwt.verify(token, process.env.VUE_APP_KEY_JWT, {
    ignoreNotBefore: true
  });
}

export function verifyToken() {
  return jwt.verify(store.state.auth.jwt, process.env.VUE_APP_KEY_JWT, {
    ignoreNotBefore: true
  });
}