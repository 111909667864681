export default {
  filtersState: {
    from: null,
    to: null,
    tattooer_id: null,
    type: null,
    pay_mode: null,
    recurrent: null,
    cash_register_id: null,
  },
  hide: {
    confirmed_gastos: true,
    confirmed_ingresos: true,
    forecast: true,
    total: true,
    customer: true,
    tattooer: true,
    appointment: true
  }
}