import constants from "@/constants";

export default {
  studioState: {},
  tattooerState: {
    user: {}
  },
  customerState: {
    files: [],
    studio: null,
    user: {
      role_id: constants.roles.CUSTOMER
    },
    body_part: null,
    sub_type: null,
    sub_type_id: null,
    type_id: null,
    inner_part: null,
    inner_part_other: null,
    date: null,
    action: null,
    pre_tattooer: null,
    extra_customers: []
  },
  subServicesState: []
}