<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 290 288.8"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M290,110.24c-.4.86-.89,1.69-1.2,2.58-3.83,11-13.22,18.21-24.86,18.28-26.48.16-53,.21-79.44,0-14.74-.11-26-11-26.2-26q-.56-39.7,0-79.44C158.54,10.82,169.8.21,184.8.11,211-.06,237.09,0,263.24.1,276,.16,285,7,289,19.06a19.6,19.6,0,0,0,1,2.18ZM224.5,13.08c-12.66,0-25.33,0-38,0-10,0-15.2,5-15.23,15q-.14,37.5,0,75c0,9.93,5.34,15.08,15.22,15.09q37.5.07,75,0c10.26,0,15.44-5.16,15.45-15.35q.06-37.25,0-74.5c0-10.25-5.07-15.19-15.45-15.22C249.16,13.05,236.83,13.08,224.5,13.08Z"
        />
        <path
          d="M0,21.24c.52-1.2,1.11-2.37,1.56-3.59C5.67,6.69,14.57.16,26.32.1Q66-.08,105.73.13c14.35.1,25.71,10.68,26,25q.67,40.44,0,80.9c-.24,14.23-11.68,24.91-26,25-26.47.23-52.94.16-79.41,0-11.64-.06-21.13-7.2-24.94-18.14-.43-1.24-.88-2.48-1.32-3.73Zm66.19-8.16c-12.5,0-25,0-37.49,0-10.64,0-15.6,4.94-15.61,15.59q-.06,36.75,0,73.49c0,10.91,5,16,15.87,16q37.25,0,74.49,0c10.1,0,15.32-5.21,15.35-15.47q.12-37.25,0-74.49c0-10-5.21-15.07-15.11-15.11C91.19,13.05,78.69,13.08,66.19,13.08Z"
        />
        <path
          d="M290,267.25c-2.59,4.59-4.56,9.71-7.93,13.64-4.88,5.69-11.82,7.72-19.34,7.7-25.83,0-51.67.12-77.5-.07-16-.12-26.93-11.17-27-27.17q-.22-38.25,0-76.5c.08-16.17,11.29-27.33,27.45-27.41,24.16-.13,48.33,0,72.49,0,18.44,0,25.25,4.68,31.86,21.85Zm-65.94,8.28c12.67,0,25.33.05,38,0,9.78-.05,14.85-5,14.88-14.76q.1-37.75,0-75.5c0-9.34-5.2-14.73-14.5-14.78q-38.5-.18-77,0c-8.7,0-14.08,5.39-14.13,14.14q-.23,38.25,0,76.5c.05,9.37,5.33,14.34,14.75,14.39C198.73,275.58,211.4,275.53,224.06,275.53Z"
        />
        <path
          d="M0,179.25C3.05,169.84,8,162,18.06,159a37.8,37.8,0,0,1,10.3-1.51q37.47-.18,74.94-.05c17.68,0,28.55,11,28.58,28.74q.06,36.74,0,73.45c0,18.26-10.58,28.91-28.79,29-23.15.06-46.31-.52-69.44.19-16.66.52-28.4-5-33.65-21.51Zm66.08,96.28c12.33,0,24.67,0,37,0,10.75,0,15.69-4.86,15.72-15.5q.09-37.24,0-74.49c0-9.8-5.38-15.06-15.28-15.08q-37.48-.09-75,0c-10.23,0-15.43,5.22-15.45,15.39q-.08,37.24,0,74.49c0,10.22,5.12,15.17,15.5,15.19C41.09,275.55,53.58,275.53,66.08,275.53Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "square",
  props: ["role"],
};
</script>
