import Vue from "vue";

export default {
  getAppointments: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  } = {
    pagination: {},
    filters: {},
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...pagination,
        ...filters,
      };
      return await Vue.http
        .get(`appointments`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getAppointment: async function ({
    commit,
    state
  }, appointment_id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .get(`appointments/${appointment_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  markAppointment: async function ({
    commit,
    state,
    dispatch
  }, appointment_id) {
    try {
      await Vue.http
        .post(`appointments/${appointment_id}/opened`)
        .then((response) => {
          dispatch(
            "appointments/getPendingAppointments", {}, {
              root: true,
            }
          );
        });
    } catch (e) {
      console.log(e);
    } finally {}
  },
  getAppointmentsDates: async function ({
    commit,
    state
  }, {
    pagination,
    filters
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...filters,
        ...pagination,
      };
      return await Vue.http
        .get(`appointments/dates`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  updateAppointment: async function ({
    commit,
    state
  }, {
    appointment_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      //console.log("update")
      return await Vue.http
        .post(`appointments/${appointment_id}/update`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  removeCustomer: async function ({
    commit,
    state
  }, {
    appointment_id,
    customer_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      //console.log("update")
      return await Vue.http
        .delete(`appointments/${appointment_id}/customer/${customer_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  removeTattooer: async function ({
    commit,
    state
  }, {
    appointment_id,
    tattooer_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      //console.log("update")
      return await Vue.http
        .delete(`appointments/${appointment_id}/tattooer/${tattooer_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getAppointmentFilters: async function ({
    commit,
    state
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`appointments/filter`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addAppointmensDesign: async function ({
    commit,
    state
  }, design) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/designs`, design)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  internalForm: async function ({
    commit,
    state
  }, form) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/prepare`, form)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  internalFormSave: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/prepare/${id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addAppointmensDesignTokenPayment: async function ({
    commit,
    state
  }, design) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/designs/token-payment`, design)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getAppoinmentsTokenPayments: async function ({
      commit,
      state
    },
    appointment_id
  ) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .get(`appointments/designs/token-payments/${appointment_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  //get AppointmentTokenPayment by AppointmentTokenPayment id
  getAppointmentTokenPayment: async function ({
      commit,
      state
    },
    appointment_token_id
  ) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .get(`appointments/designs/token-payment/${appointment_token_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addPendingCustomer: async function ({
    commit,
    state
  }, {
    id,
    form
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/${id}/pending-customer`, form)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  generatePendingCustomer: async function ({
    commit,
    state
  }, {
    id,
    method
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/${id}/pending-customer/${method}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addAppointmensTattooer: async function ({
    commit,
    state
  }, tattooer) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/tattooer`, tattooer)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addAppointmensCustomer: async function ({
    commit,
    state
  }, tattooer) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/customer`, tattooer)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  updateAppointmensDesign: async function ({
    commit,
    state
  }, {
    id,
    design
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/designs/${id}`, design)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  updateAppointmensDesignTokenPayment: async function ({
    commit,
    state
  }, {
    id,
    design
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/designs/${id}/token-payment`, design)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getAppointentsByStudioTattoerDate: async function ({
    commit,
    state
  }, {
    studio_id,
    tattooer_id,
    date
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        tattooer_id,
        studio_id,
        date,
      };
      return await Vue.http
        .get(`appointments/studiotattoerdate`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getAppointmentsByCustomerId: async function ({
    commit,
    state
  }, {
    customer_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        customer_id,
      };
      return await Vue.http
        .get(`appointments/customerid`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getAppointmentsByTattooerId: async function ({
    commit,
    state
  }, {
    tattooer_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        tattooer_id,
      };
      return await Vue.http
        .get(`appointments/tattooerid`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getMinMaxDates: async function ({
    commit,
    state
  }, id) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        id: id,
      };
      return await Vue.http
        .get(`appointments/labor-exange`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addAppointmensDate: async function ({
    commit,
    state
  }, appointement) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/labor-exange`, appointement)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addShareArchive: async function ({
    commit,
    state
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/share`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  sendArchive: async function ({
    commit,
    state
  }, formData) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/send_archive`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getPayment: async function ({
    commit,
    state
  }, {
    appointment_id,
    appointment_token_id,
    form
  }) {
    console.log("form", form);
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(
          `appointments/${appointment_id}/pay/appointment_token/${appointment_token_id}`,
          form
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  generateTokenPayment: async function ({
    commit,
    state
  }, {
    id,
    method
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/token-payment/${id}/${method}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  generateAppointmentTokenPayment: async function ({
    commit,
    state
  }, {
    id,
    method,
    user_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/appointment-token-payment/${id}/${method}`, {
          user_id
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getPendingAppointments: async function ({
    commit,
    state
  }) {
    try {
      const {
        body
      } = await Vue.http.get(`users/me/appointments/pending`);
      commit("appointments/SET_INSCRIPTION_APPOINTMENTS_COUNT", body, {
        root: true,
      });
    } catch (e) {
      //console.log(e);
    } finally {}
  },
  confirmAppointment: async function ({
    commit,
    state
  }, {
    appointment_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/${appointment_id}/confirm`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  generatePdf: async function ({
    commit,
    state
  }, consentForm) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/consent`, consentForm)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  saveConsentAndCheck: async function ({
    commit,
    state
  }, consentForm) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/consent/check`, consentForm)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  saveClientForm: async function ({
    commit,
    state
  }, consentForm) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/consent/customer`, consentForm)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addSesion: async function ({
    commit,
    state
  }, {
    appointment_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/${appointment_id}/sesion`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  updatePrices: async function ({
    commit,
    state
  }, {
    appointment_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/${appointment_id}/sesion/prices`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  editSesion: async function ({
    commit,
    state
  }, {
    appointment_id,
    session_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(`appointments/${appointment_id}/sesion/${session_id}`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  deleteSesion: async function ({
    commit,
    state
  }, {
    appointment_id,
    session_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .delete(`appointments/${appointment_id}/sesion/${session_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  deleteAppointmentPaymentToken: async function ({
    commit,
    state
  }, {
    appointment_payment_token_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .delete(
          `appointments/appointment-token-payment/${appointment_payment_token_id}`
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  deleteExpenseTokenPayment: async function ({
    commit,
    state
  }, {
    appointment_payment_token_id
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .delete(
          `appointments/appointment-token-payment/${appointment_payment_token_id}/despagar`
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  editAppointmentPaymentToken: async function ({
    commit,
    state
  }, {
    appointment_payment_token_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http
        .post(
          `appointments/appointment-token-payment/${appointment_payment_token_id}`,
          formData
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  deleteOrArchivate: async function ({
    commit,
    state
  }, data) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...data,
      };
      return await Vue.http
        .delete(`appointments/delete`, {
          body: params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addNewCustomer: async function ({
    commit,
    state
  }, {
    id,
    data
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .post(`appointments/${id}/customer`, data)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  addExistingCustomer: async function ({
    commit,
    state
  }, {
    id,
    data
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .put(`appointments/${id}/customer`, data)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addExistingTattooer: async function ({
    commit,
    state
  }, {
    id,
    data
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .put(`appointments/${id}/tattooer`, data)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getPendingConfiguration: async function ({
    commit,
    state
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      return await Vue.http.get(`appointments/states`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  updatePendingConfiguration: async function ({
      commit,
      state,
      dispatch
    },
    formData
  ) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      let a = await Vue.http
        .post(`appointments/states`, formData)
        .then((response) => {
          return response.json();
        });

      dispatch(
        "auth/getSettings", {}, {
          root: true,
        }
      );

      return a;
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  addDeposit: async function ({
    state,
    commit
  }, {
    appointment_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .post(`appointments/${appointment_id}/deposit`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  editDeposit: async function ({
    state,
    commit
  }, {
    appointment_id,
    formData
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .put(`appointments/${appointment_id}/deposit`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  actionDeposit: async function ({
    state,
    commit
  }, {
    appointment_id,
    formData,
    action
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .post(`appointments/${appointment_id}/deposit/${action}`, formData)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
  getEventCalendarAppointment: async function ({
    state,
    commit
  }, event_id) {
    try {
      /*
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
*/
      return await Vue.http
        .get(`appointments/calendar/${event_id}`)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      /*
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
      */
    }
  },

  sendMail: async function ({
    state,
    commit
  }, {
    appointment_id,
    mail
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );

      return await Vue.http
        .post(`appointments/${appointment_id}/mail`, mail)
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },

  getAllMails: async function ({
    state,
    commit
  }, {
    pagination,
    filters
  }) {
    try {
      commit(
        "app/SET_PROCESSING", {
          show: true,
          message: null,
        }, {
          root: true,
        }
      );
      const params = {
        ...pagination,
        ...filters,
      };

      return await Vue.http
        .get(`appointments/mails`, {
          params
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING", {
          show: false,
          message: null,
        }, {
          root: true,
        }
      );
    }
  },
};