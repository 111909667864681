<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 319 318.67"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,17.24c.41-.88.86-1.75,1.23-2.65C5,5.37,11.66.09,21.81.07,64.93,0,108.06-.07,151.18.19c2.84,0,6.26,2.13,8.41,4.27q77.54,77.18,154.82,154.6c1.64,1.63,3.07,3.47,4.59,5.21v5q-43.39,43.44-86.78,86.88-29.13,29.16-58.3,58.26c-5.66,5.65-8.66,5.68-14.25.09Q81.93,236.83,4.22,159.11C2.71,157.6,1.4,155.89,0,154.27ZM300.67,165.86c-.48-.53-1.41-1.67-2.45-2.7q-73-73-146.08-145.93a11.44,11.44,0,0,0-7.36-2.83c-39.82-.17-79.65-.13-119.48-.12-8.77,0-10.74,2-10.75,11,0,39.67,0,79.34-.09,119a11.15,11.15,0,0,0,3.77,8.67q72.41,72.18,144.66,144.52a23.5,23.5,0,0,1,2.57,3.65Z"
        />
        <path
          d="M43.68,65.14a21.64,21.64,0,1,1,21.56,21.6A21.63,21.63,0,0,1,43.68,65.14Zm28.83.15A7.09,7.09,0,0,0,65.34,58,7.23,7.23,0,0,0,58,65.19a7.32,7.32,0,0,0,7,7.1A7.16,7.16,0,0,0,72.51,65.29Z"
        />
        <path
          d="M218.4,209.47c-1.54,2.6-2.29,4.7-3.7,6.13q-20.89,21.14-42,42.07c-3.65,3.63-8,3.82-11,.76s-2.66-7.34,1-11c13.86-13.92,27.66-27.91,41.8-41.55,1.87-1.81,5.77-2.72,8.37-2.21C214.92,204.05,216.43,207.27,218.4,209.47Z"
        />
        <path
          d="M173.15,196.15c-1.89-1.69-4.63-3-5.37-5.09s-.53-5.82.88-7.31c9.61-10.15,19.55-20,29.6-29.73,3-2.93,6.88-2.74,9.85.31s3,6.84,0,9.86c-9.65,9.9-19.46,19.63-29.33,29.3C177.63,194.65,175.65,195,173.15,196.15Z"
        />
        <path
          d="M153.3,202.33c1.64,1.37,4.43,2.64,5.21,4.68s.55,5.71-.82,7.3a186,186,0,0,1-15.44,15.58c-3,2.71-6.59,2.37-9.45-.4-2.7-2.62-3.25-6.29-.79-9.05,5.08-5.7,10.64-11,16.16-16.26C149.14,203.26,150.89,203.16,153.3,202.33Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "tattoo_style",
  props: ["role"],
};
</script>
