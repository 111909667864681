<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 290 288.77"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,121.31v-33c3.56-7.25,9.37-9.68,17.38-9.66q127.62.27,255.26,0c8,0,13.89,2.26,17.36,9.66v33c-3.21,7.6-9,10.05-17.1,10q-127.65-.24-255.31,0C9.4,131.36,3.35,129.07,0,121.31ZM13.38,92.17v25.69H276.61V92.17Z"
        />
        <path
          d="M0,43.31v-33C3,2.57,8.59,0,16.78,0Q144.6.3,272.42,0c8,0,13.94,2,17.58,9.31v34c-3.88,7.34-10,9.23-18.13,9.21q-126.9-.33-253.81,0C10,52.54,3.89,50.56,0,43.31ZM13.35,39H276.59V13.43H13.35Z"
        />
        <path
          d="M290,246.31v33c-3.48,7.06-9.1,9.47-16.94,9.45q-127.56-.27-255.12,0c-8.09,0-14.22-2-17.94-9.46v-33c3.14-7.59,8.81-10.13,17-10.11q128.05.28,256.1,0C281.13,236.2,287,238.58,290,246.31Zm-13.27,3.4H13.5v25.54H276.73Z"
        />
        <path
          d="M290,167.31v33c-3.39,7.45-9.28,9.84-17.29,9.82q-127.71-.23-255.44,0c-8,0-13.86-2.41-17.27-9.82v-33c3.42-7.72,9.49-10,17.68-9.95q127.58.27,255.16,0C280.9,157.35,286.68,159.84,290,167.31ZM13.27,196.65H276.69V170.91H13.27Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "list",
  props: ["role"],
};
</script>
