<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 104.94 144.89"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M5,144.89H0V2C1.79,1.33,3.58.1,5.37.09Q52.5-.09,99.62.09c1.78,0,3.55,1.24,5.32,1.91V144.89h-5L89.1,133.25,77,144.89H74l-11.59-11-8.4,11H51L42.68,134c-4.2,3.93-8,7.42-11.7,10.92H28L15.71,133.53Zm1.93-13L8.29,133c1.4-1.79,2.78-3.59,4.18-5.38,2.53-3.19,5-3.34,7.75-.12s5.68,6.44,8.31,9.4a14.69,14.69,0,0,0,1.94-1.12c2.61-2.56,5.2-5.15,7.81-7.72,4.2-4.14,5.23-4.05,8.9.75,1.71,2.24,3.39,4.5,5.3,7,2.18-2.85,4.27-5.4,6.15-8.11,2.25-3.24,4.54-3.61,7.21-.57,3,3.34,5.91,6.66,9.48,10.67,3.22-3.59,5.46-6.28,7.9-8.77,5.3-5.42,5.33-5.37,10.58-.18,1.19,1.18,2.46,2.29,4.18,3.89V7H6.93Z"
        />
        <path d="M91.65,79.27v6H13.27v-6Z" />
        <path d="M91.64,92.39v6.09H13.47V92.39Z" />
        <path d="M38.8,52.47H26.52v-39H38.8Z" />
        <path d="M13.32,111.47v-6H78.55l.26,6Z" />
        <path d="M65.45,66.28v5.59H13.29L13,66.28Z" />
        <path d="M13.38,13.36h5.94v39H13.38Z" />
        <path d="M78.42,52.45H72.36V13.52h6.06Z" />
        <path d="M65.4,13.37v39H59.3v-39Z" />
        <path d="M91.55,52.75l-6.09-.27v-39h6.09Z" />
        <path d="M46.22,13.27h5.84V52.32H46.22Z" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Ticket",
  props: ["role"],
};
</script>
