var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.width,"persistent":_vm.persistent,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.type == 'button' || _vm.type == 'block')?_c('v-btn',_vm._g({attrs:{"color":_vm.color,"elevation":"0","block":_vm.type == 'block',"height":"30","disabled":_vm.disabled},on:{"click":function($event){_vm.$emit('click_open');
        _vm.$emit('open');}}},on),[(_vm.icon && !_vm.no_icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.btn_text || _vm.title)+" ")],1):(_vm.type == 'icon' && _vm.tooltip)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var onTool = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.color,"small":"","disabled":_vm.disabled},on:{"click":function($event){_vm.$emit('click_open');
            _vm.$emit('open');}}},Object.assign({}, on, onTool)),[(_vm.icon)?_c('v-icon',{attrs:{"large":!_vm.small,"small":_vm.x_small}},[_vm._v(_vm._s(_vm.icon))]):_vm._e()],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.btn_text || _vm.title)+" ")]):(_vm.type == 'icon')?_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.color,"small":"","disabled":_vm.disabled},on:{"click":function($event){_vm.$emit('click_open');
        _vm.$emit('open');}}},on),[(_vm.icon)?_c('v-icon',{attrs:{"large":!_vm.small,"small":_vm.x_small}},[_vm._v(_vm._s(_vm.icon))]):_vm._e()],1):(_vm.type == 'fab')?_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.color,"small":"","fab":"","outlined":"","disabled":_vm.disabled},on:{"click":function($event){_vm.$emit('click_open');
        _vm.$emit('open');}}},on),[(_vm.icon)?_c('v-icon',{attrs:{"large":!_vm.small}},[_vm._v(_vm._s(_vm.icon))]):_vm._e()],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":_vm.card_id}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":"","color":"white"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.$slots.outside)?_vm._t("outside"):_vm._e(),(_vm.$slots.default)?_c('v-card-text',[_vm._t("default")],2):_vm._e(),(_vm.$slots.outsite_after)?_vm._t("outsite_after"):_vm._e(),(_vm.$slots.actions || _vm.basic_btn)?[_c('v-card-actions',[(_vm.$slots.actions)?_vm._t("actions"):(_vm.basic_btn)?[_c('v-spacer'),_c('v-btn',{staticStyle:{"height":"25px","width":"100px"},attrs:{"outlined":"","elevation":"0"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0"},on:{"click":function($event){return _vm.$emit('save')}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("save", { name: _vm.$t("") }))+" ")],1)]:_vm._e()],2)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }