<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    width="44"
    height="44"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="4" y="4" width="6" height="6" rx="1" />
    <rect x="14" y="4" width="6" height="6" rx="1" />
    <rect x="4" y="14" width="6" height="6" rx="1" />
    <path d="M14 17h6m-3 -3v6" />
  </svg>
</template>

<script>
export default {
  name: "More",
  props: ["role"],
};
</script>
