<template>
  <v-card
    style="heigth: 100%"
    elevation="6"
    align="center"
    class="mx-2 mx-md-6"
  >
    <div class="actions" v-if="$slots.actions">
      <slot name="actions"> </slot>
    </div>
    <div class="checkbox" v-if="$slots.checkbox">
      <slot name="checkbox"> </slot>
    </div>

    <v-avatar
      class="mt-8"
      color="primary"
      :size="$vuetify.breakpoint.smAndDown ? 80 : 100"
      style="
        margin: 0 auto;
        font-size: 50px;
        text-transform: uppercase;
        color: #272727;
        cursor: pointer;
      "
    >
      <v-img v-if="url" :src="url">
        <template v-slot:placeholder>
          <span
            style="
              align-items: center;
              margin: 0 auto;
              font-size: 50px;
              text-transform: uppercase;
              color: #272727;
              cursor: pointer;
            "
            >{{ nameAvatar }}</span
          >
        </template>
      </v-img>
      <span v-else>{{ nameAvatar }}</span>
    </v-avatar>
    <v-card-title
      class="no-wrap justify-center"
      style="
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-size: 16px;
        cursor: pointer;
      "
      v-html="title"
    >
    </v-card-title>
    <v-card-subtitle style="font-size: 13px" class="py-2" v-html="subtitle">
    </v-card-subtitle>

    <v-card-subtitle class="no-wrap py-2" style="font-size: 13px">
      <v-icon small color="primary">$email</v-icon> {{ email || "-" }}
    </v-card-subtitle>
    <v-card-subtitle
      class="no-wrap py-2"
      :href="`tel:${phone}`"
      style="font-size: 13px"
    >
      <v-icon small color="primary">$phone</v-icon> {{ phone || "-" }}
    </v-card-subtitle>

    <br />
    <v-card-text v-if="$slots.default" class="py-2">
      <slot> </slot>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  mounted() {
    console.log(this.$slots);
  },
  props: {
    url: { type: String, required: false },
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    email: { type: String, required: true },
    phone: { type: String, required: false },
  },
  computed: {
    nameAvatar() {
      //console.log(arr);
      let arr = this.title.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });
      // //console.log(nom);
      return nom;
    },
  },
};
</script>

<style lang="sass" scoped>
.actions
  position: absolute
  top: 5px
  right: 5px

.checkbox
  position: absolute
  top: 5px
  left: 5px
</style>
