<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 355 354.21"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,191V165c.3-1.62.72-3.24.89-4.87C5.5,116.53,23,78.71,55.55,49.45c57.68-51.86,123.81-63.22,195-32.71,51.21,22,83.84,62,98.54,116,2.77,10.21,4,20.84,5.93,31.28v28a18.25,18.25,0,0,0-.89,3.25,168.72,168.72,0,0,1-11.51,47.37c-30.75,76.83-109.48,121.74-192,109.58C79.61,341.75,21.3,288.26,5,218.37,2.86,209.36,1.63,200.14,0,191ZM177.62,16.89C88.25,17,16.11,88.73,16.12,177.57s72.36,160.69,161.55,160.6A160.92,160.92,0,0,0,338.93,176.7C338.85,88.66,266.33,16.82,177.62,16.89Z"
        />
        <path
          d="M137.92,167c.1-6,2.06-9.94,8.37-11.82,10.37-3.08,20.44-6.91,31.6-6.18,17.4,1.13,27.22,12,24.27,29.15-2.23,13.05-6.61,25.71-9.72,38.62a170.19,170.19,0,0,0-4.37,22.94c-.63,6.46,2.54,9.45,9.08,10.08a38.19,38.19,0,0,0,8.93-.35,79.45,79.45,0,0,0,8.23-2c.83,6.51-.73,9.48-6.69,11.39-7.58,2.42-15.15,5.35-22.94,6.45a48.7,48.7,0,0,1-18.7-1.42c-11.63-3.06-18.26-12.75-16.26-24.56,2.11-12.41,5.79-24.55,8.75-36.81,1.95-8.06,4.53-16,5.6-24.23,1.36-10.44-2-13.7-12.6-13.35C147.06,165.05,142.69,166.22,137.92,167Z"
        />
        <path
          d="M194.71,130.14c-12.53-.2-22.66-9.53-22.44-20.67.22-11.45,10.67-20.6,23.33-20.42,12.48.17,22.39,9.4,22.32,20.78S207.55,130.35,194.71,130.14Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "color",
  props: ["role"],
};
</script>
