import Vue from "vue";

export default {
  addConsentFormDefault: async function(
    { commit, state },
    { service_id, form }
  ) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/me/consent_form_default`, { service_id, form })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  getConsentFormDefault: async function(
    { commit, state },
    { service_id, user_id }
  ) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http
        .get(
          `users/me/consent_form_default/service/${service_id}/user/${user_id}`
        )
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
};
