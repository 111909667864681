export default {


  SET_TABS(state, data) {
    state.tabs[data.tab] = data.value;
  },
  SET_DIALOG(state, data) {
    state.dialogState[data.dialog] = data.value;
  },
  OPEN_FORM(state, data) {
    state.dialogState.form = true;
    state.actionState = data.action;
    if (data.box)
      state.boxState = data.box;
    else
      state.boxState = {
        name: null,
        price: null,
        token_payment: null,

        studio_id: null,
      };
  },
  SET_FILTERS_WEEK(state, data) {
    state.filtersWeekState = data;
  },
  SET_FILTERS(state, data) {
    state.filtersState = data;
  },
  SET_DATE(state, data) {
    state.dateState = data;
  },
  SET_TATTOOERS(state, data) {
    state.tattooers = data;
  },
  SET_STUDIOS(state, data) {
    state.studios = data;
  },
  SET_BOXES(state, data) {
    state.boxes = data;
  },
  SET_TIMETABLE_STUDIO(state, data) {
    state.timetableStudio = data;
  },
  SET_BOX(state, data) {
    state.boxState = data;
  },
  SET_SELECTED_EVENT(state, data) {
    state.selectedEventState = data;
  },
  SET_SELECTED_ELEMENT(state, data) {
    state.selectedElementState = data;
  },
  SET_SELECTED_OPEN(state, data) {
    state.selectedOpenState = data;
  },
  SET_STUDIO_ID(state, data) {
    state.studioId = data;
  },
  showEvent(state, data) {
    const open = () => {
      state.selectedEventState = data.event;
      state.selectedEventState.box_name = data.box.name;
      //console.log(state.selectedEventState);
      if (data.nativeEvent) state.selectedElementState = data.nativeEvent.target;
      setTimeout(() => (state.selectedOpenState = true), 10);
    };

    if (state.selectedOpen) {
      state.selectedOpen = false;
      setTimeout(open, 100);
    } else {
      open();
    }

    data.nativeEvent.stopPropagation();

  },
  reserva(state, {

    reserva,
    action
  }) {
    state.dialogState.reserva = true;
    state.reservaState = reserva;
    state.actionState = "save";
    state.actionState = action;
    if (action == 'edit') {
      state.reservaState = {
        start: reserva.start_time,
        tattooer: reserva.tattooer_id,
        end: reserva.end_time,
        date: reserva.start_date,
        id: reserva.box_id,
        reserva_id: reserva.reserva_id,
      }
    }
  }
}