import Vue from "vue";

export default {
  getPacks: async function ({ commit, state }, { filters, pagination }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = { ...filters, ...pagination };
      return await Vue.http
        .get(`users/me/packs`, { params })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },

  addPack: async function ({ commit, state }, pack) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http.post(`users/me/packs`, pack).then((response) => {
        return response.json();
      });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },

  updatePack: async function ({ commit, state }, { id, pack }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http
        .post(`users/me/packs/${id}`, pack)
        .then((response) => {
          console.log(response);
          return response.json();
        });
    } catch (e) {
      console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },

  archivePack: async function ({ commit, state }, id) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http.delete(`users/me/packs/${id}`).then((response) => {
        console.log(response);
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
};
