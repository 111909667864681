
export default {
    SET_UNREAD_EMAILS(state, data) {
        state.unreadEmails = data;
    },
    
    DECREASE_UNREAD_EMAILS(state, mail_id) {
        switch(mail_id) {
            case 1:
                --state.unreadEmails.gmail;
                break;
            case 2:
                --state.unreadEmails.outlook;
                break;
            case 3:
                --state.unreadEmails.imap;
                break;
        }
    }
}
