export default {

  user: null,
  jwt: null,
  errorLogin: false,
  error: null,
  errorDate: null,
  filters: {
    search: '',
  },
  active: true,
  active_date: null,
  notifications: [],
  users: [],
  box_active: false,
}