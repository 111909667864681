<template>
  <svg
    class="svg-icon"
    :role="role"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 529.23 537.7"
  >
    <path
      class="cls-1"
      d="M365.55,149.33H163.68a23.39,23.39,0,0,0-23.39,23.39V324.88a23.39,23.39,0,0,0,23.39,23.39h62.67l38.25,40.1,38.26-40.1h62.69A23.38,23.38,0,0,0,389,324.88V172.72A23.38,23.38,0,0,0,365.55,149.33ZM256.2,193.87h16.86v35l-4,48.82h-8.79l-4.11-48.82Zm20.28,119.36H252.57V287.65h23.91Z"
    />
  </svg>
</template>
<script>
export default {
  name: "Appointments",
  props: ["role"],
};
</script>
