export default {
  roles: {
    NOBODY: 0,
    ADMIN: 1,
    //* TATTOOER RELATED
    TATTOOER: 2,
    TATTOOER_MANAGER: 7,
    TATTOOER_GUEST: 8,
    //* STUDIO RELATED
    STUDIO: 3,
    STUDIO_ADMIN: 5,
    STUDIO_MANAGER: 6,
    STUDIO_GUEST: 9,
    //* CLIENT RELATED
    CUSTOMER: 4
  },
}