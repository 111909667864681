<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 357 358.4"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M357,165.37v28a13.42,13.42,0,0,0-.83,2.76c-2.09,22.79-8.14,44.47-18.95,64.63-28.2,52.6-71.64,84.46-130.55,94.76-8.46,1.48-17.11,1.94-25.67,2.88h-6a27.25,27.25,0,0,0-3.86-.71,168.36,168.36,0,0,1-71.4-18.26C48.57,313.19,16.5,272,4,215.76c-1.65-7.39-2.66-14.92-4-22.39v-27c.63-4.27,1.33-8.53,1.89-12.81,12.67-95.71,103.61-165,199.59-152C274.14,11.39,332.71,63.08,351.13,134,353.79,144.29,355.08,154.91,357,165.37ZM16.18,178.87c-.79,88.59,71.75,161.8,161.17,162.68S340,270.2,340.86,180.18c.87-88.76-71.41-162.92-159.63-163.77C90,15.53,17,87.39,16.18,178.87Z"
        />
        <path
          d="M324.61,179.56c-.2,80.84-66.06,146.12-147.12,145.83C97.61,325.1,32.16,259,32.5,179,32.83,97.46,98.27,32.35,179.6,32.65,259.64,32.93,324.81,99,324.61,179.56ZM178.88,49c-72-.18-130,57.85-130.09,130.19a130,130,0,0,0,129.37,130c71.53.28,130.11-58.17,130.12-129.84C308.29,107.23,250.68,49.18,178.88,49Z"
        />
        <path
          d="M186.7,117.63c0,8.5.1,17-.06,25.49,0,3,.56,4.75,3.76,5.93a29.61,29.61,0,0,1,18.1,18.13c1.07,2.89,2.5,3.81,5.42,3.73,6.66-.16,13.32-.13,20,0,5.92.1,9.4,3.16,9.44,8,0,5.11-3.54,8.26-9.69,8.33-6.49.07-13,.17-19.48-.05-3.13-.11-4.49.86-5.72,3.91a31.94,31.94,0,0,1-36.76,19.7c-15.42-3.35-26.45-17.32-25.55-33.06.76-13.51,7.62-23.26,20.1-28.51,3.46-1.46,4.24-3.24,4.22-6.62-.15-17.16,0-34.32-.09-51.48,0-4.47,1.52-7.85,6-9.3,5.33-1.74,10.22,2.34,10.32,8.81C186.8,99.64,186.69,108.64,186.7,117.63ZM194.82,179a16.27,16.27,0,1,0-16.17,16.28A16.47,16.47,0,0,0,194.82,179Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "save",
  props: ["role"],
};
</script>
