export default {
    inscriptionAppointmentsCount: null,
    hide: true,
    filtersState: {
        search: undefined,
        customer: undefined,
        tattoo_type: undefined,
        dates: undefined,
        from: undefined,
        to: undefined,
        consent_form: undefined,
        box: undefined,
        payed: undefined,
        studio_id: undefined,
        tattooer_id: undefined,
    }
}