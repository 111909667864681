import Vue from 'vue'

export default {
    getSuppliers: async function ({
        commit,
        state
    }, {
        pagination,
        filters
    } = {
        pagination: {},
        filters: {}
    }) {
        try {
            commit('app/SET_PROCESSING', {
                show: true,
                message: null
            }, {
                root: true
            });
            const params = {
                ...pagination,
                ...filters
            }
            return await Vue.http.get(`suppliers`, {
                params
            }).then(response => {
                return response.json();
            });

        } catch (e) {
            //console.log(e);
        } finally {
            commit('app/SET_PROCESSING', {
                show: false,
                message: null
            }, {
                root: true
            })
        }
    },
    getSupplier: async function ({
        commit,
        state
    }, id) {
        try {
            commit('app/SET_PROCESSING', {
                show: true,
                message: null
            }, {
                root: true
            });

            return await Vue.http.get(`suppliers/${id}`).then(response => {
                return response.json();
            });

        } catch (e) {
            //console.log(e);
        } finally {
            commit('app/SET_PROCESSING', {
                show: false,
                message: null
            }, {
                root: true
            })
        }
    },
    updateSupplier: async function ({
        commit,
        state
    }, {

        supplier_id,
        supplier
    }) {
        try {
            commit('app/SET_PROCESSING', {
                show: true,
                message: null
            }, {
                root: true
            });
            return await Vue.http.post(`suppliers/${supplier_id}`, supplier).then(response => {
                return response.json();
            })
        } catch (e) {
            //console.log(e);
        } finally {
            commit('app/SET_PROCESSING', {
                show: false,
                message: null
            }, {
                root: true
            })
        }
    },
    getAll: async function ({
        commit,
        state
    }, ) {
        try {
            commit('app/SET_PROCESSING', {
                show: true,
                message: null
            }, {
                root: true
            });

            return await Vue.http.get(`suppliers/all`).then(response => {
                return response.json();
            });

        } catch (e) {
            //console.log(e);
        } finally {
            commit('app/SET_PROCESSING', {
                show: false,
                message: null
            }, {
                root: true
            })
        }
    },
    addSupplier: async function ({
        commit,
        state
    }, {
        supplier
    }) {
        try {
            commit('app/SET_PROCESSING', {
                show: true,
                message: null
            }, {
                root: true
            });
            return await Vue.http.post(`suppliers`, supplier).then(response => {
                return response.json();
            })
        } catch (e) {
            //console.log(e);
        } finally {
            commit('app/SET_PROCESSING', {
                show: false,
                message: null
            }, {
                root: true
            })
        }
    },

}