<template>
  <svg
    class="svg-icon"
    :role="role"
    xmlns="http://www.w3.org/2000/svg"
    width="26.935"
    height="26.935"
    viewBox="0 0 26.935 26.935"
  >
    <g id="Grupo_39" data-name="Grupo 39" transform="translate(0 2.245)">
      <g id="Grupo_38" data-name="Grupo 38">
        <path
          id="Trazado_24"
          data-name="Trazado 24"
          d="M23.007,42.667H21.885a.561.561,0,1,0,0,1.122h1.122a2.808,2.808,0,0,1,2.806,2.806V63.429a2.808,2.808,0,0,1-2.806,2.806H3.928a2.808,2.808,0,0,1-2.806-2.806V46.595a2.808,2.808,0,0,1,2.806-2.806H5.05a.561.561,0,1,0,0-1.122H3.928A3.932,3.932,0,0,0,0,46.595V63.429a3.932,3.932,0,0,0,3.928,3.928H23.007a3.932,3.932,0,0,0,3.928-3.928V46.595A3.932,3.932,0,0,0,23.007,42.667Z"
          transform="translate(0 -42.667)"
        />
      </g>
    </g>
    <g id="Grupo_41" data-name="Grupo 41" transform="translate(8.978 2.245)">
      <g id="Grupo_40" data-name="Grupo 40">
        <path
          id="Trazado_25"
          data-name="Trazado 25"
          d="M179.083,42.667h-7.856a.561.561,0,1,0,0,1.122h7.856a.561.561,0,1,0,0-1.122Z"
          transform="translate(-170.666 -42.667)"
        />
      </g>
    </g>
    <g id="Grupo_43" data-name="Grupo 43" transform="translate(0 7.856)">
      <g id="Grupo_42" data-name="Grupo 42">
        <path
          id="Trazado_26"
          data-name="Trazado 26"
          d="M26.374,149.333H.561a.561.561,0,1,0,0,1.122H26.374a.561.561,0,1,0,0-1.122Z"
          transform="translate(0 -149.333)"
        />
      </g>
    </g>
    <g id="Grupo_45" data-name="Grupo 45" transform="translate(6.734)">
      <g id="Grupo_44" data-name="Grupo 44">
        <path
          id="Trazado_27"
          data-name="Trazado 27"
          d="M128.561,0A.561.561,0,0,0,128,.561V5.05a.561.561,0,1,0,1.122,0V.561A.561.561,0,0,0,128.561,0Z"
          transform="translate(-128 0)"
        />
      </g>
    </g>
    <g id="Grupo_47" data-name="Grupo 47" transform="translate(19.079)">
      <g id="Grupo_46" data-name="Grupo 46">
        <path
          id="Trazado_28"
          data-name="Trazado 28"
          d="M363.227,0a.561.561,0,0,0-.561.561V5.05a.561.561,0,1,0,1.122,0V.561A.561.561,0,0,0,363.227,0Z"
          transform="translate(-362.666 0)"
        />
      </g>
    </g>
    <g id="Grupo_49" data-name="Grupo 49" transform="translate(6.734 12.345)">
      <g id="Grupo_48" data-name="Grupo 48">
        <path
          id="Trazado_29"
          data-name="Trazado 29"
          d="M132.487,239.717A2.805,2.805,0,1,0,128,237.473a.561.561,0,0,0,1.122,0,1.683,1.683,0,1,1,1.683,1.683h-1.122a.561.561,0,0,0,0,1.122h1.122a1.683,1.683,0,1,1-1.683,1.683.561.561,0,1,0-1.122,0,2.806,2.806,0,1,0,4.487-2.245Z"
          transform="translate(-128 -234.667)"
        />
      </g>
    </g>
    <g id="Grupo_51" data-name="Grupo 51" transform="translate(14.591 12.345)">
      <g id="Grupo_50" data-name="Grupo 50">
        <path
          id="Trazado_30"
          data-name="Trazado 30"
          d="M280.369,234.7a.561.561,0,0,0-.612.121l-2.245,2.245a.561.561,0,0,0,.794.793l1.286-1.286V244.2a.561.561,0,1,0,1.122,0v-8.978A.562.562,0,0,0,280.369,234.7Z"
          transform="translate(-277.349 -234.661)"
        />
      </g>
    </g>
    <g id="Grupo_53" data-name="Grupo 53" transform="translate(14.59 21.324)">
      <g id="Grupo_52" data-name="Grupo 52">
        <path
          id="Trazado_31"
          data-name="Trazado 31"
          d="M282.383,405.333h-4.489a.561.561,0,0,0,0,1.122h4.489a.561.561,0,1,0,0-1.122Z"
          transform="translate(-277.333 -405.333)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Appointments",
  props: ["role"],
};
</script>
