import Vue from "vue";
export default {
  getVacations: async function(
    { commit, state },
    { pagination, filters } = {
      pagination: {},
      filters: {},
    }
  ) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      const params = {
        ...pagination,
        ...filters,
      };

      return await Vue.http
        .get(`vacations`, {
          params,
        })
        .then((response) => {
          return response.json();
        });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  getVacation: async function ({
    commit,
    state
  }, vacation_id) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`vacations/${vacation_id}`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  getAll: async function ({
    commit,
    state
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      return await Vue.http.get(`vacations/all`).then(response => {
        return response.json();
      })
    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  addVacation: async function({ commit, state }, { vacation }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http.post(`vacations`, vacation).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  updateVacation: async function({ commit, state }, { vacation, id_vacation }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http.post(`vacations/${id_vacation}`, vacation).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
  deleteVacation: async function({ commit, state }, { id_vacation }) {
    try {
      commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      return await Vue.http.delete(`vacations/${id_vacation}`).then((response) => {
        return response.json();
      });
    } catch (e) {
      //console.log(e);
    } finally {
      commit(
        "app/SET_PROCESSING",
        {
          show: false,
          message: null,
        },
        {
          root: true,
        }
      );
    }
  },
};
