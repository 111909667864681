<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :role="role"
    viewBox="0 0 360 359.9"
  >
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          d="M0,193.41v-27c.76-5.08,1.46-10.16,2.3-15.23C19.72,46.68,121.78-21,224.25,5.92,294.3,24.32,337.86,70.74,355.69,141c2,8,2.9,16.28,4.31,24.43v29c-.3,1.09-.66,2.16-.89,3.26-2.6,12.52-4.06,25.4-7.95,37.5C322.29,325.1,228.8,377.87,137.32,354.29,65.25,335.72,21,288.38,3.81,215.93,2.06,208.54,1.25,200.92,0,193.41Zm343.74-13.6c-.08-90.22-73.5-163.39-163.93-163.36-90.06,0-163.39,73.32-163.47,163.39-.09,90.41,73.4,163.64,164.16,163.57C270.5,343.34,343.82,269.86,343.74,179.81Z"
        />
        <path
          d="M161.88,184.48h41.26c-1.44,3.86-2.44,7.32-4.07,10.44-.5,1-2.74,1.38-4.19,1.4-10.48.11-21,.06-32,.06,1.51,12.38,5.67,22.88,15.95,29.92a25.42,25.42,0,0,0,22.08,3.32c6.93-2.1,13.29-6.05,20-9.25,0,5.07.3,11-.21,16.89-.13,1.51-2.59,3.19-4.35,4.12-26.94,14.33-59.2,2.09-70.3-26.8-2.13-5.55-3.28-11.48-5.09-18H128.29c2.28-10.7,3.22-11.68,11.69-12.33V172.12H128.32c2.48-11.35,2.62-11.47,13.32-12,5.47-21.78,15.25-40.58,39.45-46.47,16.16-3.94,30.94.07,44.55,10.24-2.67,5.45-5.29,10.78-8,16.42-10.53-9.35-22-15.46-36-9.11-12,5.45-15.94,16.61-18.78,29h47.55c-1.44,3.71-2.35,6.93-4,9.75-.67,1.17-2.84,2.05-4.34,2.08-11.5.17-23,.09-34.5.09h-5.73Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "euro",
  props: ["role"],
};
</script>
