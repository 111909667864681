import Vue from 'vue'

export default {
  getAllMarketplace: async function ({
    commit,
    state,
    dispatch
  }, {
    filters
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      const params = {
        ...filters
      };
      return await Vue.http.get(`marketplaces/all`, {
        params
      }).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  updateMarketplace: async function ({
    commit,
    state,
    dispatch
  }, {
    type,
    marketplaces,
    formData
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });
      let form = {
        marketplaces
      }
      if (formData) form = formData;
      return await Vue.http.post(`marketplaces/${type}`, form).then(response => {
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },
  updateActiveMarketplace: async function ({
    commit,
    state,
    dispatch
  }, {
    marketplace,
    active
  }) {
    try {
      commit('app/SET_PROCESSING', {
        show: true,
        message: null
      }, {
        root: true
      });

      return await Vue.http.post(`marketplaces/${marketplace}/toggle`, {
        active
      }).then(response => {
        dispatch(
          "auth/getSettings", {}, {
            root: true,
          }
        );
        return response.json();
      });

    } catch (e) {
      //console.log(e);
    } finally {
      commit('app/SET_PROCESSING', {
        show: false,
        message: null
      }, {
        root: true
      })
    }
  },

}