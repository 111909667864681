export default {
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  drawer: null,
  hover: null,
  update: false,
  error: null,
  processing: 0,
  notification: {
    show: false,
    message: null,
    color: 'success',
    timeout: 6000
  },
  session_expired: false,
  supportedLanguages: ['es', 'en'],
  language: process.env.VUE_APP_I18N_LOCALE,
  fallback_language: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  tab_state: {
    settings: "alerts",
    plan: "upgrades"
  },
  change_user: false,
}